import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Button, Card, Col, Pagination, Row, Tabs, message } from 'antd';
import { FileExcelFilled, PlusCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ListadoVehiculos from "./listado";
import { rutas } from "../../lib/routes/routes";
import GraficosCamposPersonalizados from "../../subComponents/graficos/conductores";
import HelperModal from "../../subComponents/general/helperModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { urlapi } from "../../lib/backend/data";
import useLabelVehicles from "./useLabels";

const Vehiculos = (props) => {
  const [ loading, setLoading ] = useState(false)
  const sesion = useSelector(state => state.miusuario)
  const [ messageApi, contextHolder] = message.useMessage();
  const modulo = 'vehiculos'
  const dispatch = useDispatch()
  const { openModal, RenderModal } = useLabelVehicles();

  const onSelect = (datos,action) => {
    if(!action) return
    const i = componentes_accion.findIndex(it => it.label === action)
    if(i > -1){
        const funcion = componentes_accion[i].action
        funcion(datos)
    }
}

const eliminarMultiples = async (viajes)=>{
    setLoading(true)

    const seleccionados = viajes.map(e => e._id)
    return fetch(`${urlapi}/${modulo}/masive`,{
        method:'DELETE',
        body: JSON.stringify({
            ids: seleccionados
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            messageApi.error('Sin datos')
        } else if(res.errorMessage){
            messageApi.error(res.errorMessage)
        } else if(res.deletedCount){
            return window.location.reload()
        }
        return setLoading(false)
    })
    .catch(error => {
        messageApi.error("Error al consultar la información, intente nuevamente")
        return setLoading(false)
    })
}

const imprimirCodigos = async (viajes)=>{
    return console.log(viajes)
    setLoading(true)

    const seleccionados = viajes.map(e => e._id)
    return fetch(`${urlapi}/${modulo}/print`,{
        method:'POST',
        body: JSON.stringify({
            ids: seleccionados
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        console.log(res)
        if(!res){
            messageApi.error('Sin datos')
        } else if(res.errorMessage){
            messageApi.error(res.errorMessage)
        } else if(res.codes){
        }
        return setLoading(false)
    })
    .catch(error => {
        messageApi.error("Error al consultar la información, intente nuevamente")
        return setLoading(false)
    })
}

const componentes_accion = [
    {
        label: "ELIMINAR",
        action: eliminarMultiples
    },
    {
        label: "IMPRIMIR CÓDIGO QR",
        action: openModal
    }
]

    const render = () => {
        return <div>
            <RenderModal />
            <Card>
            <Title className="mb-2 mt-0">Vehículos</Title>
            <Row gutter={15}>
              <Col span="auto"><Button style={{ marginRight: 10 }} type="primary" size="small"><Link to={`${rutas.vehicles.slug}/new`}><PlusCircleFilled /> CREAR NUEVO</Link></Button></Col>
              <Col span="auto"><Button style={{ marginRight: 10 }} size="small"><Link to={rutas.import_vehicles.slug}><FileExcelFilled /> IMPORTADOR</Link></Button></Col>
              <Col span="auto"><HelperModal tipo="vehiculos" style="modal" /></Col>
            </Row>
            <ListadoVehiculos 
                showSelection={true} 
                showCustomFieldsData={true} 
                loadingActions={loading}
                onSelect={(data,action) => onSelect(data,action)} 
                componentAction={componentes_accion}
                showCounters={true} 
                showFilters={true} 
                condicion_default={{ status: "active" }} 
              />
            </Card>
        </div>
    }
    
    return <Structure component={render()} />
}

export default Vehiculos