import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Avatar, Badge, Breadcrumb, Button, Card, Col, Divider, Form, Input, Popconfirm, Row, Select, Tabs, Upload, message } from "antd"
import { CarFilled, FileImageOutlined, HeatMapOutlined, HomeOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { config_s3, urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import { obtenerPrefijoMovil } from "../../lib/helpers/data/moviles"
import ReactFlagsSelect from "react-flags-select"
import { paises_codigos_telefonos, paises_sistema } from "../../lib/global/data"
import { Link, useParams } from "react-router-dom"
import CargandoTabla from "./cargando"
import MapaDetalleConductor from "../Mapas/mapa_detalle_conductor"
import ListadoRegistrosFotograficos from "./registros_fotograficos_listado"
import ListadoVehiculos from "../Vehiculos/listado"
import CamposPersonalizadosRecurso from "../../subComponents/campos_personalizados/listado_recurso"
import ListadoRequisitos from "../Requisitos/listado"
import { TbFileCertificate, TbGps, TbTag } from 'react-icons/tb'
import ListadoRequisitosModelos from "../Requisitos/esquema_agregar"
import { formatoAyudaDNIPorPais, formatoAyudaMovilPorPais, nombreDNIPorPais } from "../../lib/helpers/data/internationa"
import S3FileUpload from "react-s3/lib/ReactS3"
import { limpiarStringLetrasNumeros, limpiarStringNumeros, siEsRequeridoEnEsquema, validateEmail } from "../../lib/helpers/helpers"
import ListadoAlertas from "../GeoAlertas/listado"
import CargaImagen from "../../subComponents/medios/carga_imagen"
import CamposPersonalizadosRelacionador from "../../subComponents/general/campos_personalizados_relacionador"
import ListadoCalificaciones from "../Calificaciones/listado"
import AdvanceFilterRating from "../Calificaciones/advance_filter_rating"


const DetallesConductor = (props) => {
    const [ usuario, setUsuario ] = useState(false)
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [ loading, setLoading ] = useState(false)
    const [permisosOtorgados, setPermisosOtorgados] = useState([])
    const [resetingPass, setResetingPass] = useState(false)
    const [ loadingImagen, setLoadingImagen] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [credenciales, setCredenciales] = useState({})
    const [messageApi, contextHolder] = message.useMessage();
    const { id } = useParams()
    const dispatch = useDispatch()
    const requeridos = [
        { value:'nombres', label: 'Nombres' },
        { value:'apellido_p', label: 'apellido_m' },
        { value:'phone', label: 'Móvil' },
        { value:'status', label: 'Estado' },
    ]

    const obtenerUsuario = async () => {
        return fetch(`${urlapi}/conductores?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingMaster(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingMaster(false)
            } else if(res.conductor){
                if(res.conductor.phone) res.conductor.phone = res.conductor.phone.substring(2,100)
                setUsuario(res.conductor)
//                obtenerEstructuraSoporte(res._id)
                if(res.permisos){
                    if(Array.isArray(res.permisos) !== false){
                        setPermisosOtorgados(res.permisos.map(p => ({ slug: p.module, actions: p.actions })))
                    }
                }
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    useEffect(() => {
        obtenerUsuario()
    }, [])

    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        if(name === "rut"){
            usuario[name] = limpiarStringLetrasNumeros(value)
        } else if(name === "phone"){
            usuario[name] = limpiarStringNumeros(value)
        } else {
            usuario[name] = value
        }
        return setUsuario({...{}, ...usuario})
    }

    const handleChangeUsuarioSelect = (name, value) => {
        usuario[name] = value
        return setUsuario(usuario)
    }

    const reiniciarClaveUsuario = async () => {
        const { password, repeat_password } = credenciales
        if(!password) return messageApi.error(`Ingresa una nueva contraseña`)
        if(password.toString().length < 4) return messageApi.error(`Ingresa una nueva contraseña válida, al menos 4 carácteres`)
        if(!repeat_password) return messageApi.error(`Repite tu contraseña`)
        if(password !== repeat_password) return messageApi.error("Contraseñas no coinciden")
        
        setResetingPass(true)
        return fetch(`${urlapi}/usuarios/reset-password`,{
            method:'PUT',
            body: JSON.stringify({
                id: usuario._id,
                password: password,
                password_confirm: repeat_password
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setResetingPass(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setResetingPass(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
            }
            return setResetingPass(false)
        })
        .catch(error => {
                messageApi.error("Error al consultar la información, intente nuevamente")
                return setResetingPass(false)
        })
    }

    const actualizarUsuario = async (dato) => {
        
        const registro = dato ? dato : usuario

        let faltantes = []
        requeridos.map(campo => {
            if(!registro[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)

        if(registro.email) if(!validateEmail(registro.email)) return messageApi.error(`Email inválido`)  

        registro.permisos = permisosOtorgados

        const instancia_conductor = JSON.parse( JSON.stringify(registro))
        if(instancia_conductor.phone){
            const prefijo = obtenerPrefijoMovil(pais)
            instancia_conductor.phone = `${prefijo}${instancia_conductor.phone}`
        }


        
        setLoading(true)
        return fetch(`${urlapi}/conductores`,{
            method:'PUT',
            body: JSON.stringify(instancia_conductor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const handleChangeArchivo = async (e) => {
        if(e.file.status !== "error") return false
        setLoadingImagen(true)
        const archivo = e.file.originFileObj
        return S3FileUpload.uploadFile(archivo, config_s3)
        .then( async data => {
            console.log(data)
        if(!data.location) {
            messageApi.error('No pudimos cargar tu imagen')
            return setLoadingImagen(false)
        }
            usuario.imagen_perfil = data.location
            setLoadingImagen(false)
            return setUsuario(usuario)
        })
        .catch(err => {
            setLoadingImagen(false)
            return false
        })
    }

    const handleChangePassRepeat = (e) => {
        const { name, value } = e.target
        credenciales[name] = value
        return setCredenciales(credenciales)
    }

    const eliminarRecurso = async () => {
        setLoadingMaster(true)
        return fetch(`${urlapi}/conductores?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                return window.location = rutas.drivers.slug
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    const render = () => {
        if(loadingMaster) return <Card><CargandoTabla /></Card>
        if(!usuario) return <Card><Title>No hay suficientes datos para mostrar esta pantalla</Title> </Card>
        return <div>
            <Card>
            
                <Row>
                    <Col xs={12}>
                    <Breadcrumb
                items={[
                { title: <Link to={rutas.dashboard.slug}><HomeOutlined /></Link> },
                { title: <Link to={rutas.drivers.slug}>Conductores</Link> },
                { title: 'Detalles de conductor' }
                ]}
            />
                    <Title className="mb-2 mt-0">Detalles de conductor</Title>
                    <Popconfirm
                    title="Eliminar definitivamente"
                    description="¿Estás seguro que deseas eliminar este recurso? Esta opción no se puede deshacer"
                    onConfirm={() => eliminarRecurso()}
                    onCancel={() => false}
                    okText="SI"
                    cancelText="NO"
                    >
                  <Button size="small" type="dashed" danger>ELIMINAR</Button>
                  </Popconfirm>
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                    <Avatar shape="circle" style={{ color: '#f5222d' }} size={70} src={usuario.imagen_perfil} icon={<UserOutlined />} />
                    </Col>
                </Row>
            
            

            </Card>
            <MapaDetalleConductor id={usuario._id} />
            
                <Row gutter={20} style={{ marginTop: -100, padding: 30, position: "relative", zIndex:999 }}>
                    <Col md={8}>
                    <Badge.Ribbon text={usuario.conectado ? "CONECTADO" : "DESCONECTADO"} color={usuario.conectado ? "GREEN" : "GRAY"}>
                        <Card size="small">
                    <Title level={4} className="mt-0 mb-3"><UserOutlined /> Datos personales</Title>
            
            <Form layout="vertical">
            <Row gutter={10}>
                <Col md={8} xs={24}>
                    <Form.Item label="Nombres" required={siEsRequeridoEnEsquema('nombres',requeridos)}>
                    <Input mb={3} className="mb-3" placeholder="Nombres" name="nombres" defaultValue={usuario.nombres} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                
                <Col md={8} xs={24}>
                    <Form.Item label="Apellido paterno" required={siEsRequeridoEnEsquema('apellido_p',requeridos)}>
                    <Input mb={3} className="mb-3" placeholder="Apellido Paterno" variant="filled" name="apellido_p" defaultValue={usuario.apellido_p} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Apellido materno" required={siEsRequeridoEnEsquema('apellido_m',requeridos)}>
                    <Input mb={3} className="mb-3" placeholder="Apellido Materno" variant="filled" name="apellido_m" defaultValue={usuario.apellido_m} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Sexo" required={siEsRequeridoEnEsquema('sexo',requeridos)}>
                    <Select
                    defaultValue={usuario.sexo}
                    options={[{ value: "H", label: "Hombre" },{ value:"M", label: "Mujer" }]}
                    onChange={(e) => handleChangeUsuarioSelect('sexo',e)} 
                    />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label={nombreDNIPorPais(pais)} tooltip={formatoAyudaDNIPorPais(pais)} required={siEsRequeridoEnEsquema('rut',requeridos)}>
                    <Input mb={3} className="mb-3" placeholder="Rut" variant="filled" name="rut" value={usuario.rut} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                
                <Col md={8} xs={24}>
                    <Form.Item label="Estado" required={siEsRequeridoEnEsquema('status',requeridos)}>
                    <Select className="mb-0" name="status" placeholder="Estado" style={{ width: "100%" }} defaultValue={usuario.status} options={[{ value: "active", label: "Activo" }, { value: "inactive", label: "Inactivo"}, { value:"suspended", label: "Suspendido" }]} onChange={(e) => handleChangeUsuarioSelect('status', e)} />
                    </Form.Item>
                </Col>
                
                <Col md={24} xs={24}>
                    <Form.Item label="Email" required={siEsRequeridoEnEsquema('email',requeridos)}>
                    <Input mb={3} className="mb-0" variant="filled" placeholder="Email" name="email" defaultValue={usuario.email} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={24} xs={24} className="mb-3"> 

                {/* <Upload
                    name="avatar"
                    showUploadList={false}
                    beforeUpload={(e) => console.log(e)}
                    onChange={handleChangeArchivo}
                >
                    {usuario.imagen_perfil ? (
                    <img
                        src={usuario.imagen_perfil}
                        alt="avatar"
                        style={{
                        width: '30%',
                        display: "block"
                        }}
                    />
                    ) : false }
                        <Button loading={loadingImagen}> SUBIR FOTO</Button>
                </Upload> */}
                <CargaImagen default_image={usuario.imagen_perfil} onChange={(url) => {
                    setUsuario(prev => {
                        let actual = {...prev}
                        actual.imagen_perfil = url
                        if(url) actualizarUsuario(actual)
                        return actual
                    })
                }} />
                </Col>
            </Row>
            <Form.Item label="Móvil"  tooltip={formatoAyudaMovilPorPais(pais)} required={siEsRequeridoEnEsquema('phone',requeridos)}>
            <Row className="mb-3" gutter={10}>
                <Col xs={10}>
                        <ReactFlagsSelect
                            countries={paises_sistema}
                            customLabels={paises_codigos_telefonos}
                            disabled
                            placeholder="CÓDIGO PAÍS"
                            searchPlaceholder="Buscar"
                            selected={usuario.pais_code}
                            onSelect={value => {
                                const prefijo = obtenerPrefijoMovil(value)
                                usuario.codigo_movil    = prefijo
                                usuario.pais_code       = value
                                console.log(usuario)
                                return setUsuario({...{}, ...usuario})
                            }}
                            />
                </Col>
                <Col xs={14}>
                <Input mb={3} variant="filled" style={{ height: 38 }} name="phone" value={usuario.phone} onChange={handleChangeUsuario}  />
                </Col>
            </Row>        
            </Form.Item>
               
            <Button type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => actualizarUsuario()} ><SaveOutlined /> GUARDAR CAMBIOS</Button>
            </Form>
                            </Card>
                            </Badge.Ribbon>
                    </Col>
                    <Col md={16}>
                        <Card size="small">

                        <Tabs defaultActiveKey="1" items={[
                            {
                                key: "1",
                                label: <Card size="small" style={{ textAlign: "center" }}><FileImageOutlined style={{ fontSize: 20 }} /> <Title level={4} className="m-0">VALIDACIÓN FOTOGRÁFICA</Title></Card>,
                                children: <ListadoRegistrosFotograficos condicion_default={{ id_conductor: id }} />
                            },
                            {
                                key: "2",
                                label: <Card size="small" style={{ textAlign: "center" }}><CarFilled style={{ fontSize: 20 }} /> <Title level={4} className="m-0">VEHÍCULOS</Title></Card>,
                                children: <ListadoVehiculos hideSearch={true} condicion_default={{ id_usuario: id }} />
                            },
                            {
                                key: "3",
                                label: <Card size="small" style={{ textAlign: "center" }}><TbTag style={{ fontSize: 20 }} /> <Title level={4} className="m-0">CAMPOS PERSONALIZADOS</Title></Card>,
                                children: <CamposPersonalizadosRecurso id_target={id} tipo="conductor" />
                            },
                            {
                                key: "4",
                                label: <Card size="small" style={{ textAlign: "center" }}><TbFileCertificate style={{ fontSize: 20 }} /> <Title level={4} className="m-0">REQUISITOS</Title></Card>,
                                children: <div>
                                        <ListadoRequisitos condicion_default={{ id_target: id }} />
                                        <Divider />
                                        <Title level={4}  className="mb-3 mt-0">Otros requisitos que también podrías enviar</Title>
                                        <ListadoRequisitosModelos id_target={usuario._id} condicion_default={{ tipo: "conductor", status: "active" }} />
                                    </div>
                            },
                            {
                                key: "5",
                                label: <Card size="small" style={{ textAlign: "center" }}><HeatMapOutlined style={{ fontSize: 20 }} /> <Title level={4} className="m-0">GEO ALERTAS</Title></Card>,
                                children: <div>
                                       <ListadoAlertas excludeFilters={['conductor']} condicion_default={{ id_target: id }} />
                                    </div>
                            },
                            {
                                key: "6",
                                label: <Card size="small" style={{ textAlign: "center" }}><TbGps style={{ fontSize: 20 }} /> <Title level={4} className="m-0">GPS</Title></Card>,
                                children: <div>
                                      <CamposPersonalizadosRelacionador id_target={usuario._id} condicion_default={{ module: "vehiculo" }}  />
                                    </div>
                            },
                            {
                                key: "7",
                                label: <Card size="small" style={{ textAlign: "center" }}><TbGps style={{ fontSize: 20 }} /> <Title level={4} className="m-0">CALIFICACIONES</Title></Card>,
                                children: <div>
                                      <AdvanceFilterRating id={id} />
                                    </div>
                            }
                        ]} />
                   </Card>
                    </Col>
                </Row>
            
            {contextHolder}
        </div>
    }
    
    return <Structure component={render()} />
}

export default DetallesConductor