import { Button, Col, Divider, Form, Input, Modal, Row, Select, message } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { urlapi } from "../../lib/backend/data";
import { SaveOutlined } from "@ant-design/icons";
import CargandoTabla from "./cargando";
import Title from "antd/es/typography/Title";
import { filterOption, formatYMD, formatYMD_UTC } from "../../lib/helpers/helpers";
import { campos_fecha, modulo, requeridos } from "./data";
import { FaTruckFront, FaTruckRampBox } from "react-icons/fa6";
import { FaChevronRight, FaRegTrashAlt } from "react-icons/fa";
import ModalSeleccionVehiculo from "../Vehiculos/modal_seleccion";
import SelectorZonaAvanzadoInternacional from "../RadioOperacion/selector_zona_avanzado_internacional";
import { GiHandTruck } from "react-icons/gi";
import { DateTime } from "luxon";

const EditarContactoRapido = (props) => {
    const {
        id_registro
    } = props
    const [ campo, setCampo ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ vehiculos, setVehiculos ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const module = modulo

    const obtenerDatos = async () => {
        if(!id_registro) return messageApi.error("ID no válido")
        setLoading(true)
        return fetch(`${urlapi}/${module}?id=${id_registro}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                setCampo(res)
                if(res.vehiculos) setVehiculos(res.vehiculos)
            }
            return setLoading(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }


    const guardarCambios = async () => {
        let guardar_campo = {...{}, ...campo}
        let faltantes = []
        requeridos.map(field => {
            if(!guardar_campo[field.value]) faltantes.push(field.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  

        setLoadingSave(true)
        guardar_campo.vehiculos = vehiculos
        
        return fetch(`${urlapi}/${module}`,{
            method:'PUT',
            body: JSON.stringify(guardar_campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                setIsModalOpen(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoadingSave(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingSave(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
      obtenerDatos()
    };

    const handleOk = () => {
        return guardarCambios()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

      const getDateKeyFromHourKey = (key) => {
        if(key === "hora_carga_desde") return "fecha_carga_desde"
        if(key === "hora_carga_hasta") return "fecha_carga_hasta"
        if(key === "hora_descarga_desde") return "fecha_descarga_desde"
        if(key === "hora_descarga_hasta") return "fecha_descarga_hasta"
        return false
      }

      const convertDateByMomentDate = (key, val) => {
        if(key === "fecha_carga_desde") return DateTime.fromISO(val).endOf('day').toUTC().toISO()
        if(key === "fecha_carga_hasta") return DateTime.fromISO(val).endOf('day').toUTC().toISO()
        if(key === "fecha_descarga_desde") return DateTime.fromISO(val).endOf('day').toUTC().toISO()
        if(key === "fecha_descarga_hasta") return DateTime.fromISO(val).endOf('day').toUTC().toISO()
      }

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = {...prev}
            const date_key = getDateKeyFromHourKey(name)
            if(date_key){
                const val_date = actual[date_key]
                if(val_date){
                    if(value){
                        const horario = value.split(':')
                        actual[date_key] = DateTime.fromISO(val_date).set({hour: parseInt(horario[0]), minute: parseInt(horario[1]) }).toUTC().toISO()
                    } else {
                        actual[date_key] = convertDateByMomentDate(date_key, val_date)
                    }
                }
            } else {
                if(campos_fecha.includes(name)){
                    if(value){
                        if(actual[name]){
                            const parsear = DateTime.fromISO(actual[name]).toUTC()
                            const fecha = DateTime.fromISO(value).toUTC().set({ hour: parsear.hour, minute: parsear.minute }).toISO()
                            actual[name] = fecha
                        } else {
                            const nueva_fecha = DateTime.fromISO(value).toUTC()
                            actual[name] = convertDateByMomentDate(nueva_fecha, value)
                        }
                    } else {
                        actual[name] = ''
                    }
                } else {
                    actual[name] = value
                }
            }
            return {...{}, ...actual}
        })
    }


    const handleChangeSelect = (name, value) => {
        campo[name] = value
        return setCampo({...{}, ...campo})
    }

    const mostrarMantenedorFechas = () => {
        if(campo.tipo_disponibilidad === "unica"){

            let carga_desde = ''
            let hora_carga_desde = ''
            let carga_hasta = ''
            let hora_carga_hasta = ''

            let descarga_desde = ''
            let hora_descarga_desde = ''
            let descarga_hasta = ''
            let hora_descarga_hasta = ''

            if(campo.fecha_carga_desde) carga_desde = formatYMD_UTC(campo.fecha_carga_desde)
            if(campo.fecha_carga_desde) hora_carga_desde = DateTime.fromISO(campo.fecha_carga_desde).toFormat('HH:mm')
            if(campo.fecha_carga_hasta) carga_hasta = formatYMD_UTC(campo.fecha_carga_hasta)
            if(campo.fecha_carga_hasta) hora_carga_hasta = DateTime.fromISO(campo.fecha_carga_hasta).toFormat('HH:mm')



            if(campo.fecha_descarga_desde) descarga_desde = formatYMD_UTC(campo.fecha_descarga_desde)
            if(campo.fecha_descarga_desde) hora_descarga_desde = DateTime.fromISO(campo.fecha_descarga_desde).toFormat('HH:mm')
            if(campo.fecha_descarga_hasta) descarga_hasta = formatYMD_UTC(campo.fecha_descarga_hasta)
            if(campo.fecha_descarga_hasta) hora_descarga_hasta = DateTime.fromISO(campo.fecha_descarga_hasta).toFormat('HH:mm')



            return <Col md={24}><Row>
                <Col md={24}>
                        <Title level={3} className="mt-0 mb-2"><GiHandTruck style={{ verticalAlign: "middle" }} /> Carga</Title>
                            <Row gutter={15}>
                                <Col xs={24}>
                                    <Row gutter={15}>
                                        <Col md={12}>
                                        <Form.Item label="Desde" className="mb-3">
                                        {/* <DatePicker placeholder="Seleccionar fecha" type style={{ width: "100%"}} format="YYYY-MM-DD" locale={locale} defaultValue={campo.fecha_carga_desde ? dayjs(formatYMD_UTC(campo.fecha_carga_desde), 'YYYY-MM-DD') : ""}  onChange={(e) => handleChangeDate(e,'fecha_carga_desde')} /> */}
                                        <Input type="date" name="fecha_carga_desde" defaultValue={carga_desde} onChange={handleChange} />
                                        </Form.Item>
                                        </Col>
                                        <Col md={12}>
                                        <Form.Item label="Hora" className="mb-3">
                                        <Input type="time" style={{ width: "100%"}} name="hora_carga_desde" defaultValue={hora_carga_desde} onChange={handleChange} />
                                        </Form.Item>
                                        </Col>
                                    </Row>
                                    
                                    
                                </Col>
                                <Col xs={24}>
                                    <Row gutter={15}>
                                        <Col md={12}>
                                        <Form.Item label="Hasta" className="mb-3">
                                        <Input type="date" name="fecha_carga_hasta" defaultValue={carga_hasta} onChange={handleChange} />
                                        </Form.Item>
                                        </Col>
                                        <Col md={12}>
                                        <Form.Item label="Hora" className="mb-3">
                                        <Input type="time" style={{ width: "100%"}} name="hora_carga_hasta" defaultValue={hora_carga_hasta} onChange={handleChange} />
                                        </Form.Item>
                                        </Col>
                                    </Row>
                                   
                                    
                                </Col>
                            </Row>
                    </Col>
                    <Col md={24}>
                        <Title level={3} className="mt-0 mb-2"><FaTruckRampBox style={{ verticalAlign: "middle" }} /> Descarga</Title>
                            <Row gutter={15}>
                                <Col xs={24}>
                                    <Row gutter={15}>
                                    <Col md={12}>
                                    <Form.Item label="Desde" className="mb-3">
                                    {/* <DatePicker placeholder="Seleccionar fecha" type style={{ width: "100%"}} format="YYYY-MM-DD" locale={locale} defaultValue={campo.fecha_carga_desde ? dayjs(formatYMD_UTC(campo.fecha_carga_desde), 'YYYY-MM-DD') : ""}  onChange={(e) => handleChangeDate(e,'fecha_carga_desde')} /> */}
                                    <Input type="date" name="fecha_descarga_desde" defaultValue={descarga_desde} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                    <Form.Item label="Hora" className="mb-3">
                                    <Input type="time" style={{ width: "100%"}} name="hora_descarga_desde" defaultValue={hora_descarga_desde} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                    </Row>
                                </Col>
                                <Col xs={24}>
                                <Row gutter={15}>
                                    <Col md={12}>
                                    <Form.Item label="Hasta" className="mb-3">
                                    <Input type="date" name="fecha_descarga_hasta" defaultValue={descarga_hasta} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                    <Form.Item label="Hora" className="mb-3">
                                    <Input type="time" style={{ width: "100%"}} name="hora_descarga_hasta" defaultValue={hora_descarga_hasta} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                </Row>
                                </Col>
                            </Row>
                    </Col>
            </Row>
            </Col>
        } else if (campo.tipo_disponibilidad === "recurrente"){
            return <Col md={24}>
                <Form.Item label="Vencimiento" className="mb-2">
                <Input type="date" name="vencimiento" defaultValue={formatYMD_UTC(campo.vencimiento)} onChange={handleChange} />
                </Form.Item>
                </Col>
        }
    }

    const mostrarUbicaciones = (key) => {
        if(!campo[key]) return false
        if(campo[key].length < 1) return false
        return <div>
            {
                campo[key].map((ubi,i) => <Title level={4} key={`ubi-${i}`} className="mt-0 mb-2"><FaChevronRight style={{ verticalAlign: "middle" }} /> {ubi.titulo}</Title>)
            }
        </div>
    }

    const agregarUbicacion = (data, key) => {
        if(!data) return
        setCampo(prev => {
            let actual = {...prev}
            if(!actual[key]) actual[key] = []
            const i = actual[key].findIndex(e => e._id === data._id)
            if(i < 0) actual[key].push({ _id: data._id, titulo: data.titulo, slug: data.slug })
            return actual
        })
    }

    const remover = (i) => {
        setVehiculos(prev => {
            let actual = [...prev]
            actual.splice(i,1)
            return actual
        })
    }
    
    const mostrarVehiculos = () => {
        return <div>
            <Divider className="mt-3 mb-3" />
            {
                vehiculos.map((veh,iv) => {
                    return <div key={`veh-${iv}`}>
                        <Row gutter={15}>
                            <Col md={18}><Title level={5} className="mt-0 mb-2"><FaTruckFront style={{ verticalAlign: "middle" }} /> {veh.identificador}</Title></Col>
                            <Col md={6}><Button size="small" icon={<FaRegTrashAlt />} onClick={() => remover(iv)} >REMOVER</Button> </Col>
                        </Row>
                        { vehiculos.length > 1 ? <Divider className="mt-3 mb-3" /> : false}
                    </div>
                })
            }
        </div>
    }
    
    const formulario = () => {
        if(loading) return <CargandoTabla />

        return <div>
            <Form layout="vertical">
                <Row gutter={15} >
                
                <Col md={12}>
                        <Form.Item label="Título" required className="mb-2">
                        <Input name="titulo" value={campo.titulo} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Tipo" required className="mb-2">
                        <Select options={[{ value: 'recurrente', label: "Recurrente" },{ value: "unica", label: "Única" }]} showSearch filterOption={filterOption} defaultValue={!campo.tipo_disponibilidad ? "" : campo.tipo_disponibilidad} name="tipo_disponibilidad" onChange={(e) => handleChangeSelect("tipo_disponibilidad", e)} />
                        </Form.Item>
                    </Col>                    
                    
                    {mostrarMantenedorFechas()}
                    <Col md={24}>
                        <Divider className="mt-3 mb-3" />
                        <Title level={4} className="mt-0 mb-2">Ubicación de carga</Title>
                        <SelectorZonaAvanzadoInternacional onSelected={(data) => agregarUbicacion(data,'ids_origin')} />
                        {mostrarUbicaciones('ids_origin')}
                        <Divider className="mt-3 mb-3" />
                        <Title level={4} className="mt-0 mb-2">Ubicación de descarga</Title>
                        <SelectorZonaAvanzadoInternacional onSelected={(data) => agregarUbicacion(data,'ids_destination')} />
                        {mostrarUbicaciones('ids_destination')}
                    </Col>
                    <Col md={24}>
                        <Divider className="mt-3 mb-3" />
                        <Title level={4} className="mt-0 mb-2">Vehículos asociados { vehiculos.length > 0 ? <Button size="small" icon={<FaRegTrashAlt />} onClick={() => setVehiculos([...[], ...[]])} >REMOVER TODO</Button> : false } </Title>
                            <ModalSeleccionVehiculo isMulti={true} showSelection={true} hideSeleccionados={true}  onSelect={data => {
                                let valor = data
                                setVehiculos(prev => {
                                    let actual = [ ...prev ]
                                    for( const veh of valor ){
                                        veh.identificador = veh.titulo
                                        const i = actual.findIndex(e => e._id === veh._id || e._id)
                                        if( i < 1 ) actual.push(veh) 
                                    }
                                    return actual
                                })
                            }} />
                        
                        {mostrarVehiculos()}
                    </Col>
                    <Col md={24}>
                        <Button type="primary" loading={loadingSave} onClick={() => guardarCambios()} ><SaveOutlined />GUARDAR CAMBIOS</Button>
                    </Col>
                </Row>
                
            </Form>
        </div>
    }
    
    return <div>
        <Button  className="m-0" size="small" type="dashed" onClick={showModal}>EDITAR</Button>
        <Modal 
            title="Editar" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
            footer={[]}
        >
        {formulario()}
      </Modal>
      {contextHolder}
    </div>
}

export default EditarContactoRapido