import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useCallback, useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Avatar, Button, Card, Checkbox, Col, Divider, Form, Input, Pagination, Progress, Row, Table, Tooltip, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { calcularPorcentaje, fechaATextoSimple } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import { FileExcelFilled, QuestionCircleOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons"
import { formatearDNIPorPais, formatearMovilPorPais, nombreDNIPorPais } from "../../lib/helpers/data/internationa"
import ModalPersonalizado from "../../subComponents/general/modales/modal_personalizado"
import GraficosCamposPersonalizados from "../../subComponents/graficos/conductores"
import { estilo_moving_truck } from "../../lib/estilo_sitio"
import { encontrarLlaveValorEnEsquema } from "../../lib/helpers/interfaz"
import EncabezadoPesonalizado from "../../subComponents/general/tablas/encabezado"
import PersonalizarColumnas from "../../subComponents/interfaz/columnas_personalizar"
import HelperModal from "../../subComponents/general/helperModal"
import { MdOpenInNew } from "react-icons/md"
import ModalSeleccionGeoAlertas from "../GeoAlertas/modal_seleccion"
import { debounce } from 'lodash';
import ModalButtonListado from "./modal_listado"
import ListadoRequisitos from "../Requisitos/listado"
import ListadoVehiculos from "../Vehiculos/listado"
import CamposPersonalizadosRecurso from "../../subComponents/campos_personalizados/listado_recurso"
const ListadoConductores = (props)=> {
    const {
        condicion_default,
        tableSize,
        showFilters,
        showCounters,
        showReport,
        showSelection,
        exclude,
        hideExporter,
        hideSearch,
        hideHeader,
        typeView,
        componentAction,
        loadingActions,
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [filtros, setFiltros] = useState([])
    const [loadingConductores, setLoadingConductores] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const trash = props.trash ? props.trash : false
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const tipo = props.tipo ? props.tipo : "link"
    const titulo = props.title ? props.title : 'registros'
    const key_interfaz              = 'tabla_conductores'
    const key_esquema               = 'CONDUCTORES'

    const obtenerConductores = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        setCondicionBusqueda(condicion)
        return fetch(`${urlapi}/conductores/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
                if(res.filtros) setFiltros(res.filtros)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        obtenerConductores(1, false)
    }, [])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerConductores(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination showSizeChanger={false} defaultCurrent={pagina} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }

    const seleccionarRegistro = (data, action) => {
        deseleccionarTodos({ target: false })
        let datos = data
        if(Array.isArray(datos) !== true) datos = [data]
        if(props.onSelect) props.onSelect(datos, action)
    }

    const showByType = (tipo, data) => {
        switch (tipo) {
            case "funcion":
                return <b className="hover" onClick={() => seleccionarRegistro(data)}>{data.nombres} {data.apellido_p ? data.apellido_p : ''}</b>
            default:
                return <Link to={`${rutas.drivers.slug}/${data._id}`}>{data.nombres} {data.apellido_p ? data.apellido_p : ''}</Link>
        }
    }

    const filtrarRegistros = (filtro) => {
        if(!filtro.condicion) return false
        if(typeof filtro.condicion !== "object") return false
        obtenerConductores(1, filtro.condicion)
    }

    const seleccionarEspecial = (action) => {
        return seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true), action)
      }
      
    const accionButtons = () => {
        if(showSelection !== true) return false
        const seleccionados = conductores.filter(veh => veh.seleccionado === true).length
        const enable = seleccionados > 0 ? false : true

        if(componentAction){
            return <div style={{ marginTop: 10, marginBottom: 10 }} onClick={() => seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true))}>
                {componentAction.map(it => {
                    return <Button disabled={enable} loading={ loadingActions === true } size="small" style={{ marginRight: 10 }} onClick={() => seleccionarEspecial(it.label)} >{it.label}</Button>
                })}
            </div>
        }

        return <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Button disabled={enable} size="small" onClick={() => seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true))}>SELECCIONAR</Button>
        </div>
      }
    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/drivers`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const buscarRegistros = (val) => {
        if (!val){
            const condicion = {}
            setCondicionBusqueda(condicion);
            setPagina(1);
            return obtenerConductores(1, condicion)
        }

        const condicion = {
            $or: [
                { ...condicion_default, propietario: sesion.data.propietario,  nombres: { $regex: val.toLowerCase(), $options: "i" } },
                { ...condicion_default, propietario: sesion.data.propietario,  apellido_p: { $regex: val.toLowerCase(), $options: "i" } },
                { ...condicion_default, propietario: sesion.data.propietario,  apellido_m: { $regex: val.toLowerCase(), $options: "i" } },
            ]
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerConductores(1, condicion)
      };

    const busquedaAutomaticamente = useCallback(debounce((data) => buscarRegistros(data), 1000), []);

    const handleChangeCampo = (e) => {
        const { value } = e.target;
        busquedaAutomaticamente(value)
        return setCampoBusqueda(value);
      };


      const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setCondicionBusqueda(condicion_default);
            setPagina(1);
            return obtenerConductores(1, condicion_default);
        }
        const condicion = {
            ...condicion_default,
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerConductores(1, condicion);
      };
      
      const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmitCapture={handleSubmit}>
                    <Row gutter={15}>
                    <Col md={6}>
                        <Form.Item label="Buscar" className="mb-2">
                                <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Nombre, apellido, email, teléfono o ${nombreDNIPorPais(pais)}`} />
                            </Form.Item>
                        </Col>
                        <Col md={4}>
                            <Form.Item>
                                <Button onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                            </Form.Item>
                        </Col>
                    </Row>
            </Form>
      }
      
      const deseleccionarTodos = (e) => {
        setConductores([...[], ...conductores.map(veh => { 
            if(exclude){
                if( Array.isArray(exclude) !== false ){
                    if(exclude.includes(veh._id)) return veh
                }
            }
            veh.seleccionado = e.target.checked
            return veh 
        })])
      }

      const handleChangeSeleccion = (e) => {
        const { name } = e.target
        const valor = e.target.checked
        const i = conductores.findIndex(ve => ve._id === name )
        conductores[i].seleccionado = valor
        setConductores([...[], ...conductores])
      }

      const mostrarExportador = () => {
        if(hideExporter === true) return false
        return <div><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green" onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></div>
      }

      const checkForEnabledValue = (id) => {
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const validarChecked = (seleccionado, id) => {
        if(seleccionado === true) return true
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const mostrarContadores = () => {
        if(hideHeader === true) return false

        const data = <Row gutter={15}>
            {
                filtros.map((filtro,i) => {

                    return <Col className="hover" md={3} key={`filtro-${i}`} onClick={() => filtrarRegistros(filtro)} >
                    <Card size="small" className="mb-3">
                        <Paragraph style={{ fontSize: 12 }} className="m-0">{filtro.label}
                        </Paragraph>
                        <Row>
                            <Col xs={12}><Title className="m-0">{filtro.cantidad}</Title></Col>
                            <Col xs={12} style={{ textAlign: "right", marginTop:8 }}>
                                {/* <Progress size={35} type="circle" percent={porcentaje} /> */}
                            </Col>
                        </Row>
                        {
                            filtro.help_text ? <Tooltip title={filtro.help_text}>
                        <Paragraph className="mb-0" style={{ fontSize: 10, color: estilo_moving_truck.colors.primary }}><QuestionCircleOutlined style={{ marginLeft: 5 }} /> Más información</Paragraph>
                        </Tooltip> : false
                        }
                    </Card>
                </Col>
                })
            }
        </Row>

        return <Col md={6} className="mb-3">
            <ModalPersonalizado typeView="modal" slug="informe_clasificacion" component={data} />
        </Col>
      }

      const mostrarInforme = () => {
        if(!showReport) return false

        return <Col md={6} className="mb-3">
            <ModalPersonalizado typeView="modal" slug="informe_personalizado" component={<GraficosCamposPersonalizados tipo="conductor" />} />
        </Col>
      }

      const mostrarTabla = () => {
        if(loadingConductores) return <CargandoTabla />

        const component_perfil = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="imagen_perfil" />} render={(data) => {
           
            return <div style={{ textAlign: "center"}}>
                <Avatar shape="circle" style={{ color: '#f5222d' }} src={data.imagen_perfil} icon={<UserOutlined />} />
            </div>
        }} />

        const component_nombre = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="nombres" />} render={(data) => {
            return <div>{showByType(typeView, data)}</div>
        }} />

        const component_dni = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="rut" />} render={(data) => {
            const val = formatearDNIPorPais(data.rut, pais)
            return <div>{val ? val : "Sin datos"}</div>
        }} />

        const component_email = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="email" />} render={(data) => {
            const email = data.email
            return <div>{email ? email : "Sin datos"}</div>
        }} />
        
        const component_geoalertas = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="alertas" />} render={(data) => {
          
            return <div><ModalSeleccionGeoAlertas label="GEO ALERTAS" condicion_default={{ id_target: data._id }} /> </div>
        }} />
        
        const component_requisitos = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="requisitos" />} render={(data) => {
            return <div><Progress size={35} type="circle" percent={data.porcentaje_requisitos} /></div>
        }} />

        const component_movil = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="phone" />} render={(data) => {
            return <div>{formatearMovilPorPais(data.phone, pais)}</div>
        }} />

        const component_fecha = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="createdAt" />} render={(data) => {
            return <div>{fechaATextoSimple(data.createdAt)}</div>
        }} />

        const component_estado = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="status" />} render={(data) => {
            return <div>{data.status === "active" ? "ACTIVO" : "INACTIVO"}</div>
        }} />

      

        const component_vehiculo = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="vehiculo" />} render={(data) => {
            if(!data.vehiculo_asignado) return <div>SIN INFORMACIÓN</div>
            let valor           = ''
                    let info_extra      = ''
                    let link            = ''
                    if(data.vehiculo_asignado) if(typeof data.vehiculo_asignado === 'object'){
                        valor = `${data.vehiculo_asignado.marca} ${data.vehiculo_asignado.modelo} ${data.vehiculo_asignado.ano}`
                        info_extra = data.vehiculo_asignado.patente
                        link = `${rutas.drivers.slug}/${data.vehiculo_asignado._id}`
                    }
                    if(data.vehiculo_asignado) if(typeof data.vehiculo_asignado === 'object') link = `${rutas.vehicles.slug}/${data.vehiculo_asignado._id}`
                    
                    return <div>
                        {valor} <Link to={link} target="_blank"><MdOpenInNew /></Link>
                        {info_extra ? <div ><p className="m-0" style={{ fontWeight: "bold" }}>{info_extra}</p></div> : false }
                    </div>
        }} />

        const component_lista_requisitos = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="listReq" />} render={(data) => {
          
            return <div><ModalButtonListado ComponenteListado={ListadoRequisitos} tituloText="Listado de Requisitos" label="REQUISITOS" condicion_default={{ id_target: data._id }} /> </div>
        }} />

        const component_lista_vehiculos = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="listVehiculos" />} render={(data) => {
          
            return <div><ModalButtonListado ComponenteListado={ListadoVehiculos} tituloText="Listado de Vehiculos" label="VEHICULOS" condicion_default={{ id_usuario: data._id }} /> </div>
        }} />

        const component_lista_campos = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="listPersonalizada" />} render={(data) => {
          
            return <div><ModalButtonListado ComponenteListado={CamposPersonalizadosRecurso} tituloText="Campos Perzonalizados" label="CAMPOS" condicion_default={{ id_usuario: data._id }} /> </div>
        }} />
      
        return <Table size={tableSize ? tableSize : "middle"} dataSource={conductores} pagination={false} scroll={estilo_moving_truck.scroll_row} locale={{ emptyText: <HelperModal tipo="conductores" /> }} >
        {
            showSelection !== true ? false : <Column title={<Checkbox onChange={deseleccionarTodos} />} render={(data) => {
                return <Checkbox disabled={checkForEnabledValue(data._id)} checked={validarChecked(data.seleccionado, data._id)} name={data._id} onChange={handleChangeSeleccion}/>
            }} />
        }
        { encontrarLlaveValorEnEsquema("imagen_perfil", interfaz_usuario[ key_interfaz ]) === false ? false : component_perfil }
        { encontrarLlaveValorEnEsquema("nombres", interfaz_usuario[ key_interfaz ]) === false ? false : component_nombre }
        { encontrarLlaveValorEnEsquema("rut", interfaz_usuario[ key_interfaz ]) === false ? false : component_dni }
        { encontrarLlaveValorEnEsquema("email", interfaz_usuario[ key_interfaz ]) === false ? false : component_email }
        { encontrarLlaveValorEnEsquema("vehiculo", interfaz_usuario[ key_interfaz ]) === false ? false : component_vehiculo }
        { encontrarLlaveValorEnEsquema("requisitos", interfaz_usuario[ key_interfaz ]) === false ? false : component_requisitos }
        { encontrarLlaveValorEnEsquema("alertas", interfaz_usuario[ key_interfaz ]) === false ? false : component_geoalertas }
        { encontrarLlaveValorEnEsquema("phone", interfaz_usuario[ key_interfaz ]) === false ? false : component_movil }
        { encontrarLlaveValorEnEsquema("createdAt", interfaz_usuario[ key_interfaz ]) === false ? false : component_fecha }
        { encontrarLlaveValorEnEsquema("status", interfaz_usuario[ key_interfaz ]) === false ? false : component_estado }
        { encontrarLlaveValorEnEsquema("listReq", interfaz_usuario[ key_interfaz ]) === false ? false : component_lista_requisitos }
        { encontrarLlaveValorEnEsquema("listVehiculos", interfaz_usuario[ key_interfaz ]) === false ? false : component_lista_vehiculos }
        { encontrarLlaveValorEnEsquema("listPersonalizada", interfaz_usuario[ key_interfaz ]) === false ? false : component_lista_campos }

    </Table>
   
    }
    
    const mostrarRegistros = () => {

        const botones = <Row gutter={15}>
        <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col>
        <Col span="auto"><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green" onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></Col>
        </Row>

        return <div>
        <Divider />
        {
                showFilters === true ? <Row gutter={15}>
                <Col md={24}>
                    {buscador()}
                </Col>
                <Col md={24}>
                    <Row gutter={15}>
                    {mostrarInforme()}
                    {mostrarContadores()}
                    </Row>
                    {botones}
                    {paginacion(data.pagina, total)}
                    {accionButtons()}
                    {mostrarTabla()}
                </Col>
            </Row> : <div>
                {buscador()}
                <Row gutter={15}>
                {mostrarInforme()}
                {mostrarContadores()}
                </Row>
                {botones}
                {paginacion(data.pagina, total)}
                {accionButtons()}
                {mostrarTabla()}
                </div>
            }

            
        </div>
    }

    return <div>
        {contextHolder}
        {mostrarRegistros()}
    </div>
}
export default ListadoConductores