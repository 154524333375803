import { Col, Form, Row, Select } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { modificarInterfaz } from "../../redux/actions/interfaz"

/*
sort_config: {
        viajes: {
            sort_column: '_id',
            sort_order: 'desc'
        }
}
*/
const SortBy = (props) => {
    const {
        type
    } = props
    const sort_options = [
        { value: 'desc', label: 'Más nuevos' },
        { value: 'asc', label: 'Mas antiguos' }
    ]
    const limit_page_options = [ { value: 20, label: 20 }, { value: 50, label: 50 }, { value: 100, label: 100 } ]
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const session                   = useSelector(state => state.miusuario)
    const dispatch                  = useDispatch()

    const handleChange = (val, key, interfaz_option) => {
        const items = getOptionsByType()

        let instancia_esquema                       = JSON.parse( JSON.stringify(interfaz_usuario) )

        if(interfaz_option === "limit_pagination") {
            if(!instancia_esquema.limit_pagination)          instancia_esquema.limit_pagination = {}
            if(!instancia_esquema.limit_pagination[type])    {
                const config_nueva = {
                    limit_page: 20
                }
                instancia_esquema.limit_pagination[type] = config_nueva
            }
            instancia_esquema.limit_pagination[type][key]    = val
            if(props.onChange) props.onChange(instancia_esquema.limit_pagination[type], "limit_pagination")
        } else {
            if(!instancia_esquema.sort_config)          instancia_esquema.sort_config = {}
            if(!instancia_esquema.sort_config[type])    {
                const config_nueva = {
                    sort_column: items[0].value,
                    sort_order: sort_options[0].value
                }
                instancia_esquema.sort_config[type] = config_nueva
            }
            instancia_esquema.sort_config[type][key]    = val
            if(props.onChange) props.onChange(instancia_esquema.sort_config[type], "sort_config")
        }

        
        dispatch(modificarInterfaz(instancia_esquema, session.tokenSession))
        
    }

    const getOptionsByType = () => {
        switch (type) {
            case "viajes":
                return [
                    { value: '_id', label: 'Fecha de creación' },
                    { value: 'updatedAt', label: "Fecha de actualización" }
                ]
            default:
                return []
        }
    }

    const obtenerSortConfig = (items, key) => {
        const instancia_esquema = JSON.parse( JSON.stringify(interfaz_usuario) )
        // return dispatch(modificarInterfaz(instancia_esquema, session.tokenSession))
        if(items) if(Array.isArray(items)) if(items.length > 0){
            if(instancia_esquema) if(instancia_esquema.sort_config) if(typeof instancia_esquema.sort_config === "object") if(instancia_esquema.sort_config[type]){
                return instancia_esquema.sort_config[type][key]
            }

            return items[0].value
        }
    }

    const obtenerLimitesPagina = (items, key) => {
        let default_val = 20
        const instancia_esquema = JSON.parse( JSON.stringify(interfaz_usuario) )
        // return dispatch(modificarInterfaz(instancia_esquema, session.tokenSession))
        if(items) if(Array.isArray(items)) if(items.length > 0){
            if(instancia_esquema) if(instancia_esquema.limit_pagination) if(typeof instancia_esquema.limit_pagination === "object") if(instancia_esquema.limit_pagination[type]){
                return instancia_esquema.limit_pagination[type][key]
            }

            return items[0].value
        }
        return default_val
    }

    const items = getOptionsByType()
    const default_sort_column               = obtenerSortConfig(items, 'sort_column')
    const default_sort_order                = obtenerSortConfig(sort_options, 'sort_order')
    const default_limit_page                = obtenerLimitesPagina(limit_page_options, 'limit_page')

    return <div>
        <Form layout="inline">
            <Row gutter={15}>
                {
                    items.length > 0 ? <Col span="auto">
                        <Form.Item label="Ordenar por">
                        <Select defaultValue={default_sort_column} name="sort_column" options={items} onChange={(e) => handleChange(e, 'sort_column')} />
                        </Form.Item>
                    </Col> : false
                }
                {
                    items.length > 0 ? <Col span="auto">
                        <Select defaultValue={default_sort_order} name="sort_order" options={sort_options} onChange={(e) => handleChange(e, 'sort_order')} />
                    </Col> : false
                }
                <Col span="auto">
                    <Form.Item label="Ver por página">
                    <Select defaultValue={default_limit_page} name="limit_page" options={limit_page_options} onChange={(e) => handleChange(e, 'limit_page', "limit_pagination")} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </div>
}

export default SortBy