import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Alert, Button, Card, Col, Divider, Modal, Row, Spin, Steps, Tooltip, message } from 'antd';
import { useEffect, useState } from "react";
import HelperModal from "../../subComponents/general/helperModal";
import { rutas } from "../../lib/routes/routes";
import { Link } from "react-router-dom";
import { FileExcelFilled } from "@ant-design/icons";
import { main_data } from "./datos";
import Paragraph from "antd/es/typography/Paragraph";
import SelectorGlobal from "../../subComponents/general/selector";
import { urlapi } from "../../lib/backend/data";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import ButtonGroup from "antd/es/button/button-group";
import { ReactSortable } from "react-sortablejs";
import { FaRegHandPointer } from "react-icons/fa";
import { HiCursorClick } from "react-icons/hi";
import { DateTime } from "luxon";
import { fechaUTCATexto, fechaUTCATextoSimple } from "../../lib/helpers/helpers";
import { FiBox } from "react-icons/fi";

const LineaTiempo = (props) => {
    const {
        showCreateButton,
        showImportButton,
        display_full,
        condicion,
    } = props
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion ? condicion : {})
    const [ loadingEstados, setLoadingEstados ] = useState(false)
    const [ loadingLineasTiempo, setLoadingLineasTiempo ] = useState(false)
    const [ messageApi, contextHolder] = message.useMessage();
    const [ tipoServicioSeleccionado, setTipoServicioSeleccionado ] = useState(null)
    const [ estados, setEstados ] = useState([])
    const [ lineaTiempoSeleccionada, setLineaTiempoSeleccionada ] = useState(null)
    const [ showModal, setShowModal ] = useState(false)
    const [ savingLineaTiempo, setSavingLineaTiempo ] = useState(false)
    const [ lineasTiempo, setLineasTiempo ] = useState([])
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const modulo = main_data.module

    useEffect(() => {
        obtenerEstados()
        obtenerLineasTiempo()
    }, [])

    const obtenerEstados = async () => {
        setLoadingEstados(true)
        return fetch(`${urlapi}/${modulo}/details`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res.estados) !== false){
                setEstados(res.estados)
            }
            return setLoadingEstados(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingEstados(false)
        })
    }

    const actualizarLineaTiempo = async (linea_tiempo) => {
        setSavingLineaTiempo(true)
        return fetch(`${urlapi}/${modulo}/lineas-tiempo`,{
            method:'PUT',
            body: JSON.stringify({...linea_tiempo, tipo_servicio: tipoServicioSeleccionado?._id }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){

            }
            return setSavingLineaTiempo(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setSavingLineaTiempo(false)
        })
    }

    const obtenerLineasTiempo = async () => {
        setLoadingLineasTiempo(true)
        return fetch(`${urlapi}/${modulo}/lineas-tiempo`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setLineasTiempo(res)
            }
            return setLoadingLineasTiempo(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingLineasTiempo(false)
        })
    }

    const mostrarBotonCreacion = () => {
        if(showCreateButton === false) return false
        return <div>
            <Row gutter={15}>
              <Col span="auto">
                {/* <CrearNuevoRegistro showClientSelector={showClientSelector} default_payload={default_payload ? default_payload : {}} onCreate={() => setCondicionBusqueda(prev => {
                let actual = {...prev}
                return {...{}, ...actual}
              })} /> */}
              </Col>
              { showImportButton === false ? false :  <Col span="auto"><Button style={{ marginRight: 10 }} size="small"><Link to={rutas.import_products.slug}><FileExcelFilled /> IMPORTADOR</Link></Button></Col> }
              <Col span="auto"><HelperModal tipo={modulo} style="modal" /></Col>
            </Row>
            <Divider />
        </div>
    }

    const seleccionarTipoServicio = (data) => {
        console.log(data)
        const i = lineasTiempo.findIndex(item => item.tipo_servicio === data?._id)
        if( i > -1){
            setLineaTiempoSeleccionada(lineasTiempo[i])
        } else {
            setLineaTiempoSeleccionada({ hitos: [] })
        }
        setTipoServicioSeleccionado(data)
    }

    const agregar = (estado_completo) => {
        return setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            actual.hitos.push(estado_completo)
            actualizarLineaTiempo(actual)
            return {...{}, ...actual}
        })
    }

    const detectarEstadosActuales = () => {
        const mostrar_estados = lineaTiempoSeleccionada ? estados.filter(e => e.tipos_servicio.includes(tipoServicioSeleccionado?._id)).filter(e => {
            const ids_seleccionados = lineaTiempoSeleccionada.hitos.map(h => h._id)
            return !ids_seleccionados.includes(e._id)
        }) : estados.filter(e => e.tipos_servicio.includes(tipoServicioSeleccionado?._id))

        return mostrar_estados
    }
    
    const mostrarEstados = () => {
        if(loadingEstados) return <div>
            <Spin />
            <Title level={5} className="mt-0">Cargando...</Title> </div>

        if(!tipoServicioSeleccionado) return <div>
            <Title className="mt-0" level={5}>Selecciona un tipo de servicio para ver los estados asociados</Title>
        </div>

        if(estados.length < 1) return <div>
            <Title className="mt-0" level={5}>El servicio que seleccionaste aún no tiene eventos logísticos asociados dentro del protocólo de entrega</Title>
            <Link to={rutas.estados_carga.slug}><Button >CREAR Y ASOCIAR EVENTOS</Button></Link>
        </div>

        const mostrar_estados = detectarEstadosActuales()

        if(mostrar_estados.length < 1) return <div>
            <Title className="mt-0" level={5}>No hay más estados disponibles para agregar a la línea de tiempo</Title>
        </div>

        return <div>
            <Title level={3} className="mt-0 mb-0">{mostrar_estados.length} Estados encontrados</Title>
            <Alert className="mb-3" message={<div><HiCursorClick /> A continuación toca el estado para agregarlo a la línea de tiempo</div>} type="info" />
            {
                mostrar_estados.map((estado, index) => {
                    return <div key={`estado-${index}`}>
                        <Tooltip title="Toca para agregar a la línea de tiempo">
                        <Button level={5} icon={<HiCursorClick />} className="mb-3" onClick={() => agregar(estado)} >{estado.titulo}</Button>
                        </Tooltip>
                    </div>
                })
            }
        </div>
    }

    const remover = (i) => {
        setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            actual.hitos.splice(i, 1)
            actualizarLineaTiempo(actual)
            return {...{}, ...actual}
        })
    }

    const reordenarHitos = (hitos) => {
        setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            actual.hitos = hitos
            actualizarLineaTiempo(actual)
            return {...{}, ...actual}
        })
    }

    const mostrarLineaTiempo = () => {
        if(!tipoServicioSeleccionado) return <div>
            <Title className="mt-0" level={5}>Selecciona un tipo de servicio para ver las líneas de tiempo asociadas</Title>
        </div>
        
        const mostrar_estados = detectarEstadosActuales()
        if(lineaTiempoSeleccionada.hitos.length < 1 ){
            if(mostrar_estados.length < 1) return false
            return <div>
                <Title className="mt-0" level={3}>Aún no has agregado estados a la línea de tiempo</Title>
            </div>
        }

        return <div>
            <Title level={3} className="mt-0 mb-0">Esta es tu línea de tiempo para <b>{savingLineaTiempo ? <Spin /> : false } {tipoServicioSeleccionado?.title}</b></Title>
            <Alert className="mb-3" message={<div><FaRegHandPointer /> Reordena los estados arrastrandolos hacia arriba y abajo. <Button onClick={() => setShowModal(true)}>PREVISUALIZAR</Button></div>} type="info" />

            <ReactSortable list={lineaTiempoSeleccionada.hitos} animation={200} setList={(d) => reordenarHitos(d)}>
            {
                lineaTiempoSeleccionada.hitos.map((hito, index) => {
                    return <div key={`hito-${(index+1)}`}>
                        <Card size="small" className="mb-3 hover">
                        <Row>
                            <Col md={12}><Title className="mt-0 mb-0" level={5}>{(index+1)} {hito.titulo}</Title></Col>
                            <Col md={12} style={{ textAlign: "right" }}>
                            <Button onClick={() => remover(index)}>REMOVER</Button>
                            </Col>
                        </Row>
                        </Card>
                    </div>
                })
            }
            </ReactSortable>
        </div>

    }

    const handleCancel = () => setShowModal(false)
    const mostrarModal = () => {

        return <Modal
            width={"60%"}
            title="Esta este es un ejemplo de tu línea de tiempo" 
            open={showModal} 
            // onOk={handleOk} 
            onCancel={handleCancel}
            // confirmLoading={loading}
            footer={[]}
        >
            <Steps
                current={lineaTiempoSeleccionada?.hitos.length - 1}
                style={{ marginTop: 20 }}
                items={lineaTiempoSeleccionada?.hitos.map( (estado,i) => {
                    return {
                        title: estado.titulo,
                        description: fechaUTCATexto(DateTime.now().plus({ minutes: (i+10) }).toUTC().toISO()),
                        // icon: <FiBox />
                    }
                })}
            />
    </Modal>
    }
    
    const render = () => {
        return <div>
            <Title className="mb-2 mt-0">{main_data.titulo}</Title>
            <Paragraph>{main_data.descripcion_2}</Paragraph>
            <SelectorGlobal titulo="Tipo de servicio" condicion={{ tipo: "servicios" }} module="estadoscarga" key_label="title" key_value="_id" onChange={(data) => seleccionarTipoServicio(data)} />
            <Row gutter={15}>
                <Col md={12}>
                    {mostrarEstados()}
                </Col>
                <Col md={12}>
                    {mostrarLineaTiempo()}
                </Col>
            </Row>
        </div>
    }

    if(display_full === true ){

        const renderizar = <div>
            {contextHolder}
            {mostrarModal()}
            <Card>{render()}</Card>
        </div>

        return <Structure component={renderizar} />
    } else {
        return render()
    }
}

export default LineaTiempo