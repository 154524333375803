export const rutas = {
    formularios: {
        slug: '/formularios',
    },
    login: {
        slug: '/login'
    },
    usuarios: {
        slug: '/users'
    },
    dashboard:{
        slug: '/dashboard'
    },
    monitoring:{
        slug: '/monitoring'
    },
    monitoring_general:{
        slug: '/monitoring-general',
        title: "MONITOREO"
    },
    monitoring_vehicles:{
        slug: '/monitoring-vehicles'
    },
    drivers:{
        title: "CONDUCTORES",
        slug: '/drivers'
    },
    vehicles:{
        slug: '/vehicles',
        title: "VEHÍCULOS"
    },
    import_drivers:{
        slug: '/import/drivers'
    },
    import_vehicles:{
        slug: '/import/vehicles'
    },
    business:{
        slug: '/business'
    },
    operation_zones:{
        slug: '/operation-zones'
    },
    zones:{
        slug: '/zones'
    },
    operation_zones_new:{
        slug: '/operation-zones-new'
    },
    custom_fields:{
        slug: '/campos-personalizados'
    },
    tracking_driver:{
        slug: '/rastreo'
    },
    alerts:{
        slug: '/zones/alerts'
    },
    map_alerts:{
        slug: '/zones/map-alerts'
    },
    notifications:{
        slug: '/notificaciones'
    },
    estadistica_tracking:{
        slug: '/estadistica/tracking'
    },
    forget_password:{
        slug: '/forgot-password'
    },
    reset_password:{
        slug: '/reset-password'
    },
    equipment:{
        slug: '/equipment',
        title: "EQUIPOS"
    },
    equipment_categories:{
        slug: '/equipment-categories',
        title: "CATEGORÍAS"
    },
    equipment_sub_categories:{
        slug: '/equipment-sub-categories',
        title: "SUB CATEGORÍAS"
    },
    clients:{
        slug: '/clients',
        title: "EMPRESAS"
    },
    clients_categories:{
        slug: '/clients-categories',
        title: "CATEGORÍAS"
    },
    clients_sub_categories:{
        slug: '/clients-sub-categories',
        title: "SUB CATEGORÍAS"
    },
    contacts:{
        slug: '/clients-contacts',
        title: "CONTACTOS"
    },
    trips:{
        slug: '/trips',
        title: "ÓRDENES"
    },
    trips_report:{
        slug: '/trips-reporte',
        title: "REPORTE"
    },
    import_trips:{
        slug: '/import/trips',
        title: "IMPORTAR"
    },
    cargo_categories:{
        slug: '/cargos-categories',
        title: "CARGOS"
    },
    import_equipment:{
        slug: '/import/equipment'
    },
    import_clients:{
        slug: '/import/clients'
    },
    warehouses:{
        slug: '/warehouses',
        title: "BODEGAS"
    },
    rutas:{
        slug: '/rutas',
        title: "RUTAS"
    },
    products:{
        slug: '/products',
        title: "PRODUCTOS"
    },
    estados_carga:{
        slug: '/estados_carga',
        title: "PROTOCOLOS DE ENTREGA"
    },
    tipos_servicio:{
        slug: '/services-types',
        title: "SERVICIOS"
    },
    products_categories:{
        slug: '/products-categories',
        title: "CATEGORÍAS"
    },
    products_subcategories:{
        slug: '/products-subcategories',
        title: "SUB CATEGORÍAS"
    },
    import_products:{
        slug: '/import/products'
    },
    costos_categories:{
        slug: '/costos-categories',
        title: "COSTOS"
    },
    costos_sub_categories:{
        slug: '/costos-sub-categories',
        title: "SUB CATEGORÍAS"
    },
    costos:{
        slug: '/costos',
        title: "COSTOS"
    },
    ruteo:{
        slug: '/ruteo',
        title: "RUTEO"
    },
    actividad_flota:{
        slug: '/actividad-flota',
        title: "ACTIVIDAD DE FLOTA"
    },
    timeline:{
        slug: '/linea-tiempo',
        title: "LINEA DE TIEMPO"
    },
    eta:{
        slug: '/configuracion-eta',
        title: "ETA"
    },
    enlaces:{
        slug: '/enlaces-ruta',
        title: "COMPARTIR RUTAS"
    },
    disponibilidades:{
        slug: '/disponibilidades',
        title: "DISPONIBILIDADES"
    },
    busqueda_recursos:{
        slug: '/busqueda-recursos',
        title: "MI DISPONIBILIDAD"
    },
    solicitud_disponibilidad:{
        slug: '/disponibilidades/solicitudes',
        title: "MIS SOLICITUDES"
    },
    solicitud_disponibilidad_externa:{
        slug: '/disponibilidades/solicitudes-externas',
        title: "SOLICITUDES A MI EMPRESA"
    },
    personalizar:{
        slug: '/personalizar',
        title: "PERSONALIZAR"
    },
    canales:{
        slug: '/canales',
        title: "CANALES"
    },
    bodega_expediciones:{
        slug: '/bodega-expediciones',
        title: "EXPEDICIONES"
    },
    ckeck_list:{
        slug: '/formularios',
        title: "CHECK LIST"
    },
}