import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Form, Row, Select, Spin, message } from "antd";
import debounce from 'lodash/debounce';
import { urlapi } from "../../lib/backend/data";
import { cerrarSesion } from "../../redux/actions/sesion";

function DebounceSelect({ condicion, key_label, fields_search, labelFunction, key_value, fetchOptions, debounceTimeout = 300, ...props }) {
  const [fetching, setFetching] = useState(true);
  const [ value, setValue ] = useState(props.mode === "multiple" ? [] : null)
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const selectRef = useRef(null);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      let default_search = {...condicion, title: { $regex: value, $options: "i" } }

      if(fields_search) if(Array.isArray(fields_search) !== false) {
        default_search = {
          $or: fields_search.map(field => {
            return ({ ...condicion, [field]: { $regex: value, $options: 'i' } })
          })
        }
      }

      if(!value) default_search = {...condicion}


      fetchOptions(default_search).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return setFetching(false);
        }
        setOptions(newOptions);
        setFetching(false);
        
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const init = async () => {
      const data = await fetchOptions(condicion)
      if(data) if(Array.isArray(data) !== false) setOptions(data)
      let seleccionado = 'Cargando datos...'

      let opciones_selector = data.filter(m => m[key_label]).map(op => ({ value: op[key_value], label: labelFunction ? labelFunction(op) : op[key_label] }))
      if( props.value ){
        if(Array.isArray(props.value)){
          if(opciones_selector.length > 0){
            seleccionado = props.value
          } else {
            seleccionado = []
          }
        } else {
          if(opciones_selector.findIndex(e => e.value === props.value) > -1) seleccionado = props.value
        }
        } else {
          seleccionado = props.mode === "multiple" ? [] : null
        }
        
        if(seleccionado === "Cargando datos...") seleccionado = null
        setValue(seleccionado)
        setFetching(false)
  }
  useEffect(() => {
    init()
  }, [])

  const handleChange = (newValue) => {
    if(!newValue){
      setValue(newValue)
      if(props.module === "multiple"){
        if(props.onChangeValue) return props.onChangeValue([])
      } else {
        if(props.onChangeValue) return props.onChangeValue(false)
      }
      return
    }
    if(Array.isArray(newValue) !== false){
      console.log({newValue})
      const filtrar = options.filter(e => newValue.includes(e._id))
      setValue(newValue)
      if(props.onChangeValue) return props.onChangeValue(filtrar)
    } else {
      setValue(newValue)
      const i = options.findIndex(e => e._id === newValue)
      if(i > -1){
        if(props.onChangeValue) return props.onChangeValue(options[i])
      } else {
        return false
      }
    }
  } 
  let opciones_selector = options.filter(m => m[key_label]).map(op => ({ value: op[key_value], label: labelFunction ? labelFunction(op) : op[key_label] }))

  
  return <div>
    <Select
      ref={selectRef}
      value={value ? value : false}
      mode={props.mode}
      filterOption={false}
      showSearch
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      // {...props}
      allowClear
      onChange={handleChange}
      options={opciones_selector}
    />
  </div>
}


const SelectorGlobal = (props) => {
    const {
        value,
        titulo,
        condicion,
        key_label,
        labelFunction,
        key_value,
        module,
        isMulti,
        fields_search,
        defaultValue
    } = props
    const [ loading, setLoading ] = useState(false)
    const [ opcionesComplete, setOpcionesComplete ] = useState([])
    const [ opciones, setOpciones ] = useState([])
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch()

    
     const obtenerRegistros = async (cond) => {
        return fetch(`${urlapi}/${module}/list/select`, {
          method: 'POST',
          body: JSON.stringify({
            condicion: cond
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
              return res
            }
            return []
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return []
          })
      }

      useEffect(() => {
        // obtenerDatos()
      }, [ condicion ])

    
      if(loading) return <Spin />
      
      return <div>
        {contextHolder}

        <Form layout="vertical">
            <Form.Item label={titulo ? titulo : "Selecciona"}>
            <DebounceSelect
            mode={isMulti===true ? "multiple" : false}
            value={value}
            condicion={condicion}
            key_label={key_label}
            key_value={key_value}
            fields_search={fields_search}
            labelFunction={labelFunction}
            // placeholder="Select users"
            fetchOptions={obtenerRegistros}
            onChangeValue={(newValue) => {
              if(props.onChange) props.onChange(newValue)
              // setValue(newValue);
            }}
            style={{
              width: '100%',
            }}
          />
                    </Form.Item>
            </Form>
      </div>
}

export default SelectorGlobal