import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import ButtonGroup from "antd/es/button/button-group"
import { Button } from "antd"
import { cerrarSesion } from "../../redux/actions/sesion"

const FuentesDato = (props) => {
    let fuentes_default = [{ value: "", label: "Móvil", selected: true }]
    const [ fuenteSeleccionada, setFuenteSeleccionada ] = useState('')
    const [ fuentes, setFuentes ] = useState(fuentes_default)
    const [ loading, setLoading ] = useState(true)
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)

    useEffect(() => {
        refrescarListado()
    }, [])

    const refrescarListado = async () => {
    
        return fetch(`${urlapi}/configuracion/tipo?tipo=gps-integracion`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                return false
            } else if(Array.isArray(res) !== false){
                setFuentes([...fuentes_default, ...res.map(e => ({ value: e.sub_tipo, label: e.sub_tipo }))])
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }

    const seleccionar = (i) => {
        setFuentes(prev => {
            let actual = [...prev]
            const iterar = actual.map((e,pos) => {
                e.selected = pos === i ? true : false

                if(pos === i){
                    props.onChange(e.value)
                }
                return e
            })

            return [...[], ...iterar]
        })
    }

    return <div>
        <ButtonGroup className="mb-0">
            {
                fuentes.map((fue,i) => {
                    return <Button size="small" className="mb-0" type={fue.selected ? "primary" : "default"} md={3} xs={12} key={`r-${i}`} style={{ textTransform: "uppercase" }} onClick={() => seleccionar(i)} className="mb-3">{fue.label}</Button>
                })
            }
        </ButtonGroup>
    </div>

}

export default FuentesDato