const tipos_pallet = [
    { value: 'pallet_americano', label: 'Pallet americano 1.200 mm. x 1.000 mm.', short: "Paller americano" },
    { value: 'pallet_europeo', label: 'Pallet europeo 1.200 mm. x 800 mm.', short: "Paller europeo" },
]

const stringByPalletType = (val) => {
    let campos = {}
    for( const campo of tipos_pallet ){
        campos[campo.value] = campo.short
    }
    return campos[val] ? campos[val] : "Sin información"
}

module.exports = {
    tipos_pallet,
    stringByPalletType
}