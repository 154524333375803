import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Button, Checkbox, Col, Divider, Form, Input, Pagination, Row, Select, Table, Typography, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { fechaUTCATexto, fechaUTCATextoSimple } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { FileExcelFilled, SearchOutlined } from "@ant-design/icons";
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import ContadoresVehiculos from "./contadores"
import GraficosCamposPersonalizados from "../../subComponents/graficos/conductores"
import ModalPersonalizado from "../../subComponents/general/modales/modal_personalizado"
import { rutas } from "../../lib/routes/routes"
import { encontrarLlaveValorEnEsquema } from "../../lib/helpers/interfaz"
import PersonalizarColumnas from "../../subComponents/interfaz/columnas_personalizar"
import EncabezadoPesonalizado from "../../subComponents/general/tablas/encabezado"
import MostrarMoviles from "../../lib/helpers/mostrar_moviles"
import MostrarEmails from "../../lib/helpers/mostrar_emails"
import { stringByStatusTrip } from "../../lib/helpers/viajes"
import HelperModal from "../../subComponents/general/helperModal"
import SelectorGlobal from "../../subComponents/general/selector"
import { getLabelForVehicle } from "../../lib/helpers/selectores"
import { obtenerQueryTiempoEntrega, obtenerTiemposEntregaOpciones } from "../../lib/helpers/main"
import ListadoViajes from "../Viajes/listado"
import ResumenRuta from "./resumen_ruta"
import { estilo_moving_truck } from "../../lib/estilo_sitio"

const ListadoRutas = (props)=> {
    const {
        condicion_default,
        showFilters,
        typeView,
        showCustomFieldsData,
        showCounters,
        hideSearch,
        showSelection
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [ conductores, setRegistros] = useState([])
    const [ loadingRegistros, setLoadingRegistros] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ condicionTiempoEntrega, setCondicionTiempoEntrega ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const pais = useSelector(state => state.pais)
    const trash                     = props.trash ? props.trash : false
    const idioma                    = useSelector(state => state.idioma)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const tipo                      = props.tipo ? props.tipo : "link"
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const titulo                    = props.title ? props.title : 'registros'
    const module = 'rutas'
    const key_interfaz              = 'tabla_rutas'
    const key_esquema               = 'RUTAS'
    const opciones_tiempo_entrega   = obtenerTiemposEntregaOpciones(pais)
    const [ key_input_filter, setKeyInputFilter ] = useState(0)
    const exclude = []

    const eliminarMultiples = async ()=>{
        setLoadingRegistros(true)

        const seleccionados = conductores.filter(e => e.seleccionado).map(e => e._id)
        return fetch(`${urlapi}/${module}/masive`,{
            method:'DELETE',
            body: JSON.stringify({
                ids: seleccionados
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingRegistros(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingRegistros(false)
            } else if(res.deletedCount){
                return window.location.reload()
            }
            return setLoadingRegistros(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingRegistros(false)
        })
    }
    const obtenerDatos = async (page, query)=>{
        setLoadingRegistros(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/${module}/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingRegistros(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingRegistros(false)
            }
            if(Array.isArray(res.datos) !== false){
                setRegistros(res.datos)
                setTotal(res.total)
            }
            return setLoadingRegistros(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingRegistros(false)
        })
    }

    useEffect(() => {
        obtenerDatos(1, false)
    }, [])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerDatos(e, condicion_busqueda)
    }
    
    const refrescarBusqueda = () => {
        setPagina(1)
        let cond = {}
        Object.keys(filtroBusqueda).map(llave => {
            if(filtroBusqueda[llave]['$in']){
                if(Array.isArray(filtroBusqueda[llave]['$in']) !== false){
                    if(filtroBusqueda[llave]['$in'].length > 0) cond[llave] = filtroBusqueda[llave]
                }
            } else if(Array.isArray(filtroBusqueda[llave]) !== false){
                    if(filtroBusqueda[llave].length > 0){
                        cond[llave] = { $in: filtroBusqueda[llave] }
                    }
                }
            
        })

        if(condicionTiempoEntrega) cond = {...cond, ...condicionTiempoEntrega}
        return obtenerDatos(1, cond)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination current={pagina} total={(cantidad*10)} showSizeChanger={false} onChange={handleChangePagina} />
        </div>
    }

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/vehicles`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setPagina(1)
            setCondicionBusqueda({})
            return obtenerDatos(1, {})
        }
        const condicion = {
            id: parseInt(campoBusqueda)
        //   $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerDatos(1, condicion);
      };

      const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

      
    const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmitCapture={handleSubmit} >
                <Row gutter={10}>
                    <Col md={6}>
                        <Form.Item className="mb-2">
                            <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Busca por número`} />
                        </Form.Item>
                    </Col>
                    <Col md={4}>
                        <Form.Item >
                            <Button  onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                        </Form.Item>
                    </Col>
                    <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col>
                </Row>
                </Form>
      }

      const deseleccionarTodos = (e) => {
        setRegistros([...[], ...conductores.map(veh => { 
            if(exclude){
                if( Array.isArray(exclude) !== false ){
                    if(exclude.includes(veh._id)) return veh
                }
            }
            veh.seleccionado = e.target.checked
            return veh 
        })])
      }

      const checkForEnabledValue = (id) => {
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const validarChecked = (seleccionado, id) => {
        if(seleccionado === true) return true
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const handleChangeSeleccion = (e) => {
        const { name } = e.target
        const valor = e.target.checked
        setRegistros(prev => {
            let actual = [...prev]
            const i = actual.findIndex(ve => ve._id === name )
            actual[i].seleccionado = valor
            return actual
        })
      }

      const seleccionarRegistro = data => {
        // deseleccionarTodos({ target: false })
        if(props.onSelect) props.onSelect(data)
    }

      const showByType = (tipo, data) => {
        switch (tipo) {
            case "funcion":
                return <b className="hover" onClick={() => seleccionarRegistro(data)} style={{ textTransform: "uppercase" }}>{data.id}</b>
            default:
                return <Link to={`${rutas.rutas.slug}/${data._id}`}>{data.id ? data.id : "VER DETALLES"}</Link>
        }
    }

      const mostrarTabla = () => {
        if(loadingRegistros) return <CargandoTabla />

        const component_id = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="id" />} render={(data) => {
                    return <div>
                        {showByType(typeView, data)}
                    </div>
        }} />

        const component_fecha = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="createdAt" />} render={(data) => {
            return <div>{fechaUTCATexto(data.createdAt)}</div>
        }} />
        const component_conductor = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="conductor" />} render={(data) => {
            return <div>{data.conductor}</div>
        }} />
        
        const component_cumplimiento = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="cumplimiento" />} render={(data) => {
            let retrasos = data.retrasos ? data.retrasos : []

            const component_data = retrasos.length > 0 ? <ListadoViajes condicion_default={{ _id: { $in: retrasos.map(via => via._id) } }} /> : false
            
            return <div>
                { retrasos.length > 0 ? <ModalPersonalizado version="v2" slug="viajes_retrasados" title="Viajes retrasados" typeView="modal" component={component_data} /> : <Paragraph className="mb-0 mt-0">SIN RETRASOS</Paragraph> }
            </div>
        }} />
        const component_vehiculo = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="vehiculo" />} render={(data) => {
            return <div>{data.vehiculo} </div>
        }} />
        const component_status = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="status" />} render={(data) => {
            
            const component = <ResumenRuta id_ruta={data._id} />
            return <div>
                {data.finalizada === true ? "FINALIZADA" : "PENDIENTE"} 
                <ResumenRuta id_ruta={data._id} typeView="modal" />
                {/* <ModalPersonalizado typeView="modal" slug="Resumen de la ruta" component={component} /> */}
                </div>
        }} />

        const component_postulable = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="postullable" />} render={(data) => {
            return <div>{data.postullable === true ? "SI": "NO"}</div>
        }} />

          return <Table dataSource={conductores} tableLayout="fixed" pagination={false}  size="small" bordered scroll={estilo_moving_truck.scroll_row} locale={{ emptyText: <HelperModal tipo="viajes" /> }}>
            { showSelection === true ? <Column title={<Checkbox onChange={deseleccionarTodos} />} render={(data) => {
                return <Checkbox disabled={checkForEnabledValue(data._id)} checked={validarChecked(data.seleccionado, data._id)} name={data._id} onChange={handleChangeSeleccion}/>
            }} /> : false }
            { encontrarLlaveValorEnEsquema("id", interfaz_usuario[ key_interfaz ]) === false ? false : component_id }
            { encontrarLlaveValorEnEsquema("status", interfaz_usuario[ key_interfaz ]) === false ? false : component_status }
            { encontrarLlaveValorEnEsquema("cumplimiento", interfaz_usuario[ key_interfaz ]) === false ? false : component_cumplimiento }
            { encontrarLlaveValorEnEsquema("conductor", interfaz_usuario[ key_interfaz ]) === false ? false : component_conductor }
            { encontrarLlaveValorEnEsquema("vehiculo", interfaz_usuario[ key_interfaz ]) === false ? false : component_vehiculo }
            { encontrarLlaveValorEnEsquema("postullable", interfaz_usuario[ key_interfaz ]) === false ? false : component_postulable }
            { encontrarLlaveValorEnEsquema("createdAt", interfaz_usuario[ key_interfaz ]) === false ? false : component_fecha }
      </Table>
      }

      const agregarFiltro = (key, value) => {
        console.log(value)
        setFiltroBusqueda(prev => {
            let actual = {...prev}
            if(value.length > 0){
                actual[key] = { $in: value.map(v => v._id) }
            } else {
                delete actual[key]
            }
            return actual
        })
      }

      const agregarFiltroEmbedido = (value, llave, llave_busqueda) => {
        return setFiltroBusqueda(prev => {
            let actual = {...prev}
            let llave_valor = "_id"
            if(llave_busqueda) llave_valor = llave_busqueda
            if(value.length < 1) {
                delete actual[llave]
            } else {
                actual[llave] = [...value.map(e => e[llave_valor])]
            }

            return actual
        })
      }

      const handleChangeTiempo = (e) => {
        if(!e) return setCondicionTiempoEntrega(false)
        const query = obtenerQueryTiempoEntrega(e)
        return setCondicionTiempoEntrega(query)
      }

      const resetFilters = () => {
        setFiltroBusqueda({})
        setKeyInputFilter(key_input_filter + 1)
        obtenerDatos(1, condicion_default ? condicion_default : {})
    }

      const mostrarFiltrosBusqueda = () => {
        const mostrarButtonReset = Object.keys(filtroBusqueda).length > 0 ? true : false
        return <div className="mt-0">
            <Form layout="vertical">
            <Row gutter={10}>
                <Col md={6} >
                    <SelectorGlobal key={key_input_filter} titulo="Requisito solicitados" condicion={{ }} isMulti={true} module="requisitos/tipo" key_label="titulo" key_value="_id" onChange={(data) => agregarFiltroEmbedido(data, 'requisitos._id', '_id')} />
                </Col>
                <Col md={5} className="mb-0">
                    <SelectorGlobal key={key_input_filter} fields_search={[ "nombres","apellido_m","apellido_p" ]} titulo="Conductor" condicion={{ }} isMulti={true} module="conductores" key_label="nombres" key_value="_id" onChange={(data) => agregarFiltro('id_conductor', data)} />
                </Col>
                <Col md={5} className="mb-3">
                    <SelectorGlobal key={key_input_filter} titulo="Vehículo" labelFunction={getLabelForVehicle} condicion={{ }} isMulti={true} module="vehiculos" key_label="patente" key_value="_id" onChange={(data) => agregarFiltro('id_vehiculo', data)} />
                </Col>
                <Col md={4} className="mb-3">
                    <Paragraph style={{ marginBottom: 8 }}>Click para actualizar</Paragraph>
                <Button loading={loadingRegistros} onClick={() => refrescarBusqueda()} style={{ width: "100%" }} icon={<SearchOutlined />} >ACTUALIZAR</Button>
                </Col>
                <Col md={4} className="mb-3">
                    <Title className="mb-2 mt-0" level={5}>FILTRAR</Title>
                    <Button disabled={!mostrarButtonReset}  style={{ width: "100%" }} onClick={() => resetFilters()}>BORRAR FILTROS</Button>
                </Col>
                {/* <Col md={24} className="mb-3">
                    <SelectorGlobal titulo="Equipo utilizado" condicion={{ }} isMulti={true} module="equipos" key_label="titulo" key_value="_id" onChange={(data) => agregarFiltroEmbedido(data, 'equipos._id', '_id')} />
                </Col>
                <Col md={24} className="mb-3">
                    <SelectorGlobal titulo="Categoría de equipos" condicion={{ tipo: "equipo" }} isMulti={true} module="categorias" key_label="title" key_value="_id" onChange={(data) => agregarFiltroEmbedido(data, 'equipos.id_categoria', '_id')} />
                </Col>
                <Col md={24} className="mb-3">
                    <SelectorGlobal titulo="Sub Categoría de equipos" condicion={{ tipo: "equipo" }} isMulti={true} module="sub-categorias" key_label="title" key_value="_id" onChange={(data) => agregarFiltroEmbedido(data, 'equipos.id_sub_categoria', '_id')} />
                </Col> */}
            </Row>
            </Form>
            
        </div>
      }

      const mostrarContadores = () => {

        const component = <ContadoresVehiculos onFilter={(condition) => {
            setPagina(1)
            setCondicionBusqueda(condition)
            obtenerDatos(1, condition)
        }} />

        return showCounters === true ? <Col><ModalPersonalizado typeView="modal" slug="distrubion" component={component} /></Col> : false
      }
      
      const mostrarDatosPersonalizados = () => {
        const component = <GraficosCamposPersonalizados typeView="modal" tipo="viaje" />
        return showCustomFieldsData === true ? <Col><ModalPersonalizado typeView="modal" slug="informe_personalizado" component={component} /></Col> : false
      }

      const accionesEspeciales = () => {
        const seleccionados = conductores.filter(e => e.seleccionado)
        return <div style={{ marginBottom: 20 }}>
            <Button disabled={seleccionados.length < 1} style={{ marginRight: 10 }} size="small" ><Link to={`${rutas.ruteo.slug}/${seleccionados.map(p => p._id).join('|')}`}>RUTEAR</Link> </Button>
            <Button disabled={seleccionados.length < 1} loading={loadingRegistros} style={{ marginRight: 10 }} size="small" onClick={() => eliminarMultiples()} >ELIMINAR</Button>
        </div>
      }

    const mostrarRegistros = () => {

        return <div>
            <Divider />
            {
                showFilters === true ? <Row gutter={15}>
                <Col md={24}>
                    {mostrarFiltrosBusqueda()}
                    {buscador()}
                </Col>
                <Col md={24}>
                    <Row gutter={15} className="mb-3">
                    {mostrarContadores()}
                    {mostrarDatosPersonalizados()}
                    </Row>
                    {paginacion(data.pagina, total)}
                    {accionesEspeciales()}
                    {mostrarTabla()}
                </Col>
            </Row> : <div>
                    {mostrarContadores()}
                    {buscador()}
                    {paginacion(data.pagina, total)}
                    {accionesEspeciales()}
                    {mostrarTabla()}
                </div>
            }
            
            
        </div>
    }

    return <div>
        {contextHolder}
        {mostrarRegistros()}
    </div>
}
export default ListadoRutas