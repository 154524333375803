import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Button, Card, Modal } from "antd"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { TEXT_LANG } from "../../../lib/lang/main"
import { BsCardChecklist } from "react-icons/bs"
import { botones } from "../../../lib/global/data"

const ModalPersonalizado = (props) => {
    const {
        slug,
        icon,
        typeView,
        component,
        version,
        title
    } = props
    const pais = useSelector(state => state.pais)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const WrappedComponent = (props) => {
        const {
            component
        } = props
        // Hacer una copia del componente y asignarle un nuevo onSelect
        const NewComponent = React.cloneElement(component, {
            onSelect: (data) => {
                // Aquí puedes capturar y procesar los datos
                seleccionarRegistro(data);
    
                // Si el componente original tenía un onSelect, lo invocas aquí también
                if (component.props && component.props.onSelect) {
                    component.props.onSelect(data);
                    // if(props.)
                }
            }
        });
    
        return NewComponent;
    }

    const handleCancel = () => {
        setIsModalOpen(false);
      };

      const seleccionarRegistro = (data) => {
        // if(props.onSelect) props.onSelect(data)
        // relacionar(data)
        console.log({ a: data})
        setIsModalOpen(false)
        // setSeleccionado(data)
    }

    const showModalV2 = () => {
        return <div>
            <Modal
            width="80%"
            footer={[]}
            title={TEXT_LANG(botones[slug]?.label, pais)}
            open={isModalOpen} 
            onCancel={handleCancel}
        >
        {component}
        </Modal>
        <Button size="small" onClick={() => setIsModalOpen(true)} style={{ textTransform: "uppercase" }} >{title ? title : "VER DETALLES"}</Button>
        </div>
    }

    const visualizacionTipoModal = () => {
        if(version === "v2") return showModalV2()
        const title = TEXT_LANG(botones[slug]?.label, pais)
        return <div>
            <Modal
            width="80%"
            footer={[]}
            title={TEXT_LANG(botones[slug]?.label, pais)}
            open={isModalOpen} 
            onCancel={handleCancel}
        >
        <WrappedComponent component={component} />
        </Modal>
        <Card size="small" className=" hover" onClick={() => setIsModalOpen(true)} >
        
            <Title level={5} className="m-0" >{ icon ? icon : <BsCardChecklist style={{ verticalAlign: "middle" }} size={20} />} { title ? title : slug }</Title>
        </Card>
        </div>
    }

    const visualizarDatos = () => {
        if(typeView==="modal") return visualizacionTipoModal()
        return component
    }

    return <div>
        {visualizarDatos()}
    </div>
}

export default ModalPersonalizado