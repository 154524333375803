import React, { useEffect, useState } from "react"
import data, { config_s3 } from '../../lib/backend/data'
import { useDispatch, useSelector } from "react-redux"
import { fechaATexto, formatYMD } from "../../lib/helpers/helpers"
import { formatDateHoy } from "../../lib/helpers/dates"
import { Alert, Badge, Button, Card, Col, Form, Image, Input, Modal, Pagination, Progress, Row, Select, Spin, Table, Tooltip, Upload, message } from "antd"
import Paragraph from "antd/es/typography/Paragraph"
import CargandoTabla from "./cargando"
import { cerrarSesion } from "../../redux/actions/sesion"
import { TbAlertCircleFilled, TbCircleCheckFilled, TbProgressAlert, TbSend } from 'react-icons/tb'
import Title from "antd/es/typography/Title"
import { FileOutlined, InfoCircleOutlined, UploadOutlined } from "@ant-design/icons"
import S3FileUpload from "react-s3/lib/ReactS3"
import { estilo_moving_truck } from "../../lib/estilo_sitio"
import HelperModal from "../../subComponents/general/helperModal"
import Column from "antd/es/table/Column"
import TagSinDatos from "../../subComponents/general/tag_sin_datos"
import CargaImagen from "../../subComponents/medios/carga_imagen"


const ListadoRequisitos = (props) => {
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loadingEliminar, setLoadingEliminar] = useState(false);
    const sesion = useSelector(state => state.miusuario)
    const [ vehiculos, setVehiculos] = useState([])
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ showModal, setShowModal] = useState(false)
    const [ currentRequisito, setCurrentRequisito] = useState(false)
    const [ loadingVehiculos, setLoadingVehiculos] = useState([])
    const [ loadingCambios, setLoadingCambios] = useState(false)
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ idLoading, setIdLoading ] = useState(false)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const [messageApi, contextHolder] = message.useMessage();
    const trash = props.trash ? props.trash : false
    const show_filter = props.show_filter ? props.show_filter : false
    const titulo = props.title ? props.title : 'registros'

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerRequisitos(page)
    }
    
    const handleChangePagina = (e,page) => {
        setPagina(page)
        return obtenerRequisitos(page, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination showSizeChanger={false} total={cantidad} defaultCurrent={pagina} onChange={handleChangePagina} />
        </div>
    }

    const obtenerRequisitos = async (page, query)=>{
        setLoadingVehiculos(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/requisitos/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingVehiculos(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingVehiculos(false)
            }
            if(Array.isArray(res.datos) !== false){
                setVehiculos(res.datos)
                setTotal(res.total)
            }
            return setLoadingVehiculos(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingVehiculos(false)
        })
    }

    useEffect(() => {
        obtenerRequisitos(1, false)
    }, [])


    const handleChangeCampo = (e) => {
        const { value } = e.target
        return setCampoBusqueda(value)
    }

    const filtro = () => {
        if(!show_filter) return false
        return <div>
            <Row >
                <Col item xs={12}>
                    <Form layout="vertical">
                        <Form.Item label="Buscar por nombre, apellido, email o móvil">
                            <Input id="outlined-basic" variant="outlined" onChange={handleChangeCampo} />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row >
                <Col item xs={6}>{paginacion(data.pagina, total)}</Col>
                <Col item xs={6} style={{ textAlign: 'right' }}><h3 sx={{ m:0 }} >{total} {titulo}</h3></Col>
            </Row>
        </div>
    }

    const obtenerRegistro = (id) => {
        if(idLoading !== false) return
        setIdLoading(id)
        return fetch(`${data.urlapi}/requisitos?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setIdLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setIdLoading(false)
            } else if(res._id){
                setCurrentRequisito(prev => ({...{}, ...res}))
                setIsModalOpen(true)
            }
            return setIdLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setIdLoading(false)
        })
    }

    const handleModalRF = () => {
        return setShowModal(false)
    }

    const eliminarRequisito = (id) => {
        setLoadingEliminar(true)
        return fetch(`${data.urlapi}/requisitos?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success('Eliminado exitosamente')
                setIsModalOpen(false)
                return setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
            return setLoadingEliminar(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingEliminar(false)
        })
    }

    const guardarCambios = (enviar) => {
        setLoadingCambios(true)
        return fetch(`${data.urlapi}/requisitos`,{
            method:'PUT',
            body: JSON.stringify(enviar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            setCurrentRequisito({ ...{}, ...enviar })
            console.log(res)
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingCambios(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingCambios(false)
            } else if(res._id){
                messageApi.success('Actualizado exitosamente')
                const instance = vehiculos
                const i = instance.findIndex(v => v._id === res._id)
                if(i > -1){
                    instance[i] = res
                    setVehiculos(prev => [...[], ...instance])
                }
                setIsModalOpen(false)
            }
            return setLoadingCambios(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingCambios(false)
        })
    }

    const handleCancel = () => {
        setIsModalOpen(false)
      };

    const handleChangeArchivo = async (e) => {
        if(e.file.status !== "error") return false
        setLoadingCambios(true)
        const archivo = e.file.originFileObj
        return S3FileUpload.uploadFile(archivo, config_s3)
        .then( async data => {
            console.log(data)
        if(!data.location) {
            messageApi.error('No pudimos cargar tu imagen')
            return setLoadingCambios(false)
        }
            const instancia_req = JSON.parse( JSON.stringify(currentRequisito) )
            instancia_req.valor = data.location
            return guardarCambios(instancia_req)
        })
        .catch(err => {
            console.log(err)
            setLoadingCambios(false)
            return false
        })
    }

    const handleChangeArchivoV2 = async (url) => {
        if(!url) return messageApi.error('No pudimos cargar tu imagen')
        setCurrentRequisito(prev => {
            const actual = { ...{}, ...prev }
            actual.valor = url
            guardarCambios(actual)
            return { ...{}, ...actual }
        })
    }

    const ModalRegistrosFotograficos = () => {
        let titulo              = 'No disponible'
        let url_download        = ''

        if(!isModalOpen) return false

        if(typeof currentRequisito.permiso_modelo === 'object'){
            titulo = currentRequisito.permiso_modelo.titulo
        }

        if(currentRequisito.valor){
            url_download = currentRequisito.valor.split('/').pop()
        }

        return <Modal open={isModalOpen} maskClosable={false} footer={[]} onCancel={handleCancel} >
                    {
                        currentRequisito.valor ? <div>
                            
                            <Card className="mb-3">
                                <FileOutlined style={{ fontSize: 30 }} />
                                <Title level={5} className="mt-0 mb-0">{url_download}</Title>
                                <a href={currentRequisito.valor} target="_blank"><Button type="primary">DESCARGAR</Button> </a>
                            </Card>
                            
                            </div> : <div>
                            <TbProgressAlert style={{ fontSize: 20 }} />
                            <Paragraph variant="h6" xs={{ mb:0 }}>Sin datos cargados</Paragraph>
                        </div>
                    }
                    <CargaImagen onChange={(e) => handleChangeArchivoV2(e)} />
                    {/* <Upload showUploadList={false} action={false} onChange={(e) => handleChangeArchivo(e)}> <Button loading={loadingCambios} icon={<UploadOutlined />}>{url_download ? "ACTUALIZAR ARCHIVO" : "SUBIR ARCHIVO"}</Button></Upload> */}
        </Modal>
    }

    const mostrarObservaciones = (status, observaciones) => {
        if(status !== "aprobado"){
            return observaciones
        }
        return ''
    }

    const showHTMLStatusRequeriment = (value) => {
        switch (value) { 
            case "pendiente":
                return <Title className="mb-2 mt-0" style={{ fontSize: 15, verticalAlign: "middle", color:"blue", fontWeight: "bold" }}><TbSend style={{ fontSize: 15, color: "blue" }} /> {value ? value.toUpperCase() : 'SIN INFORMACIÓN'}</Title>
            case "aprobado":
                return <Title className="mb-2 mt-0" style={{ fontSize: 15, verticalAlign: "middle", color:"green", fontWeight: "bold" }}><TbCircleCheckFilled style={{ fontSize: 15, color: "green" }} /> {value ? value.toUpperCase() : 'SIN INFORMACIÓN'}</Title>
            case "rechazado":
                return <Title className="mb-2 mt-0" style={{ fontSize: 15, verticalAlign: "middle", color:"red", fontWeight: "bold" }}><TbAlertCircleFilled style={{ fontSize: 15, color: "red" }} /> {value ? value.toUpperCase() : 'SIN INFORMACIÓN'}</Title>
            default:
                return <Paragraph className="mb-2 mt-0" >{value ? value.toUpperCase() : 'SIN INFORMACIÓN'}</Paragraph>
        }

        return 
    }

    const mostrarRegistros = () => {
        if(vehiculos.length < 1) return <h3>No hay registros</h3>
        
        const component_archivo = <Column title="archivo" render={(data) => {
            let url_download        = ''
            if(data.valor) url_download = data.valor.split('/').pop()

            return <div>{ url_download ? <Paragraph className="mt-0 mb-3" >Archivo cargado: <b style={{ color: "red" }}>{url_download}</b></Paragraph> : <TagSinDatos/> }</div>
        }} />
        const component_comentario = <Column title="comentarios" render={(data) => {
            const comentario = mostrarObservaciones(data.status, data.comentario)
            return <div>{ comentario ? <Alert style={{ padding: "5px 10px" }} type="warning" message={comentario} /> : <TagSinDatos/> }</div>
        }} />

        const component_estado = <Column title="estado" render={(data) => {
            return <div>{showHTMLStatusRequeriment(data.status)}</div>
        }} />
        
        const component_acciones = <Column title="acciones" render={(data) => {
            return <div><Button size="small" loading={idLoading === data._id} onClick={() => obtenerRegistro(data._id)}>VER DETALLES</Button></div>
        }} />

        const component_titulo = <Column title="titulo" render={(data) => {
            let titulo = 'No disponible'
            let solicitado = false

            if(typeof data.permiso_modelo === 'object'){
                titulo = data.permiso_modelo.titulo
                if(data.permiso_modelo.status === "active") solicitado = true
            }
            return <div>{titulo} { solicitado === true ? <Tooltip title="Este requisito es requerido obligatoriamente"><InfoCircleOutlined /></Tooltip> : false }</div>
        }} />

        return <div>
            <Table dataSource={vehiculos} pagination={false} scroll={estilo_moving_truck.scroll_row} locale={{ emptyText: <HelperModal tipo="requisitos" /> }}>
            {component_titulo}
            {component_archivo}
            {component_comentario}
            {component_estado}
            {component_acciones}
            </Table>
        </div>
    }

    if(loadingVehiculos) return <CargandoTabla />

    return <div>
        {filtro()}
        {ModalRegistrosFotograficos()}
        {mostrarRegistros()}
        {contextHolder}
    </div>
}

export default ListadoRequisitos