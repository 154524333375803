import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Avatar, Breadcrumb, Button, Card, Col, Collapse, DatePicker, Divider, Form, Image, Input, Layout, List, Menu, Modal, Popconfirm, Row, Select, Spin, Steps, Switch, Tabs, Tag, Tooltip, Typography, Upload, message, notification, theme } from "antd"
import { HomeOutlined, LoadingOutlined, PlusOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { duracionTiempoString, fechaATexto, fechaATextoExperimental, fechaATextoSimple, fechaATextoSimpleExperimental, fechaUTCATexto, fechaUTCATextoSimple, fechaUTCATiempo, filterOption, formatYMD, limpiarStringLetrasNumeros, limpiarStringNumeros, siEsRequeridoEnEsquema, validateEmail } from "../../lib/helpers/helpers"
import { config_s3, maps_key, timezone, url_images, urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import CamposObligatoriosLeyenda from "../../subComponents/general/formularios/campos_obligatorios"
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps"
import { AiFillCar, AiFillMinusCircle, AiOutlineMail, AiOutlinePlus } from "react-icons/ai"
import { FaClockRotateLeft } from "react-icons/fa6";
import { GrAdd } from "react-icons/gr"
import { GoDeviceMobile } from "react-icons/go"
import SelectorClientes from "../Clientes/selector"
import { BsBoxArrowInUpRight, BsFillBoxSeamFill, BsFillFileEarmarkPersonFill, BsTruckFlatbed } from "react-icons/bs"
import { LuTruck } from "react-icons/lu";
import ModalSeleccionContactos from "../Contactos/modal_seleccion"
import { MdAltRoute, MdDateRange, MdOutlinePlace, MdOutlineRoute, MdVerified } from "react-icons/md"
import { FiUser } from "react-icons/fi"
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/hepler_main"
import ModalSeleccionRequisitos from "../Requisitos/modal_seleccion"
import Paragraph from "antd/es/typography/Paragraph"
import CaracteristicasVehiculo from "./requisitos/caracteristicas_vehiculo"
import CamposPersonalizadosRecurso from "../../subComponents/campos_personalizados/listado_recurso"
import MediosBox from "../../subComponents/medios/medios"
import { Link, useParams } from "react-router-dom"
import dayjs from "dayjs"
import { DateTime } from "luxon"
import locale from 'antd/es/date-picker/locale/es_ES';
import ButtonGroup from "antd/es/button/button-group"
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi"
import Costos from "../Costos"
import SelectorGlobal from "../../subComponents/general/selector"
import ModalSeleccionVehiculos from "../Conductores/modal_seleccion"
import ModalSeleccionVehiculo from "../Vehiculos/modal_seleccion"
import ModalSeleccionProductos from "../Productos/modal_seleccion"
import { FaCheckCircle, FaCircle, FaFlagCheckered, FaFontAwesomeFlag, FaRegAddressCard, FaRegClock, FaRegDotCircle, FaRegUserCircle } from "react-icons/fa";
import { CgBorderStyleDashed, CgTrack } from "react-icons/cg";
import { canalEstadosString } from "../../lib/helpers/viajes"
import { debounce } from 'lodash';
import ModalSeleccionRuta from "../Rutas/modal_seleccion"
import MapaViajeDetalle from "./mapa_viaje"
import { formatoMoneda } from "../../lib/helpers/main"
import { estilo_moving_truck } from "../../lib/estilo_sitio"
import polyline from '@mapbox/polyline';
import { GiSteeringWheel } from "react-icons/gi"
import { RiPinDistanceLine } from "react-icons/ri"
import { IoHelpCircleSharp } from "react-icons/io5"
import TagSinDatos from "../../subComponents/general/tag_sin_datos"
const turf = require('@turf/turf');

const VisualizarViaje = (props) => {
    const {
        orden
    } = props
    const id = orden?._id
    const [ loading, setLoading ] = useState(false)
    const [ showPassword, setShowPassword ] = useState(false)
    const [ ubicacionConductor, setUbicacionConductor ] = useState(null)
    const [ loadingImagen, setLoadingImagen ] = useState(false)
    const [ loadingPosicionConductor, setLoadingPosicionConductor ] = useState(false)
    const [ loadingAutoSave, setLoadingAutoSave ] = useState(false)
    const [ configuracionTracking, setConfiguracionTracking ] = useState(false)
    const [ routesConductor, setRoutesConductor ] = useState([])
    const [ deleting, setDeleting ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [ uMDefault, setUMDefault ] = useState('')
    const [ seleccionaConductor, seleccionarConductor ] = useState(false)
    const [ conductor, setConductor ] = useState(false)
    const [ estados, setEstados ] = useState([])
    const [ vehiculo, setVehiculo ] = useState(false)
    const [ uMPesoDefault, setUMPesoDefault ] = useState('')
    const [ lineaTiempo, setLineaTiempo ] = useState(null)
    const default_parada = { description: "", tipo_entrega: "entrega"}
    const default_producto = { descripcion: "", tipo_entrega: "entrega"}
    const [ paradas, setParadas ] = useState([default_parada])
    const [ requisitos, setRequisitos ] = useState([])
    const [ productos, setProductos ] = useState([default_producto])
    const [messageApi, contextHolder] = message.useMessage();
    const [ usuario, setUsuario ] = useState(null)
    const [ informeRastreo, setInformeRastreo ] = useState(false)
    const dispatch = useDispatch()
    const [ condicion_sub_categoria, setCondicionSubCategoria ] = useState({ tipo: "cliente" })
    const module = 'viajes/new'
    const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
    const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)
    const [ caracteristicas, setCaracteristicas ] = useState({})
    const [current, setCurrent] = useState(0);
    const { token } = theme.useToken();
    const [ fechaConsulta, setFechaConsulta ] = useState(formatYMD())
    const [ loadingMaster, setLoadingMaster ] = useState(false)
    const [ resize, setResize ] = useState(1)
    const [ intervalo, setIntervalo ] = useState(null)
    const [api, contextHolderPush] = notification.useNotification();
    let style_small = { fontSize: 13, textTransform: "uppercase", fontWeight: 700 }


    const next = () => {
        setCurrent(current + 1);
      };
    const prev = () => {
        setCurrent(current - 1);
    };
    const contentStyle = {
        padding: 20,
        // lineHeight: '260px',
        // textAlign: 'center',
        color: token.colorTextTertiary,
        backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
      };
    
    const guardarAutomaticamente = useCallback(debounce((data) => guardarAutomatico(data), 3000), []);
      
    
    const validarRespuestaRutaMapbox = (sugerencias) => {
        let way_points          = []
        let rutas_consultadas   = []
        let errores_mensajes    = []

        for( const res of sugerencias){
            if(res.uuid){
                if(res.code) if(res.code.toString().toUpperCase() === "OK") {
                    if(res.routes) if(Array.isArray(res.routes) !== false) if(res.routes.length > 0){
                        rutas_consultadas = [...rutas_consultadas, ...[ res.routes[0] ]]
                        way_points = [...way_points, ...res.waypoints]
                    }
                } else {
                    errores_mensajes.push(res.message)
                }
            }
        }
        if(errores_mensajes.length > 0) messageApi.error(errores_mensajes.join(', '))
        // alinearMapa(way_points)
        console.log({ rutas_consultadas, sugerencias })
        if(rutas_consultadas.length > 0) {
            setRoutesConductor(rutas_consultadas)
        }
    }


    const calcularDistanciaConductor = async (origen, puntos, profile) => {
        setLoadingPosicionConductor(true)
        // openNotification("Obteniendo información de la ruta...", <div><Title level={5} className='mt-0 mb-0'><Spin /> Verás la información del camino sugerido</Title></div>)
        return fetch(`${urlapi}/geodata/direcciones`,{
            method:'POST',
            body: JSON.stringify({
                origen,
                puntos,
                profile
            }),
            headers: {
                'Content-Type':'application/json',
                // 'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            // if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                validarRespuestaRutaMapbox(res)
            }
            return setLoadingPosicionConductor(false)
        })
        .catch(error => {
            return setLoadingPosicionConductor(false)
        })
    }

    const iniciarIntervalosRastreo = (stops) => {
        const id_intervalo = setInterval(() => {
            refrescarUbicacion(stops)
          }, 4000);
          setIntervalo(id_intervalo)
    }

    const refrescarUbicacion = async (stops) => {
        const url = `${urlapi}/viajes-public/tracking-vehicle?id=${id}`
        return fetch(url, {
            method: "GET",
            headers: {
                'Content-type': "application/json",
                // 'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => res.json())
        .then(async res => {
            if(!res){
            } else if(res.location){
                if(res.location?.coordinates){
                    if(res.location.coordinates.length > 1){
                        setUbicacionConductor(prev => {
                            let actual = {...prev, ...res}
                            if(!actual) return actual
                            calcularDistanciaConductor(actual, stops.filter(st => st.finished !== true), "driving-traffic")
                            return {...{}, ...actual }
                        })
                    }
                }
            }
            return false
        })
        .catch(error => false)
    }

    const openNotification = (message, description) => {
        api.info({
            message,
            description: <div>
                {description}
                <Link to={'/login'} ><Button style={{ marginTop: 20 }} type="default" >Iniciar sesión</Button></Link>
            </div>,
            placement: "bottom",
            duration: 6
        })
    }

    const obtenerDetalles = async ( ) => {
        const url = `${urlapi}/viajes-public?id=${id}`
        return fetch(url, {
            method: "GET",
            headers: {
                'Content-type': "application/json",
                // 'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.viaje){
                let trip = res.viaje
                let stops = []
                if(res.viaje.conductor) setConductor(res.viaje.conductor)
                if(res.viaje.vehiculo) setVehiculo(res.viaje.vehiculo)
                if(res.estados) setEstados(res.estados)
                if(res.paradas) if(Array.isArray(res.paradas) !== false) if(res.paradas.length > 0){
                    stops = res.paradas.filter(e => e.tipo !== "origen")
                    setParadas(stops)
                    const origen = res.paradas.filter(e => e.tipo === "origen")
                    if(origen.length > 0){
                        trip.origen = origen[0]
                        trip.direccion = origen[0].description
                    }
                }

                if(res.viaje?.informe_rastreo) setInformeRastreo(res.viaje?.informe_rastreo)

                if(res.productos) if(Array.isArray(res.productos) !== false) if(res.productos.length > 0){
                    setProductos(res.productos)
                }
                if(res.viaje.requisitos) if(Array.isArray(res.viaje.requisitos) !== false) if(res.viaje.requisitos.length > 0){
                    setRequisitos(res.viaje.requisitos)
                }
                if(res.viaje.caracteristicas) if(typeof res.viaje.caracteristicas === "object"){
                    setCaracteristicas(res.viaje.caracteristicas)
                }
                setUsuario(trip)
                if(trip.configuracion_tracking) setConfiguracionTracking(trip.configuracion_tracking)

                if(trip.conductor) if(trip.conductor?.ubicacion){

                    if(trip.finalizado !== true){
                        setUbicacionConductor({...trip.conductor.ubicacion, patente: trip.vehiculo?.patente })
                        setTimeout(() => {
                            iniciarIntervalosRastreo( stops.map(p => ({...p, location: p.start_point})).filter(st => st.finished !== true) )
                        }, 200);
                        calcularDistanciaConductor(trip.conductor.ubicacion, stops.map(p => ({...p, location: p.start_point})).filter(st => st.finished !== true), "driving-traffic")
                    }
                }
                if(res.linea_tiempo) setLineaTiempo(res.linea_tiempo)
                setResize(prev => prev + 1)
            }
            setLoadingMaster(false)
            setTimeout(() => {
                openNotification("Inicia sesión para mejor experiencia", "Al iniciar sesión podrás ver detalles de toda la operación logística asociada con tu empresa")
            }, 300);
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoadingMaster(false)
        })
    }
    useEffect(() => {
        if(orden){
            let trip = orden.viaje
            let stops = []
            if(trip.conductor) setConductor(trip.conductor)
            if(trip.vehiculo) setVehiculo(trip.vehiculo)
            if(orden.estados) setEstados(orden.estados)
            if(orden.paradas) if(Array.isArray(orden.paradas) !== false) if(orden.paradas.length > 0){
                stops = orden.paradas.filter(e => e.tipo !== "origen")
                setParadas(stops)
                const origen = orden.paradas.filter(e => e.tipo === "origen")
                if(origen.length > 0){
                    trip.origen = origen[0]
                    trip.direccion = origen[0].description
                }
            }

            if(trip?.informe_rastreo) setInformeRastreo(trip?.informe_rastreo)

            if(orden.productos) if(Array.isArray(orden.productos) !== false) if(orden.productos.length > 0){
                setProductos(orden.productos)
            }
            if(trip.requisitos) if(Array.isArray(trip.requisitos) !== false) if(trip.requisitos.length > 0){
                setRequisitos(trip.requisitos)
            }
            if(trip.caracteristicas) if(typeof trip.caracteristicas === "object"){
                setCaracteristicas(trip.caracteristicas)
            }
            setUsuario(trip)
            if(trip.configuracion_tracking) setConfiguracionTracking(trip.configuracion_tracking)

            if(trip.conductor) if(trip.conductor?.ubicacion){

                if(trip.finalizado !== true){
                    setUbicacionConductor({...trip.conductor.ubicacion, patente: trip.vehiculo?.patente })
                    setTimeout(() => {
                        iniciarIntervalosRastreo( stops.map(p => ({...p, location: p.start_point})).filter(st => st.finished !== true) )
                    }, 200);
                    calcularDistanciaConductor(trip.conductor.ubicacion, stops.map(p => ({...p, location: p.start_point})).filter(st => st.finished !== true), "driving-traffic")
                }
            }
            if(orden.linea_tiempo) setLineaTiempo(orden.linea_tiempo)
            setResize(prev => prev + 1)
        }
    }, [ ])
    const requeridos = [
        { value: 'origen', label: 'Origen' },
      ];

    const handleChangeSelect = (e,name) => {
        usuario[name] = e
        guardarAutomaticamente(usuario)
        return setUsuario({...{}, ...usuario})
    }
    
    const handleChangeSelectParada = (e,name,i) => {
        paradas[i][name] = e
        return setParadas([...[], ...paradas])
    }
    
    const handleChangeSelectProducto = (e,name,i) => {

        if(name === "unidad_medida"){
            setUMDefault(e)
        } else if(name === "unidad_medida_peso"){
            setUMPesoDefault(e)
        }
        productos[i][name] = e
        return setProductos([...[], ...productos])
    }
    
    const handleChangeParada = (e,i) => {
        const { name, value } = e.target
        paradas[i][name] = value
        return setParadas([...[], ...paradas])
    }
    
    const handleChangeProducto = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        productos[pos][name] = value
        return setProductos([...[], ...productos])
    }

    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        if(name === "rut"){
            usuario[name] = limpiarStringLetrasNumeros(value)
        } else if(name === "phone"){
            usuario[name] = limpiarStringNumeros(value)
        } else {
            usuario[name] = value
        }
        guardarAutomaticamente(usuario)
        return setUsuario({...{}, ...usuario})
    }

    const uploadButton = (
        <div>
          {loadingImagen ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Subir foto
          </div>
        </div>
      );

    const guardarAutomatico = async (trip) => {
return false
        if(trip.emails_adicionales) if(trip.emails_adicionales.length > 0){
            const invalidos = trip.emails_adicionales.filter(e => !validateEmail(e.email) || !e.email)
            if(invalidos.length > 0) return 
        }
        
        if(trip.moviles_adicionales) if(trip.moviles_adicionales.length > 0){
            const invalidos = trip.moviles_adicionales.filter(e => !e.pais_codigo ||!e.movil)
            if(invalidos.length > 0) return 
        }

        setLoadingAutoSave(true)
        const url = `${urlapi}/${module}/autosave`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify({
                viaje: {
                    _id: id,
                    ...trip,
                    caracteristicas,
                    requisitos,
                },
                productos,
                paradas
            }),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => setLoadingAutoSave(false))
        .catch(error => setLoadingAutoSave(false))
    }

    
    const mostrarEmailsAdicionales = () => {
        if(!usuario.emails_adicionales) return false
        if(usuario.emails_adicionales.length < 1) return <div>
            <Title level={4} className="mt-0 mb-0" style={{ color: "gray" }}>Sin información</Title>
        </div>
        return <div>
            {
                usuario.emails_adicionales.map((field,i) => {
                    return <div key={`email-${i}`} className="mt-3">
                                { field.name ? <Title level={5} className="mt-0 mb-2">{field.name}</Title> : false }
                                <Paragraph>{field.email}</Paragraph>
                    </div>
                })
            }
        </div>
    }

    const mostrarMovilesAdicionales = () => {
        
        
        if(usuario.moviles_adicionales.length < 1) return <div>
        <Title level={4} className="mt-0 mb-0" style={{ color: "gray" }}>Sin información</Title>
    </div>
        if(!usuario.moviles_adicionales) return false
        return <div>
            {
                usuario.moviles_adicionales.map((field,i) => {
                    return <div key={`movil-${i}`} className="mt-3">
                            <Title level={5} className="mt-0 mb-2">Móvil</Title>
                            <Paragraph>{field.pais_codigo}{field.movil}</Paragraph>
                    </div>
                })
            }
        </div>
    }

    const addStop = () => {
        return setParadas(prev => [...prev, ...[default_parada]])
    } 
    const addRequeriment = (nuevo) => {
        const i = requisitos.findIndex(re => re._id === nuevo._id)
        if(i > -1) return 
        return setRequisitos(prev => [...prev, ...[nuevo]])
    } 
    
    const addProduct = () => {
        const producto = {...default_producto, unidad_medida: uMDefault, unidad_medida_peso: uMPesoDefault }
        return setProductos(prev => [...prev, ...[producto]])
    } 

    const removerParada = (i) => {
        const registros = paradas
        registros.splice(i,1)
        return setParadas(prev => [...registros, ...[]])
    }
    const removerReq = (id) => {
        const registros = requisitos
        const i = registros.findIndex(re => re._id === id)
        if(i < 0) return 
        registros.splice(i,1)
        return setRequisitos(prev => [...registros, ...[]])
    }
    
    const eliminarProducto = async (id) => {

        const url = `${urlapi}/viajes-public/producto?id=${id}`
        return fetch(url, {
            method: "DELETE",
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            }
            return false
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return false
        })
    }


    const removerProducto = (i, pro) => {
        if(pro._id){
            eliminarProducto(pro._id)
        }
        const stops = productos
        stops.splice(i,1)
        return setProductos(prev => [...stops, ...[]])
    }

    const procesarGeoParada = (place,i) => {
        if(!place) return false
                                const geo_data = procesarGeoDatosGeocode(place)

                                const instancia = JSON.parse( JSON.stringify(paradas))
                                const instancia_parada = JSON.parse( JSON.stringify( paradas[i] ))
                                // return console.log({instancia})
                                const payload = {
                                    ...instancia_parada,
                                    tipo: "entrega",
                                    description: place.formatted_address,
                                    location: {
                                        latitude:geo_data.lat,
                                        longitude:geo_data.lng
                                    },
                                    geo_data,
                                    start_point: {
                                        type: "Point",
                                        coordinates: [
                                            geo_data.lng, geo_data.lat
                                        ]
                                    },
                                    details: place
                                }
                                
                                setParadas(prevParadas => {
                                    // Crea una copia del arreglo de paradas
                                    const updatedParadas = [...prevParadas];
                                    // Actualiza el ítem en el índice especificado
                                    updatedParadas[i] = payload;
                                    return updatedParadas;
                                });
                            
    }

    const onSelectContactos = (data,i) => {
        return setParadas(prevParadas => {
            // Crea una copia del arreglo de paradas
            const updatedParadas = [...prevParadas];
            
            if(!updatedParadas[i].contactos) updatedParadas[i].contactos = []
            const final = updatedParadas[i].contactos

            for ( const co of data ){
                const i = final.findIndex(el => el._id === co._id)
                if(i > -1) continue
                final.push(co)
            }
            updatedParadas[i].contactos = [...[], ...final]
            return updatedParadas;
        });
    }

    const eliminarContacto = (pos_contact, pos_stop) => {
        setParadas(prevParadas => {
                                    // Crea una copia del arreglo de paradas
                                    const updatedParadas = [...prevParadas];
                                    // Actualiza el ítem en el índice especificado
                                    updatedParadas[pos_stop].contactos.splice(pos_contact,1)
                                    return updatedParadas;
                                });
    }
    const mostrarContactos = (contactos,i) => {
        if(!contactos) return false
        if(Array.isArray(contactos) !== true) return false
        if(contactos.length < 1) return false

        return <div>
            <Title level={5} className="mt-0 mb-2">{contactos.length} Contactos asociados a esta parada</Title>
            <List
            // header={<div>Header</div>}
            // footer={<div>Footer</div>}
            bordered
            dataSource={contactos}
            renderItem={(contact,pos) => (
                <List.Item>
                    { contact.nombres ? <Title level={5} className="m-0" ><FiUser /> {contact.nombres} { contact.apellidos ? contact.apellidos : false} {contact.email ? `· ${contact.email}` : ''} </Title> : false}
                </List.Item>
            )}
            />
        </div>
    }

    const visualizarParadas = () => {


        return <div className="mb-3">
            <Title level={4} className="mt-0 mb-3"><MdOutlinePlace style={{ verticalAlign: "middle" }} /> Puntos de entrega</Title>

            <Collapse accordion defaultActiveKey={1} items={paradas.map((stop,i) => {

        let condicion_busqueda = {}
        if(usuario.id_cliente) condicion_busqueda.id_cliente = usuario.id_cliente


        const contenido = <div key={`stop_${i}`} className="mb-3">
            <Row gutter={15}>
                <Col md={16}>
                    <Title level={4} className="mt-0 mb-0">Punto de entrega {i+1}</Title>
                    <Paragraph className="mb-0">{stop.description}</Paragraph>
                </Col>
                <Col md={8} xs={24}>
                    <Title level={4} className="mt-0 mb-0">Observaciones</Title>
                    <Paragraph className="mb-0">{stop.observaciones ? stop.observaciones : "Sin información"}</Paragraph>
                </Col>
                <Col md={24} xs={24}>
                {mostrarContactos(stop.contactos, i)}
                </Col>
            </Row>
            
        </div>

        const label = <div>
            <Row>
                <Col md={12}><MdOutlinePlace /> {stop.description ? stop.description : `Punto ${(i+1)}`}</Col>
            </Row>
        </div>

        return {
            key: (i+1),
            label,
            children: contenido
        }
        })} />
        </div>
    }

    const handleChangeCheck = (e) => {
        usuario.tipo_seleccion_productos = e === true ? "avanzado" : "simple"
        guardarAutomaticamente(usuario)
        return setUsuario({...{}, ...usuario})
    }
    
    const visualizacionSimpleProductos = () => {

        return <div>
            <Row gutter={15}>
                    <Col md={6} xs={24}>
                        <Paragraph className="mb-0 mt-0">Cantidad de bultos</Paragraph>
                        <Title level={4} className="mt-0 mb-0">{formatoMoneda(usuario.bultos)} {usuario.unidad_medida}</Title>
                    </Col>
                    <Col md={6} xs={24}>
                        <Paragraph className="mb-0 mt-0">Ancho</Paragraph>
                        <Title level={4} className="mt-0 mb-0">{formatoMoneda(usuario.ancho)} {usuario.unidad_medida}</Title>
                    </Col>
                    <Col md={6} xs={24}>
                        <Paragraph className="mb-0 mt-0">Alto</Paragraph>
                        <Title level={4} className="mt-0 mb-0">{formatoMoneda(usuario.alto)} {usuario.unidad_medida}</Title>
                    </Col>
                    <Col md={6} xs={24}>
                        <Paragraph className="mb-0 mt-0">Largo</Paragraph>
                        <Title level={4} className="mt-0 mb-0">{formatoMoneda(usuario.largo)} {usuario.unidad_medida}</Title>
                    </Col>
                    <Col md={6} xs={24}>
                        <Paragraph className="mb-0 mt-0">Peso</Paragraph>
                        <Title level={4} className="mt-0 mb-0">{formatoMoneda(usuario.peso)} {usuario.unidad_medida_peso}</Title>
                    </Col>
            </Row>
        </div>

    }
    const visualizacionAvanzadaProductos = () => {

        return <div>                
            { productos.map((pro,i) => {
    
            const contenido = <div key={`pro_${i}`} className="mb-3">
                <Card size="small" className="mb-3">
                <Row gutter={15}>
                    <Col md={4} xs={24}>
                        <Paragraph className="mb-0 mt-0">Cantidad</Paragraph>
                        <Title level={5} className="mt-0 mb-0">{formatoMoneda(pro.cantidad)}</Title>
                    </Col>
                    <Col md={20}>
                        <Paragraph className="mb-0 mt-0">Descripción</Paragraph>
                        <Title level={5} className="mt-0 mb-0">{pro.descripcion ? pro.descripcion : "Sin información"}</Title>
                    </Col>
                    <Col md={4} xs={24}>
                        <Paragraph className="mb-0 mt-0">Ancho</Paragraph>
                        <Title level={5} className="mt-0 mb-0">{pro.ancho} {pro.unidad_medida}</Title>
                    </Col>
                    <Col md={4} xs={24}>
                        <Paragraph className="mb-0 mt-0">Alto</Paragraph>
                        <Title level={5} className="mt-0 mb-0">{pro.largo} {pro.unidad_medida}</Title>
                    </Col>
                    <Col md={4} xs={24}>
                        <Paragraph className="mb-0 mt-0">Largo</Paragraph>
                        <Title level={5} className="mt-0 mb-0">{pro.largo} {pro.unidad_medida}</Title>
                    </Col>
                    <Col md={4} xs={24}>
                        <Paragraph className="mb-0 mt-0">Peso</Paragraph>
                        <Title level={5} className="mt-0 mb-0">{pro.peso} {pro.unidad_medida_peso}</Title>
                        {/* <Form.Item label="Unidad de medida de peso" required={siEsRequeridoEnEsquema('unidad_medida_peso',requeridos)} >
                            <Select value={pro.unidad_medida_peso} options={unidades_medida_peso.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelectProducto(e,"unidad_medida_peso",i)} />
                        </Form.Item> */}
                    </Col>
                </Row>
                </Card>
                { (productos.length - 1) === i ? false : <Divider style={{ marginTop: 5, marginBottom: 5 }} /> }
            </div>
            
            return contenido
            })}
        </div>
    }

    const visualizarCamposPersonalizados = () => {


        return <div>
            <CamposPersonalizadosRecurso titulo="Más información" id_target={"id"} tipo="viaje" />            
        </div>
    }

    const onSelectProducto = (data) => {
        if(Array.isArray(data) !== false){

            if(usuario.tipo_seleccion_productos !== "avanzado") setUsuario(prev => {
                let actual = {...prev}
                actual.tipo_seleccion_productos = 'avanzado'
                return {...{}, ...actual}
            })

            let nuevos = []

            for( const pro of data ){
                pro.cantidad = 1
                delete pro._id
                if(pro.sku){
                    const i = productos.findIndex(p => p.sku === pro.sku)
                    if( i < 0 ) nuevos.push(pro)
                } else {
                    nuevos.push(pro)
                }
            }
            return setProductos(prev => {
                let actual = [...prev]
                if(actual.length > 0) if(!actual[0].descripcion) actual.splice(0,1)
                guardarAutomaticamente(usuario)
                return [...actual, ...nuevos]

            })
        }
    }

    const visualizarProductos = () => {


        return <div>
            { usuario.tipo_seleccion_productos === "avanzado" ? visualizacionAvanzadaProductos() : visualizacionSimpleProductos() }
            
        </div>
    }
    const visualizarDocumentacion = () => {


        return <div>
            <Title level={4} className="mt-0 mb-3">Archivos</Title>
            <MediosBox id_destino={id} />
            
        </div>
    }
    const visualizarRequisitosPorTipo = (tipo) => {


        const filtrados = requisitos.filter(e => e.tipo === tipo)

        return <div>
            <Row gutter={15}>
            {
                filtrados.map((req,i) => {
                    return <Col md={4} key={`req-${i}`}>
                        <Card size="small">
                        <Title level={5} className="m-0">{req.titulo}</Title>
                        <Paragraph level={5} className="m-0">{req.descripcion}</Paragraph>
                        <Button size="small" type="text" icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} /> } onClick={() => removerReq(req._id)} >ELIMINAR</Button>
                        </Card>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    const onSelectRequisito = (data) => {
        addRequeriment(data)
    }
    
    const visualizarRequisitos = () => {

        const boton = <Button icon={<GrAdd /> } className="mb-3" >AÑADIR NUEVO</Button>

        const items = [
            {
                label: <div style={{ textAlign: "center" }}><AiFillCar style={{ fontSize: 20 }} /> <Title level={4} className="m-0">DOCUMENTACIÓN DEL VEHÍCULO</Title></div>,
                children: <div>
                    <ModalSeleccionRequisitos boton={boton} slug="subir_requisitos_vehiculo" typeView="modal" onSelect={(data) => onSelectRequisito(data)} condicion_busqueda={{ tipo: "vehiculo", status: "active" }} /> 
                    {visualizarRequisitosPorTipo('vehiculo')}
                </div>
            },
            {
                label: <div style={{ textAlign: "center" }}><FiUser style={{ fontSize: 20 }} /> <Title level={4} className="m-0">DOCUMENTACIÓN DEL CONDUCTOR</Title></div>,
                children: <div>
                    <ModalSeleccionRequisitos boton={boton} slug="subir_requisitos_conductor" typeView="modal" onSelect={(data) => onSelectRequisito(data)} condicion_busqueda={{ tipo: "conductor", status: "active" }} /> 
                    {visualizarRequisitosPorTipo('conductor')}
                </div>
            },
            {
                label: <div style={{ textAlign: "center" }}><BsTruckFlatbed style={{ fontSize: 20 }} /> <Title level={4} className="m-0">CARACTERÍSTICA DEL VEHÍCULO</Title></div>,
                children: <div>
                    <Paragraph >Solo los vehículos con las características que selecciones debajo podrán transportas esta carga</Paragraph>
                    <CaracteristicasVehiculo defaultData={caracteristicas} onChange={(data) => setCaracteristicas(data)} />
                    {visualizarRequisitosPorTipo('caracteristica_vehiculo')}
                </div>
            },
        ].map((item,i) => ({ key: (i+1), ...item}))

        return <div>
            <Title level={4} className="mt-0 mb-3">Requisitos</Title>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    }
    

    const onChangeStep = (e) => {
        setCurrent(e)
    }

    const handleChangeDate = (e,name) => {
        usuario[name] = e['$d']
        guardarAutomaticamente(usuario)
        return setUsuario(usuario)
    }

    const onChangeCliente = (e) => {
        usuario.id_cliente = e
        guardarAutomaticamente(usuario)
        return setUsuario(usuario)
    }

    const pasoAnterior = () => {
        return setCurrent(current-1)
    }
    const pasoSiguiente = () => {
        return setCurrent(current+1)
    }

    const showComponentByTipoFormulario = (campo) =>{
        switch (campo.tipo_accion) {
          case 'campo-texto':
            if(!campo.valor) return false
            return <div className="text-primary">
              <Title level={5} className='mb-0 mt-0' style={{fontWeight:900}}>{campo.titulo}</Title>
              <Paragraph className='mb-0 mt-0'>{campo.valor ? campo.valor : 'Sin información'}</Paragraph>
            </div>
          case 'selector':
            if(!campo.valor) return false
            return <div className="text-primary">
              <Title level={5} className='mb-0 mt-0' style={{fontWeight:900}}>{campo.titulo}</Title>
              <Paragraph className='mb-0 mt-0'>{campo.valor ? campo.valor : 'Sin información'}</Paragraph>
            </div>
          case 'carga-imagenes':
            if(campo.valores.length < 1) return false
            return <div className="text-primary">
              <Title level={5} className='mb-0 mt-0' style={{fontWeight:900}}>{campo.titulo}</Title>
              
              {
                campo.valores.length > 0 ? campo.valores.map((foto,i) => {
                  return <div md={3} key={`foto-estado-${i}`} style={{ display: "inline-block" }}>
                    <Image src={foto.url} width={80} />
                    </div>
                }) : <Col md={12}><p className='text-danger' style={{fontWeight:700}}><i className="fa-solid fa-triangle-exclamation text-warning"></i> Sin información</p></Col>
              }
            </div>
          default:
            break;
        }
    }


    const mostrarMetaDatosSubEstado = (metadatos) => {
        if(!metadatos) return false
        if(Array.isArray(metadatos) !== true) return false
        if(metadatos.length < 1) return false
  
        return metadatos.map((meta,i) => {
            const contenido = showComponentByTipoFormulario(meta)
            if(!contenido) return false 
          return <div key={`meta-${i}`}>
            <Divider className="mt-0 mb-2" />
            {contenido}
          </div>
        })
    }

    const mostrarLineaTiempo = () => {
        if(!lineaTiempo) return false
        if(!lineaTiempo.hitos) return false
        if(!Array.isArray(lineaTiempo.hitos)) return false

        return <div style={{ marginBottom: 30 }}>
            <Steps
                size="small"
                // current={lineaTiempo.length > 0 ? (lineaTiempo.length-1) : 0 }
                items={lineaTiempo.hitos.map( (hito,i) => {
                    let icon            = <FaRegDotCircle />
                    let icon_success    = <FaCheckCircle color="green" />
                    let description     = ""

                    lineaTiempo.hitos.map((e,pos) => {
                        if(pos < i) return 
                        const emitidos = estados.filter(estado => estado.codigo_estado === e.codigo_estado).length
                        if(emitidos > 0) icon = icon_success
                    })

                    const emitidos = estados.filter(estado => estado.codigo_estado === hito.codigo_estado) 
                    if(emitidos.length > 0) {
                        icon = icon_success
                        description = fechaUTCATexto(emitidos[ emitidos.length - 1 ].createdAt)
                    }
                    return {
                        title: `${hito.titulo}`,
                        icon,
                        // description
                    }
                })}
            />
            {/* <Row gutter={15} >
                { lineaTiempo.hitos.map((hito,i) => {

                    let icon = <FaRegDotCircle />
                    let icon_success = <FaCheckCircle color="green" />

                    lineaTiempo.hitos.map((e,pos) => {
                        if(pos < i) return 
                        const emitidos = estados.filter(estado => estado.codigo_estado === e.codigo_estado).length
                        if(emitidos > 0) icon = icon_success
                    })

                    const emitidos = estados.filter(estado => estado.codigo_estado === hito.codigo_estado).length 
                    if(emitidos > 0) icon = icon_success

                    return <Col span="auto" key={`p-${i}`} style={{ textAlign: "center" }}>
                    <div style={{ height: 1, color: "black", width: "50%" }} />
                    {icon}
                    <Title level={4} style={{ fontSize: 15 }} className="mt-0 mb-0">{hito.titulo} {hito.codigo_estado}</Title>
                    </Col>
                }) }
            </Row> */}
        </div>
    }

    const mostrarEstados = () => {

        if(estados.length < 1) return <div>
            <img src={`${url_images}/illustrations/gps-navigator-with-pin-location.svg`} style={{ maxWidth: "50%" }} />
            <Title level={4} className="mt-0 mb-3">Eventos en la ruta</Title>
            <Paragraph>Los eventos en la ruta son actualizaciones del estado de las órdenes, estos forman parte del protocólo de entrega y nos brindan una trazabilidad completa sobre la carga</Paragraph>
        </div>

        const items = estados.map((estado,i) => {
                
            let lat = 0
            let lng = 0

            if(estado.location){
            if(Array.isArray(estado.location.coordinates) !== false){
                if(estado.location.coordinates.length > 1){
                lat = estado.location.coordinates[0]
                lng = estado.location.coordinates[1]
                }
            }
            }

            const label = <Title level={5} className="mb-0 mt-0" style={{ textTransform: "uppercase", fontWeight:900}}><b>{estado.estado}</b> <b>{estado.subestado}</b> <FaCircle color={estado.estado_color} /></Title>

            const contenido = <div size="small" key={`est-${i}`} className="p-0 mx-3 shadow-sm text-left">
                <div className="pl-2 pb-0">
                    <Tag className="mb-3">{fechaATextoExperimental(estado.createdAt)} · <a href={`https://google.com/maps?q=${lat},${lng}`} target="_blank"><i className="fas text-primary fa-map-marker-alt"></i></a></Tag>
                </div>
                <Divider className="mt-0 mb-2" />
                    <Paragraph className="mb-0"><b>Canal:</b> {canalEstadosString(estado.canal)}</Paragraph>
                    <Paragraph className="mb-2"><b>Por :</b> {estado.identificador_creador}</Paragraph>
                    {
                        mostrarMetaDatosSubEstado(estado.metadatos)
                    }
                    { (estados.length - 1) === i ? false : <Divider style={{ marginTop: 5, marginBottom: 5 }} /> }
            </div>

            return {
                title: label,
                description: contenido
            }
        })
        
        return <div className="mt-3" style={{ marginBottom: 20 }}>
            <Steps
            progressDot
            current={2}
            direction="vertical"
            items={items}
            />
        </div>
    }

    const analisisCumplimiento = () => {
        let status_transito = "Sin información"
        
        return <div>
            <Title level={4} className="mt-0 mb-0">Tiemp de entrega</Title>
            <Title level={5} className="mt-0 mb-2">{status_transito}</Title>   
            <Title level={4} className="mt-0 mb-0">Cumplimiento de la ruta</Title>
            <Title level={5} className="mt-0 mb-2">Km.</Title>   
        </div>
    }

    const obtenerValorEta = (key) => {
        let valor_exportar = 0
        if(configuracionTracking) if(configuracionTracking.detalles) if(typeof configuracionTracking.detalles === "object") if(configuracionTracking.detalles[key]) if(!isNaN( configuracionTracking.detalles[key]) ) valor_exportar = parseInt(configuracionTracking.detalles[key])
        return valor_exportar
    }

    const showTimeEspectation = (minutos_faltantes_llegada) => {
        const fecha_entrega = DateTime.fromISO(usuario.fecha_entrega_hasta)
        const faltante_esperado = DateTime.now().plus({ minutes: minutos_faltantes_llegada })
        
        const tiempo_diferencia = fecha_entrega.diff(faltante_esperado, ["minutes", "hours", "days"])?.values

        const {
            days,
            hours,
            minutes
        } = tiempo_diferencia

        const maximo_dias           = obtenerValorEta('flexibilidad_dias')
        const maximo_horas          = obtenerValorEta('flexibilidad_horas')
        const maximo_minutos        = obtenerValorEta('flexibilidad_minutos')

        console.log({ tiempo_diferencia: { days, hours, minutes }, flexibilidad: { maximo_dias, maximo_horas, maximo_minutos }, faltante_esperado: faltante_esperado.toISO(), fecha_entrega: usuario.fecha_entrega_hasta })
        
        if(parseInt(days) < maximo_dias || parseInt(hours) < maximo_horas || parseInt(minutes) < maximo_minutos ){

            let dias_retraso = days < 0 ? (days * -1) : 0
            let horas_retraso = hours < 0 ? (hours * -1) : 0
            let minutos_retraso = minutes < 0 ? (minutes * -1) : 0

            if(dias_retraso > 0)        dias_retraso        = Math.round( dias_retraso - maximo_dias )
            if(horas_retraso > 0)       horas_retraso       = Math.round( horas_retraso - maximo_horas )
            if(minutos_retraso > 0)     minutos_retraso     = Math.round( minutos_retraso - maximo_minutos )

            let tiempo_faltante = ''
            if(dias_retraso > 0) tiempo_faltante += `${dias_retraso} Días`
            if(horas_retraso > 0) tiempo_faltante += ` ${horas_retraso} Horas`
            if(minutos_retraso > 0) tiempo_faltante += ` ${minutos_retraso} Minutos`

            return <Tooltip title={tiempo_faltante}><Tag color="red">RETRASADO</Tag></Tooltip>
        }

        return <Tag color="green">A TIEMPO</Tag>
        
    }

    const isPointWithinRoute = (point, route, tolerance) => {
        // Convertir el punto y la ruta a features de Turf
        const pointFeature = turf.point(point);
        const lineFeature = turf.lineString(route);
        
        // Calcular la distancia del punto a la línea
        const distance = turf.pointToLineDistance(pointFeature, lineFeature, {units: 'kilometers'});
        
        // Verificar si la distancia está dentro de la tolerancia
        return distance
    }

    const showRouteEspectation = () => {
        if(!ubicacionConductor) return false
        if(!ubicacionConductor.location) return false
        if(!ubicacionConductor.location.coordinates) return false
        if(ubicacionConductor.location.coordinates.length < 2) return false
        if(!routesConductor) return false
        if(routesConductor.length < 1) return false
        const ruta_conductor = routesConductor[0]

        const punto = [ubicacionConductor.location.coordinates[0],ubicacionConductor.location.coordinates[1]]
        const route = polyline.decode(ruta_conductor.geometry)
        
        const en_ruta = isPointWithinRoute(punto, route)
        
        return en_ruta < 200 ? <Tag color="green">EN RUTA</Tag> : <Tag color="red">FUERA DE RUTA</Tag>
    }
    
    const mostrarInformacionTrafico = () => {
        let ruta_transito = null
        if(routesConductor.length > 0) ruta_transito = routesConductor[0]

        if(!ruta_transito) return false

        return <Card size="small" style={{ width: 300, background: "#262c30", position: "absolute", top: 174, left: 10, zIndex: 9 }}>
            <Paragraph className="mt-0 mb-0" style={{ color: "white" }}>Tiempo para la entrega</Paragraph>
            <Title level={4} className="mt-0 mb-2" style={{ color: estilo_moving_truck.colors.primary }}>{duracionTiempoString(ruta_transito.duration / 60)} {showTimeEspectation(ruta_transito.duration / 60)}</Title>   
            <Paragraph className="mt-0 mb-0" style={{ color: "white" }}>Distancia para la entrega </Paragraph>
            <Title level={4} className="mt-0 mb-2" style={{ color: estilo_moving_truck.colors.primary }}>{formatoMoneda(ruta_transito.distance / 1000)} Km. {showRouteEspectation()}</Title>  
            </Card>
    }
    
    const informacionConductor = () => {
        let fecha_ultima = usuario.conductor?.ubicacion?.updatedAt
        return <div>
            <Card size="small" style={{ background: estilo_moving_truck.colors.primary, color: "white" }} >
            <Title level={4} className="mt-0 mb-3" style={{ color: "white" }}><FaRegAddressCard color="white" /> Conductor designado</Title>
            <Row gutter={15}>
                <Col md={6}><Avatar shape="circle" style={{ color: '#f5222d' }} size={70} src={usuario.conductor?.imagen_perfil} icon={<UserOutlined />} /></Col>
                <Col md={18}>
                <Title level={5} className="mt-0 mb-2" style={{ color: "white" }}>{usuario.conductor?.nombres} {usuario.conductor?.apellido_p} {usuario.conductor?.validado === true ? <Tooltip title="Este es un conductor validado"><MdVerified color="white" /></Tooltip> : false}</Title>
                <Paragraph className="mb-0" style={{ color: "white" }}><b>DNI</b> {usuario.conductor?.rut ? usuario.conductor?.rut : "Sin información"}</Paragraph>  
                <Paragraph style={{ color: "white" }}><b>Última ubicación conocida</b> {fecha_ultima ? fechaATextoExperimental(fecha_ultima) : "No disponible"}</Paragraph>  
                </Col>
            </Row>
            
            </Card>
            {/* <Title level={4} className="mt-0 mb-0">Vehículo asignado</Title>
            <Title level={5} className="mt-0 mb-2">{usuario.vehiculo?.titulo}</Title>    */}
        </div>
    }
    
    const informacionVehiculo = () => {
        const img_style = { width: "100%" }
        return <div>
            <Card color="primary" size="small" headerBg={estilo_moving_truck.colors.primary} extraColor="white">
            <Title level={4} className="mt-0 mb-3"><LuTruck /> Vehículo designado</Title>
            <Row gutter={15}>
                <Col xs={8}>
                    { usuario.vehiculo?.tipo_img ? <img src={usuario.vehiculo?.tipo_img} style={img_style} /> : <img src={`${url_images}/camion.png`} style={img_style} /> }
                    </Col>
                <Col xs={16}>
                    {
                        usuario.vehiculo ? <div>
                            <Title level={5} className="mt-0 mb-2">{usuario.vehiculo?.titulo}</Title>
                            <Paragraph><b>PATENTE</b> {usuario.vehiculo?.patente ? usuario.vehiculo?.patente : "Sin información"}</Paragraph>  
                        </div> : <Title level={5} className="mt-0 mb-2">Sin información</Title>
                    }
                </Col>
            </Row>
            
            </Card>
        </div>
    }

    const verInformacionGeografica = (punto) => {
        if (!punto) return false
        if (!punto.geo_datos) return false
        if (typeof punto.geo_datos !== "object") return false
        return <div>
            <Divider className="mt-3 mb-2" />
            <Row>
                <Col md={24}><p className="mb-0"><i className="fa-solid fa-location-crosshairs"></i> {punto.geo_datos?.formatted_address}</p></Col>
                <Col md={8}><p className="mb-0">{punto.geo_datos?.pais}</p></Col>
                <Col md={8}><p className="mb-0"><b>{punto.geo_datos?.level1}</b></p></Col>
                <Col md={8}><p className="mb-0"><b>{punto.geo_datos?.level2}</b></p></Col>
            </Row>
            <Divider className="mt-3 mb-2" />
        </div>
    }

    const mostrarFotos = (iz, photos) => {
        if(!photos) return false
        if(Array.isArray(photos) !== true) return false
        if(photos.length < 1) return false
        return <div>
            {
                                                photos.map((foto,i) => {
                                                    const url = `data:image/jpeg;base64,${foto.src}`
                                                    return <Image key={`foto-${iz}-${i}`} src={url} width={100} />
                                                })
            }
        </div>
    }
    
    const mostrarResumenTracking = () => {

        const tracking_informe = <div>
            <img src={`${url_images}/illustrations/navigation-map.svg`} style={{ maxWidth: "50%" }} />
            <Title level={4} className="mt-0 mb-3">Tracking del vehículo</Title>
            <Paragraph>Aquí verás las paradas detectadas por nuestro sistema en la trazabilidad del vehículo</Paragraph>
        </div>

        if(!informeRastreo) return tracking_informe

        return <div>
            <Card size="small">
            <Row gutter={15}>
                <Col md={24}>
                    <Paragraph level={4} className="mt-0 mb-0">
                    <Tooltip title="Desde que el conductor notifica el inicio del tránsito"><b><FaFontAwesomeFlag /> Inicio del rastreo <IoHelpCircleSharp style={{ verticalAlign: "middle" }} /></b></Tooltip>
                    </Paragraph>
                    <Paragraph className="mb-0 mt-0">{fechaATextoExperimental(informeRastreo.inicio_rastreo)}</Paragraph>
                    <Divider className="mt-3 mb-2" />
                </Col>
                <Col md={24}>
                    <Paragraph level={4} className="mt-0 mb-0"><b><FaFlagCheckered /> </b></Paragraph>
                    <Tooltip title="Desde que el conductor notifica el inicio del tránsito"><b><FaFlagCheckered /> Inicio del rastreo <IoHelpCircleSharp style={{ verticalAlign: "middle" }} /></b></Tooltip>

                    <Paragraph className="mb-0 mt-0">{fechaATextoExperimental(informeRastreo.final_rastreo)}</Paragraph>
                    <Divider className="mt-3 mb-2" />
                </Col>
                <Col md={12}>
                    <Paragraph level={4} className="mt-0 mb-0"><b><FaRegClock /> Tiempo de conexión</b></Paragraph>
                    <Paragraph className="mb-0 mt-0">{formatoMoneda(Math.round(informeRastreo.minutos_conexion))} Min.</Paragraph>
                </Col>
                <Col md={12}>
                    <Paragraph level={4} className="mt-0 mb-0"><b><RiPinDistanceLine /> Distancia recorrida</b></Paragraph>
                    <Paragraph className="mb-0 mt-0">{formatoMoneda(informeRastreo.distancia_km_recorrida)} Km.</Paragraph>
                </Col>
            </Row>
            </Card>
        </div>
    }

    const mostrarParadas = () => {

        const tracking_informe = false

        if(!informeRastreo) return tracking_informe
        if(!informeRastreo.paradas) return tracking_informe
        if(!Array.isArray(informeRastreo.paradas)) return tracking_informe
        if(informeRastreo.paradas.length < 1) return tracking_informe

        const paradas = informeRastreo.paradas
        const items = paradas.map((marker, iz) => {

                let coordinates     = []
                let url_google      = ''
                let desde           = marker.coordenada_inicial?.date
                let hasta           = marker.coordenada_final?.date

                if(marker?.coordenada_inicial?.location?.coordinates){
                    coordinates = marker?.coordenada_inicial?.location?.coordinates
                }

                if(coordinates.length > 1) url_google = `https://www.google.com/maps?q=${coordinates[1]},${coordinates[0]}`

                const content = <div key={`parada-${iz}`} style={{ marginBottom: 20 }}>
                                        <p className="mb-0 mt-0" style={{ textTransform: "uppercase", fontSize: 13 }}><b>{fechaATextoSimpleExperimental(marker.coordenada_inicial?.date)}</b></p>
                                        <Row gutter={15}>
                                            <Col><Paragraph style={style_small} className='mb-0 mt-0'><FaRegClock /> {`Desde ${fechaUTCATiempo(desde)}`}</Paragraph></Col>
                                            <Col><Paragraph style={style_small} className='mb-0 mt-0'><FaRegClock /> {`Hasta ${fechaUTCATiempo(hasta)}`}</Paragraph></Col>
                                        </Row>
                                        {verInformacionGeografica(marker)}
                                        { url_google ? <a href={url_google} target='_blank' style={{ fontWeight: 700 }}><i className="fa-solid fa-map-location-dot"></i> VER EN GOOGLE <BsBoxArrowInUpRight /></a> : false }
                                        {mostrarFotos(iz, marker.photos)}
            </div>

            return {
                title: `Parada ${iz+1}`,
                description: content
            }
        })
        return <div>
            <Steps
            progressDot
            current={1}
            direction="vertical"
            items={items.map((pa,ip) => ({ ...pa, title: `Parada ${ip+1}` }))}
            />
        </div>
    }

    const onClick = (e) => {
        console.log('click ', e);
        // setCurrent(e.key);
      };
    
    const itemsMenu = [
        {
            key: "rastreo",
            label: <div><MdAltRoute color={estilo_moving_truck.colors.primary} /> RASTREO</div>
        },
        {
            key: "analisis",
            label: <div><GiSteeringWheel color={estilo_moving_truck.colors.primary} /> CONDUCCIÓN</div>
        },
        // {
        //     key: "trafico",
        //     label: <div><MdAltRoute color={estilo_moving_truck.colors.primary} /> TRÁFICO</div>
        // },
    ]

    const menuSeleccion = () => {
        return <div>
            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <Menu  onClick={onClick} mode="horizontal" items={itemsMenu} />
        </div>
    }

    const render = () => {

        if(loadingMaster) return <Layout style={{ textAlign: "center", padding: 20 }}>
            <Title level={3} className="mt-0 mb-3">Moving Truck</Title>
            <Spin size="large" />
        </Layout>

        if(!usuario) return <Layout style={{ textAlign: "center", padding: 20 }}>
            <Title level={3} className="mt-0 mb-3">No hay datos para mostrar</Title>
        </Layout>

        const component_notificaciones = <div>
            <Paragraph level={4} className="mt-0">Los siguientes son los datos de contactoa que serán notificados acerca del los eventos de tracking.</Paragraph>
                    <Row gutter={15}>
                        <Col md={12}>
                        <Title level={4} className="mt-0" ><GoDeviceMobile /> Teléfonos</Title>
                        {mostrarMovilesAdicionales()}
                        </Col>
                        <Col md={12}>
                        <Title level={4} className="mt-0" ><AiOutlineMail /> Emails</Title>
                        {mostrarEmailsAdicionales()}
                        </Col>
                    </Row>
        </div>


        const component_finalizar = <div>
            <Row gutter={15}>
                <Col md={12} xs={24}>
                            <Title level={4} className="mt-0"><MdDateRange /> ¿Cuándo se puede retirar?</Title>
                            <Paragraph className="mb-0 mt-0"><b>Desde</b> {fechaATextoSimple(usuario.fecha_retiro_desde)}</Paragraph>
                                <Paragraph className="mb-0 mt-0"><b>Hasta</b> {fechaATextoSimpleExperimental(usuario.fecha_retiro_hasta)}</Paragraph>
                </Col>
                <Col md={12} xs={24}>
                        <Title level={4} className="mt-0"><MdDateRange /> ¿Cuándo se debe entregar?</Title> 
                                <Paragraph className="mb-0 mt-0"><b>Desde</b> {fechaATextoSimple(usuario.fecha_entrega_desde)}</Paragraph>
                                <Paragraph className="mb-0 mt-0"><b>Hasta</b> {fechaATextoSimpleExperimental(usuario.fecha_entrega_hasta)}</Paragraph>
                </Col>
            </Row>

        </div>

        const steps = [
            {
              title: 'Detalles de la orden',
              children: <div>
                 <Title level={4} className="mt-0 mb-3"><MdOutlinePlace style={{ verticalAlign: "middle" }} /> Origen de carga</Title>
                <Row gutter={15}>
                    <Col md={16}>
                        <Title level={4} className="mt-0 mb-0">Dirección</Title>
                        <Paragraph className="mb-0">{usuario.origen?.description}</Paragraph>
                    </Col>
                    <Col md={8} xs={24}>
                    <Title level={4} className="mt-0 mb-0">Observaciones</Title>
                    <Paragraph className="mb-0">{usuario.origen?.observaciones ? usuario.origen?.observaciones : <TagSinDatos /> }</Paragraph>
                    </Col>
                </Row>
                <Divider className="mt-3 mb-3" />
                  {visualizarParadas()}
              </div>
            },
            {
              title: 'Información de la carga',
              children: visualizarProductos(),
            },
            // {
            //   title: 'Más info',
            //   children: <CamposPersonalizadosRecurso hideActions={true} saveOnBlur={false} viewType={"show"} id_target={usuario._id} titulo="Más info" tipo="viaje" />   ,
            // },
            // {
            //   title: 'Notificaciones',
            //   children: component_notificaciones,
            // },
            {
              title: 'Fechas',
              children: component_finalizar,
            },
          ];
    
        const itemSteps = steps.map((item) => ({ key: item.title, label: item.title, ...item }));

          const puntos_entrega = paradas.map(pa => {
            let coords = null
            if(pa.start_point) if(pa.start_point?.coordinates) if(pa.start_point.coordinates.length > 1){
                pa.coords = {
                    lat: pa.start_point.coordinates[1],
                    lng: pa.start_point.coordinates[0]
                }
                pa.lat = pa.start_point.coordinates[1]
                pa.lng = pa.start_point.coordinates[0]
            }
            return pa
          }).filter(e => e.coords)

          if(usuario.origen) if(usuario.origen.start_point) if(usuario.origen.start_point.coordinates) if(usuario.origen.start_point.coordinates.length > 1){
            puntos_entrega.push({
                ...usuario.origen,
                coords: {
                    lat: usuario.origen.start_point.coordinates[1],
                    lng: usuario.origen.start_point.coordinates[0]
                },
                lat: usuario.origen.start_point.coordinates[1],
                lng: usuario.origen.start_point.coordinates[0]
            })
        }


    const steps2 = [
        {
            key:"eventos",
            title: <Title level={4} className="mt-0 mb-0"><FaClockRotateLeft size={17} /> Eventos</Title>,
            children: <Layout style={{  }}>
                {mostrarEstados()}
                </Layout>,
        },
        {
            key:"tracking",
            title: <Title level={4} className="mt-0 mb-0"><LuTruck /> Tracking vehículo</Title>,
            children: <Layout style={{  }}>
                {mostrarResumenTracking()}
                {mostrarParadas()}
                </Layout>,
        },
      ];

      const itemSteps2 = steps2.map((item) => ({ key: item.title, label: item.title, ...item }));

      const panMiga = () => {
        if(session) if(session.data) return <div style={{ marginTop: 20 }}>
                <Breadcrumb
                items={[
                {
                    href: rutas.dashboard.slug,
                    title: <HomeOutlined />,
                },
                {
                    href: rutas.trips.slug,
                    title: <span>Viajes</span>,
                },
                {
                    // href: rutas.trips.slug,
                    title: <span>Detalles del viaje</span>,
                },
                ]}
            />
            </div>

        return false
        }

        if(!orden) return false

        return <div>
            <Layout style={{ padding: 0 }}>
            <Row >
                <Col md={12} style={{  }}>
                {/* <Layout style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 0, background: "transparent" }}>
                {panMiga()}
                <Tabs size="large" defaultActiveKey="eventos" items={itemSteps2} onChange={onChangeStep} />
                </Layout> */}
                <MapaViajeDetalle hideHeader={true} hideHeaderOptions={true} pinType="uber" poligonos={informeRastreo?.poligonos} height={400} markers={puntos_entrega} resize={resize} ubicacionConductor={ubicacionConductor} routesConductor={routesConductor} />
                <Layout style={{ padding: 12 }}>
                <Row gutter={15}>
                        <Col md={4}><Paragraph><FaCircle size={10} color="orange" /> Punto de carga</Paragraph></Col>
                        <Col md={4}><Paragraph><FaCircle size={10} color="blue" /> Punto de entrega</Paragraph></Col>
                        <Col md={4}><Paragraph><MdOutlineRoute size={16} style={{ verticalAlign: "middle" }} color="black" /> Ruta sugerida</Paragraph></Col>
                        <Col md={4}><Paragraph><MdOutlineRoute size={16} style={{ verticalAlign: "middle" }} color={estilo_moving_truck.colors.primary} /> Ruta transitada</Paragraph></Col>
                        <Col md={4}><Paragraph><CgBorderStyleDashed size={20} style={{ verticalAlign: "middle" }} color="blue" /> Ruta a transitar</Paragraph></Col>
                </Row>
                </Layout>
                </Col>
                <Col md={12} style={{ backgroundColor: "white" }}>
                    {/* <Layout style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, background: "transparent" }}>
                    <Row gutter={10} align="top">
                        <Col md={18}>
                            
                            <Title level={4} className="mb-3 mt-0">{loadingMaster ? <Spin /> : false } Detalles del viaje {usuario.internal_id}</Title>
                            {mostrarLineaTiempo()}
                        </Col>
                    </Row>
                    </Layout> */}
                    {/* {mostrarInformacionTrafico()} */}
                    <Layout style={{ paddingRight: 20, paddingLeft: 20, paddingBottom: 100, background: "transparent" }}>
                    
                        {/* <Row gutter={15}>
                            <Col md={12}>{informacionConductor()}</Col>
                            <Col md={12}>{informacionVehiculo()}</Col>
                        </Row> */}
                    <Tabs size="large" current={current} items={itemSteps} onChange={onChangeStep} />
                    </Layout>
                </Col>
            </Row>
            

            </Layout>
            {contextHolder}
            {contextHolderPush}
        </div>
    }
    // if(session) if(session.data) return <Structure component={render()} />
    return render()
}

export default VisualizarViaje