import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Button, Card, Col, Form, Pagination, Row, Select, Table, Tooltip, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { fechaATextoExperimental, fechaATextoSimple, fechaUTCATexto } from "../../lib/helpers/helpers"
import { IoMdAlert } from 'react-icons/io'
import { obtenerIdentificadorConductor } from "../../lib/helpers/conductores"
import { CiMobile3 } from "react-icons/ci";
import { MdOpenInNew } from "react-icons/md"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import { formatoMoneda } from "../../lib/helpers/main"
import SelectorFechas from "../../subComponents/general/selector_fechas"
import ModalSeleccionVehiculo from "../Vehiculos/modal_seleccion"
import ModalSeleccionVehiculos from "../Conductores/modal_seleccion"
import FiltroTarget from "../../subComponents/general/filtro_target"
import { tituloPorTipoNotificacion } from "../../lib/helpers/alertas"
import SelectorGlobal from "../../subComponents/general/selector"
import { getLabelForVehicle } from "../../lib/helpers/selectores"
import SelectorTipoAlerta from "../GeoAlertas/selector_tipo_alerta"

const ListadoNotificaciones = (props)=> {
    const {
        condicion_default,
        marcadores
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [loadingConductores, setLoadingConductores] = useState(true)
    const [messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ key_input_filter, setKeyInputFilter ] = useState(0)
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const trash = props.trash ? props.trash : false
    const tipo = props.tipo ? props.tipo : "link"
    const titulo = props.title ? props.title : 'registros'
    const opciones_filtro = [
        { label: 'Conductores', value: 'conductor' },
        { label: 'Vehículos', value: 'vehiculo' },
        // { label: 'Equipos', value: 'equipo'}
    ]

    const marcarNotificaciones = async (ids)=>{
        if(ids.length < 1) return false
        return fetch(`${urlapi}/notificaciones/marcar`,{
            method:'POST',
            body: JSON.stringify({
                ids
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => res)
        .catch(error => error)
    }

    const obtenerConductores = async (page, query)=>{
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        setLoadingConductores(true)
        return fetch(`${urlapi}/notificaciones/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                const ids = res.datos.map(not => not._id).filter(n => n)
                marcarNotificaciones(ids)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        obtenerConductores(pagina, condicion_busqueda)
    }, [ condicion_busqueda ])

    const handleChangePagina = (e) => {
        setPagina(e)
        setLoadingConductores(true)
        obtenerConductores(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination current={pagina} total={(cantidad*10)} showSizeChanger={false} onChange={handleChangePagina} />
        </div>
    }

    const agregarFiltro = (key, value) => {

        if(!value){
            delete filtroBusqueda[key]
        } else if(Array.isArray(value) !== false){
            if(value.length < 1){
                delete filtroBusqueda[key]
            } else {
                filtroBusqueda[key] = { $in: value.map(v => v._id) }
            }
        } else {
            filtroBusqueda[key] = value
        }
        setFiltroBusqueda(filtroBusqueda)
      }

      const obtenerCondicionDeFiltros = () => {
        let cond = {}
        Object.keys(filtroBusqueda).map(llave => {
            if(filtroBusqueda[llave]['$in']){
                if(Array.isArray(filtroBusqueda[llave]['$in']) !== false){
                    if(filtroBusqueda[llave]['$in'].length > 0) cond[llave] = filtroBusqueda[llave]
                }
            } else if(Array.isArray(filtroBusqueda[llave]) !== false){
                    if(filtroBusqueda[llave].length > 0){
                        cond[llave] = { $in: filtroBusqueda[llave] }
                    }
            } else {
                cond[llave] = filtroBusqueda[llave]
            }
            
        })
        return cond
      }
      const refrescarBusqueda = () => {
        setPagina(1)
        let cond = obtenerCondicionDeFiltros()

        return obtenerConductores(1, {...cond, ...condicion_busqueda})
    }

    const mostrarTablaDatos = () => {
        if(loadingConductores) return <CargandoTabla />

        return <div>
            <Table dataSource={conductores} pagination={false} scroll={{ x:"50vw" }} >
                <Column title="Conductor" render={(data) => {
                    let valor           = ''
                    let info_extra      = ''
                    let link            = ''
                    if(data.conductor) if(typeof data.conductor === 'object'){
                        valor = obtenerIdentificadorConductor(data.conductor)
                        info_extra = data.conductor.phone
                        link = `${rutas.drivers.slug}/${data.conductor._id}`
                    }
                    if(data.tipo_target === 'vehiculo'){
                        if(data.vehiculo) if(typeof data.vehiculo === 'object') link = `${rutas.vehicles.slug}/${data.vehiculo._id}`
                    } else if(data.tipo_target === 'equipo'){
                        if(data.equipo) if(typeof data.equipo === 'object') link = `${rutas.equipment.slug}/${data.equipo._id}`
                    } else if(data.tipo_target === 'conductor'){
                        if(data.conductor) if(typeof data.conductor === 'object') link = `${rutas.drivers.slug}/${data.conductor._id}`
                    }
                    return <div>
                        {valor} <Link to={link} target="_blank"><MdOpenInNew /></Link>
                        {info_extra ? <div ><p className="m-0" style={{ fontWeight: "bold" }}>{info_extra}</p></div> : false }
                        
                    </div>
                }} />
                <Column title="Vehículo" render={(data) => {
                    return <div> { data.vehiculo?._id ? <Link to={`${rutas.vehicles.slug}/${data.vehiculo?._id}`} target="_blank"><MdOpenInNew /></Link> : false } {data.vehiculo?.titulo}</div>
                }} />
                <Column title="Teléfono" render={(data) => {
                    return <div>{data.conductor?.phone}</div>
                }} />
                <Column title="Tipo de evento" render={(data) => {
                    return <div style={{ textTransform: "uppercase" }}>{tituloPorTipoNotificacion(data.tipo)}</div>
                }} />
                <Column title="Mensaje" render={(data) => {
                    return <div>{data.checked !== true ? <Tooltip title="Eres el primero en ver esta notificación"><IoMdAlert style={{ fontSize: 20 }} /></Tooltip> : false} {data.mensaje}</div>
                }} />
                <Column title="Fecha de creación" render={(data) => {
                    return <div>{fechaATextoExperimental(data.createdAt)}</div>
                }} />
            </Table>
        </div>
    }

    const actualizarBusqueda = (condicion) => {
        setCondicionBusqueda(prev => {
            let actual = {...prev, ...condicion}
            if(!condicion.createdAt) delete actual.createdAt
            const cond = obtenerCondicionDeFiltros()
            obtenerConductores(1, {...cond, ...actual})
            return {...{}, ...actual}
        
        })
    }

    const mostrarRegistros = () => {

        return <div>

            <Paragraph level={3} className="m-0">TOTAL</Paragraph>
            <Title className="mt-0 mb-3">{formatoMoneda(total)}</Title>

            <SelectorFechas onChange={(data) => actualizarBusqueda(data)} />
            <Row gutter={15}>
                <Col md={12}>
                    <SelectorGlobal key={key_input_filter} fields_search={[ "nombres","apellido_m","apellido_p" ]} titulo="Conductor" condicion={{ }} isMulti={true} module="conductores" key_label="nombres" key_value="_id" onChange={(data) => agregarFiltro('id_target', data)} />
                </Col>
                <Col md={12}>
                    <SelectorGlobal key={key_input_filter} titulo="Vehículo" labelFunction={getLabelForVehicle} condicion={{ }} isMulti={true} module="vehiculos" key_label="patente" key_value="_id" onChange={(data) => agregarFiltro('id_vehicle', data)} />
                </Col>
                
            </Row>
            {/* <FiltroTarget onChange={(data) => agregarFiltro("id_target", data?._id ) } /> */}
            {/* <div className="mb-3"><ModalSeleccionVehiculo label="FILTRAR POR VEHÍCULO" onRemove={() => agregarFiltro('id_target', null)} onSelect={(data) => agregarFiltro('id_target', data._id )} /></div>
            <div className="mb-3"><ModalSeleccionVehiculos label="FILTRAR POR CONDUCTOR" onRemove={() => agregarFiltro('id_target', null)} onSelect={(data) => agregarFiltro('id_target', data._id )} /></div> */}

                <Row gutter={16}>
                <Col md={16}>
                    <SelectorTipoAlerta onChange={(val) => agregarFiltro('tipo',val)} />
                </Col>
                    {/* <Col md={12}>
                        <Form.Item>
                            <Select placeholder="Filtrar por tipo de evento" mode="multiple" options={opciones_filtro} onChange={(e) => agregarFiltro('tipo_target', e)} />
                        </Form.Item>
        </Col> */}
                    <Col span="auto">
                        <Button loading={loadingConductores} onClick={() => refrescarBusqueda()} type="primary" className="mb-3" >BUSCAR</Button>
                    </Col>
                </Row>
            {paginacion(data.pagina, total)}
            {mostrarTablaDatos()}
        </div>
    }

    return <div>
        {contextHolder}
        {mostrarRegistros()}
    </div>
}
export default ListadoNotificaciones