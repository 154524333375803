import { useEffect, useRef, useState } from 'react'
// import { Row, Col, Tab, Tabs, Spinner, Card, Button, ProgressBar } from 'react-bootstrap'
import data, { url_images } from '../../lib/backend/data'
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { Button, Card, Col, Divider, Input, Progress, Row, Skeleton, Spin, Tabs, Tag, message } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { AiFillFile } from 'react-icons/ai';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';

const MediosBox = (props) => {
    const [ loadingMedios, setLoadingMedios] = useState(true)
    const [ loadingMediosProyecto, setLoadingMediosProyecto ] = useState(true)
    const [ cargandoMas, setCargandoMas ] = useState(false)
    const [ medios, setMedios ] = useState([])
    const [ mediosProyecto, setMediosProyecto ] = useState([])
    const [ totalMedios, setTotalMedios ] = useState(0)
    const [ medioSeleccionado, setMedioSeleccionado ] = useState(false)
    const [ uploading, setUploading ] = useState(false)
    const [ uploadPorcentaje, setUploadPorcentaje ] = useState(0)
    const [ downloading, setDownloading ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ asignandoMedio, setAsignandoMedio ] = useState(false)
    const [ palabraBusqueda, setPalabraBusqueda ] = useState('')
    const [ condicionBusqueda, setCondicionBusqueda ] = useState({})
    const [ loadingAcciones, setLoadingAcciones ] = useState(false)
    const [ tab_active, setActiveKey ] = useState(1)
    const [messageApi, contextHolder] = message.useMessage();
    const token = session.tokenSession
    const dragerRef = useRef(null)

    const id_destino = props.id_destino

    const boxUploadingSpinner = () => {
        if(uploading !== true) return false
        if(!uploadPorcentaje) return false
        return <Progress size="small" type="circle" percent={uploadPorcentaje} />
    }

    const handleChange = (e) => {
        const { value } = e.target
        return setPalabraBusqueda(value)
    }

    const buscarArchivo = async () => {
        if(palabraBusqueda) if(palabraBusqueda.length < 4) return messageApi.error("Introduce al menos 4 carácteres")
        const cond = palabraBusqueda ? { $text: { $search: palabraBusqueda } } : {}
        setCondicionBusqueda(cond)
        setCargandoMas(true)
        return fetch(`${data.urlapi}/medios/list`,{
            method:'POST',
            body: JSON.stringify({ condicion: cond, skip: 0 }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                messageApi.error('Sin datos')
                return false
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return false
            }
            if(Array.isArray(res.datos) !== false){
                if(res.datos.length > 0){
                    console.log(res.datos)
                    setMedios(res.datos)
                    setTotalMedios(res.total)
                    return setCargandoMas(false)
                } else {
                    messageApi.warn("No se encontraron resultados")
                    return setCargandoMas(false)
                }
            } else {
                return setCargandoMas(false)
            }
        })
        .catch(error => {
            messageApi.error("Error al registrar el medio, intente nuevamente")
            return setCargandoMas(false)
        })
    }

  const handleSubmit = async (files) => {
    const f = files[0];
    const name = `${Date.now()}-${f.file.name}`
    const original_name = f.file.name
    const type = f.file.type
    const size = f.file.size
    const medio = {
        nombre: original_name,
        type,
        size,
        nombre_server: name,
    }
    const url_firmada = await obtenerFirmaUrl(name, type)
    if(!url_firmada) return false
    setUploading(true)
    try {
        const result = await axios.put(url_firmada, f["file"], {
            headers: {
              'Content-Type': f["file"].type
            },
            onUploadProgress: (e) => {
                const { loaded, total } = e
                let porcentaje = Math.floor(  (loaded*100) / total )
                setUploadPorcentaje(porcentaje)
            }
            })
            const nuevo_medio = await registrar_medio(medio)
            console.log({ result, nuevo_medio })
            return setUploading(false)
    } catch (error) {
        setUploading(false)
        console.log(error.message)
        messageApi.error("No se pudo cargar este archivo")
        }
    }
  
    const handleSubmitV2 = async (file) => {
    const name = `${Date.now()}-${file.name}`
    const original_name = file.name
    const type = file.type
    const size = file.size
    const medio = {
        nombre: original_name,
        type,
        size,
        nombre_server: name,
    }
    const url_firmada = await obtenerFirmaUrl(name, type)
    if(!url_firmada) return false
    setUploading(true)
    try {
        const result = await axios.put(url_firmada, file, {
            headers: {
              'Content-Type': file.type
            },
            onUploadProgress: (e) => {
                const { loaded, total } = e
                let porcentaje = Math.floor(  (loaded*100) / total )
                setUploadPorcentaje(porcentaje)
            }
            })
            const nuevo_medio = await registrar_medio(medio)
            return setUploading(false)
    } catch (error) {
        setUploading(false)
        console.log(error.message)
        // messageApi.error("No se pudo cargar este archivo")
        }
    }

    useEffect(() => {
        obtenerBibliotecaMedios(false)
        obtenerMediosProyecto(id_destino,'medio')
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const registrar_medio = async (objeto)=>{
        return fetch(`${data.urlapi}/medios/registrar-medio`,{
            method:'POST',
            body: JSON.stringify(objeto),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return false
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return false
            } else if(res._id){
                messageApi.success("Cargado exitosamente")
                medios.unshift(res)
                setActiveKey(2)
                if(id_destino) asignarHito(res._id)
                return setMedios(medios)
            }
            return false
        })
        .catch(error => {
            console.log(error)
            messageApi.error("Error al registrar el medio, intente nuevamente")
            return false
        })
    }
    const obtenerFirmaUrl = async (name,type)=>{
        return fetch(`${data.urlapi}/medios/presigne-url`,{
            method:'POST',
            body: JSON.stringify({
                name, type
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return false
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return false
            } else if(res.url){
                return res.url
            }
            return false
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return false
        })
    }

    const obtenerMediosProyecto = async (id, tipo)=>{
        if(!id) return setLoadingMediosProyecto(false)
        setLoadingMediosProyecto(true)
        return fetch(`${data.urlapi}/medios/relaciones?id=${id}&tipo=${tipo}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingMediosProyecto(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingMediosProyecto(false)
            }
            if(Array.isArray(res) !== false) setMediosProyecto(res)
            return setLoadingMediosProyecto(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMediosProyecto(false)
        })
    }

    const eliminarRecurso = async (id)=> {
        setLoadingAcciones(true)
        return fetch(`${data.urlapi}/medios?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Eliminado exitosamente")
                const i = medios.findIndex(e => e._id === id)
                if(i > -1){
                    medios.splice(i,1)
                    setMedios([...[], ...medios])
                    setMedioSeleccionado(false)
                }
            }
            return setLoadingAcciones(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingAcciones(false)
        })
    }

    const obtenerArchivo = async (id)=> {
        setDownloading(true)
        return fetch(`${data.urlapi}/medios/presigne-url?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setDownloading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setDownloading(false)
            } else if(res.url){
                setDownloading(false)
                return window.open(res.url, '_blank').focus();
            }
            return false
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setDownloading(false)
        })
    }
    const obtenerBibliotecaMedios = async (cargarmas)=> {
        if(cargarmas===true){
            setCargandoMas(true)
        } else {
            setLoadingMedios(true)
        }
        return fetch(`${data.urlapi}/medios/list`,{
            method:'POST',
            body: JSON.stringify({ condicion: condicionBusqueda, skip: medios.length }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingMedios(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingMedios(false)
            }
            if(cargarmas===true){
                if(Array.isArray(res.datos) !== false){
                    const final_array = medios.concat(res.datos)
                    setTotalMedios(res.total)
                    setMedios(final_array)
                    return setCargandoMas(false)
                } else {
                    return setLoadingMedios(false)
                }
            } else {
                setMedios(res.datos)
                setTotalMedios(res.total)
                return setLoadingMedios(false)
            }
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMedios(false)
        })
    }

    const seleccionarMedio = (id) => {
        return setMedioSeleccionado(id)
    }

    const cargandoScreen = () => {
        return <Row>
                <Skeleton active />
            </Row>
    }

    const mostrarMedios = () => {
        if(loadingMedios === true) return cargandoScreen()
        if(medios.length < 1) return <div className='p-5'>
        <Row className="justify-content-md-center">
            <Col md={224} className="text-center">
                <img src={`${url_images}/animations/Search Not Found.gif`} style={{ height: 120}} />
            <Title level={4} className='mt-0 mb-0'>No hay archivos</Title>
            <p>Aún no has cargado ningún archivo a la biblioteca de medios</p>
            </Col>
        </Row>
        </div>
        return <div>
            <h4>Se muestran {medios.length} de {totalMedios} Registros</h4>
            <Row gutter={15}>
                <Col md={9}><Input className='css-dev-only-do-not-override-1e88bjs mb-3' placeholder='Buscar por nombre de archivo' onChange={handleChange} /></Col>
                <Col md={3}><Button size="sm" onClick={()=>buscarArchivo()}>BUSCAR</Button> </Col>
            </Row>
            <Row gutter={15}>
            {
                medios.map(medio => {
                    return <Col md={6} key={medio._id} className="mb-3">
                        <Card className="hover" style={{ border: medioSeleccionado === medio._id ? '1px solid green' : false  }} onClick={()=>seleccionarMedio(medio._id)}>
                        {/** <Card.Img variant="top" src={`/${medio.url}`} /> */}
                        <div className='p-3'>
                            <AiFillFile size={40} />
                            <div />
                            <Tag>{medio.type}</Tag>
                            <p className="mb-0" style={{ fontSize: 13 }}>{medio.nombre.substring(0,20)}...</p>
                        </div>
                        </Card>
                    </Col>
                })
            }
            </Row>
            {
                cargandoMas === true ? <Spin animation="border" /> : <div>
                    {
                        totalMedios > medios.length ? <Button variant='outline-primary' size="sm" onClick={()=>obtenerBibliotecaMedios(true)}>CARGAR MÁS</Button> : false
                    }
                </div>
            }
        </div>
    }
    const mostrarMediosProyecto = () => {
        if(loadingMediosProyecto === true) return cargandoScreen()
        if(mediosProyecto.length < 1) return <div className='p-5'>
        <Row className="justify-content-md-center">
            <Col md={24} className="text-center">
            <img src={`${url_images}/animations/Search Not Found.gif`} style={{ height: 120}} />
            <Title level={4} className='mt-0 mb-0'>No hay archivos</Title>
            <p>Aún no has cargado ningún archivo a este recurso, agrega tu archivo a la biblioteca de medios y acontinuación, asígnalo a este recurso</p>
            </Col>
        </Row>
        </div>
        return <div>
        <h4>{mediosProyecto.length} Registros</h4>
        <Row>
        {
            mediosProyecto.map(medio => {
                return <Col md={6} key={medio._id}>
                    <Card className="hover" style={{ border: medioSeleccionado === medio._id ? '1px solid green' : false  }} onClick={()=>seleccionarMedio(medio._id)}>
                    <div className='p-3'>
                        <AiFillFile size={40} />
                        <div />
                        <Tag>{medio.type}</Tag>
                        <p className="mb-0" style={{ fontSize: 13 }}>{medio.nombre.substring(0,20)}...</p>
                    </div>
                    </Card>
                </Col>
            })
        }
        </Row>
    </div>
    }
    
    const mostrarMedioSeleccionado = (tipo) => {
        if(!medioSeleccionado) return false
        const i = medios.findIndex(me => me._id === medioSeleccionado)
        const i_asignado = mediosProyecto.findIndex(me => me._id === medioSeleccionado)
        if(i < 0) return false
        const medio = medios[i]
        return <div>
            <h5>Detalles del adjunto</h5>
            <Card className='p-3'>
                <Paragraph className='mb-0'>{medio.nombre}</Paragraph>
                <Tag className='mb-0'>{medio.type}</Tag>
                <Paragraph className='mb-0'>{medio.date_string}, <b>{(medio.size/1000000).toFixed(2)}MB</b></Paragraph>
                {
                    tipo === 'medios_asignados' ? <div>
                    <Row gutter={15}>
                        <Col md={12} className="mb-1">
                            { asignandoMedio === true ? <Spin animation="border" /> : <Button size="sm" variant="outline-danger" onClick={()=>eliminarRelacion(mediosProyecto[i_asignado].idrelacion)}>DESASIGNAR</Button>}
                        </Col>
                        <Col md={12} className="mb-1">
                            { downloading ? <Spin animation="border" /> : <Button size="sm" variant="success" onClick={()=>obtenerArchivo(medioSeleccionado)} >DESCARGAR</Button> }
                        </Col>
                    </Row>
                    </div> : <div>
                    <Button variant="link" size="sm" className='p-0 text-danger text-left' onClick={() => eliminarRecurso(medioSeleccionado)}>ELIMINAR</Button>
                    <Row gutter={15}>
                        <Col md={12} className="mb-1">
                            { asignandoMedio === true ? <Spin animation="border" /> : <Button size="sm" onClick={()=>asignarHito(medioSeleccionado)}>ASIGNAR</Button>}
                        </Col>
                        <Col md={12} className="mb-1">
                            { downloading ? <Spin animation="border" /> : <Button size="sm" variant="success" onClick={()=>obtenerArchivo(medioSeleccionado)} >DESCARGAR</Button> }
                        </Col>
                    </Row>
                    </div>
                }
                
            </Card>
        </div>
    }

    const eliminarRelacion = async (id) => {
        setAsignandoMedio(true)
        return fetch(`${data.urlapi}/medios/relaciones?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setAsignandoMedio(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setAsignandoMedio(false)
            } else if(res._id){
                messageApi.success("Este item fue removido exitosamente")
                const i = mediosProyecto.findIndex(m => m.idrelacion)
                if(i > -1){
                    mediosProyecto.splice(i,1)
                    setMediosProyecto(mediosProyecto)
                }
                setMedioSeleccionado(false)
                return setAsignandoMedio(false)
            }
            return true
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setAsignandoMedio(false)
        })
    }

    const asignarHito = async(id) => {
        setAsignandoMedio(true)
        const payload = {
            id_destino,
            id_medio: id
        }
        return fetch(`${data.urlapi}/medios/relaciones`,{
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Authorization': `Bearer: ${token}`,
                'Content-Type':'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res) messageApi.error('No se pudo cargar tu archivo')
            if(res.errorMessage) messageApi.error(res.errorMessage)
            if(res._id){
                mediosProyecto.unshift(res)
                setMediosProyecto(mediosProyecto)
                messageApi.success("Asignado exitosamente")
            }
            setMedioSeleccionado(false)
            return setAsignandoMedio(false)
        })
        .catch(error => {
            console.log(error)
            setAsignandoMedio(false)
            return messageApi.error('No se pudo subir la imagen')
        })
    }
    

    const props_file_upload = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        onChange(e) {
            if (e.file.status !== 'uploading') {
                const archivo = e.file.originFileObj
                handleSubmitV2(archivo)
            }
        }
      };

    const component_subida = <div className=''>
    <Dragger ref={dragerRef} disabled={uploading} {...props_file_upload}>
                    {/* <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Has click o arrastra el archivo a esta sección</p> */}
                    {/* <p className="ant-upload-hint">Tamaño máximo de archivo: 3GB</p> */}
                    <Button loading={uploading} >TOCA PARA SUBIR ARCHIVO</Button>
                    {boxUploadingSpinner()}
        </Dragger>

    </div>

    const component_biblioteca = <Row gutter={15}>
    <Col md={18}>
    {mostrarMedios()}
    </Col>
    <Col md={6}>
    {mostrarMedioSeleccionado('bibliteca_medios')}
    </Col>
    </Row>


    const component_relaciones = <Row gutter={15}>
    <Col md={18}>
    {mostrarMediosProyecto()}
    </Col>
    <Col md={6}>
    {mostrarMedioSeleccionado('medios_asignados')}
    </Col>
    </Row>

    const items = [
        // {
        //     label:"Subir archivos",
        //     children: 
        // },
        {
            label:"Biblioteca de medios",
            children: component_biblioteca
        },
        {
            label:"Archivos relacionados",
            children: component_relaciones
        },
    ].map((e,i) => ({...e, key:(i+1) }))

    const handleSelectTab = (e) => {
        setActiveKey(e)
    }
    return <div>
        <div className='p-2'>
            {component_subida}
            <Tabs defaultActiveKey={1} activeKey={tab_active} items={items} className="mb-3" onChange={handleSelectTab} />
        </div>
        {contextHolder}
        </div>
}

export default MediosBox