import { Avatar, Breadcrumb, Button, Card, Col, Divider, Form, Input, List, Popconfirm, Row, Select, Skeleton, Switch, Tabs, TimePicker, message } from "antd";
import Structure from "../Structure";
import React, { useEffect, useState } from "react";
import { siEsRequeridoEnEsquema, validateEmail } from "../../lib/helpers/helpers";
import { AntDesignOutlined, DeleteOutlined, HomeOutlined } from "@ant-design/icons";
import { rutas } from "../../lib/routes/routes";
import Title from "antd/es/typography/Title";
import { urlapi } from "../../lib/backend/data";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { Link, useParams } from "react-router-dom";
import MapaDibujoPoligono from "../RadioOperacion/Geogercas/creador/MapaDibujoPoligono";
import ListadoAlertas from "../GeoAlertas/listado";
import MapaZonasDetalle from "../Mapas/zonas/mapa_zonas_creadas";
import { parsearGeocercaMapa } from "../../lib/helpers/maps/geocercas";
import ModalSeleccionVehiculos from "../Conductores/modal_seleccion";
import { BsBoxArrowInUpRight } from "react-icons/bs";
import Paragraph from "antd/es/typography/Paragraph";
import ModalSeleccionTiposVehiculos from "../../subComponents/vehiculos/tipos/modal_seleccion_tipo";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { AiFillMinusCircle, AiOutlineMail } from "react-icons/ai";
import { codigos_area } from "../../lib/internacional";
import { GoDeviceMobile } from "react-icons/go";
import { GrAdd } from "react-icons/gr";
import { tipo_zonas } from "./data";
import ModalSeleccionVehiculo from "../Vehiculos/modal_seleccion";

dayjs.extend(customParseFormat)

  const DetallesZona = () => {
    const [ geocerca, setGeocerca ] = useState({})
    const [ geocercasParaActualizar, setGeoCercasParaActualizar ] = useState([])
    const [ geojson, setGeoJson] = useState(false)
    const [ horario, setHorario] = useState(false)
    const [ newGeojson, setNewGeoJson] = useState(false)
    const [ loadingMaster, setLoadingMaster] = useState(true)
    const [ loading, setLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const [ geometrias, setGeometrias ] = useState([])
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [ resize, setResize ] = useState(0)
    const dispatch = useDispatch()
    const { id } = useParams()

    const requeridos = [
      { value: "titulo", label: "Título" },
      { value: "tipo", label: "Tipo de geocerca" },
    ]

    const handleChange = (e) => {
      const { name, value } = e.target
      geocerca[name] = value
      return setGeocerca({...{}, ...geocerca})
    }

    const handleChangeHorario = (e) => {
      if(!e){
        geocerca.horario = {}
        return setGeocerca({...{}, ...geocerca})
      }
      
      const desde = {
        hora: e[0]['$H'],
        minuto: e[0]['$m']
      }

      const hasta = {
        hora: e[1]['$H'],
        minuto: e[1]['$m']
      }

      geocerca.horario = {
        desde,
        hasta
      }
      return setGeocerca({...{}, ...geocerca})
    }

    const handleChangeSelect = (e,name) => {
      geocerca[name] = e
      return setGeocerca({...{}, ...geocerca})
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const obtenerDatos = async () => {
        if(!id) return setLoadingMaster(false)
        const url = `${urlapi}/geodata/zonas/empresa?id=${id}`
        return fetch(url, {
          method: "GET",
          headers: {
              'Content-type': "application/json",
              'Authorization': `Bearer: ${session.tokenSession}`,
              'country': pais,
              'lang': idioma
          }
      })
      .then(res => {
          if(res.status === 401) return dispatch(cerrarSesion())
          return res.json()
      })
      .then(async res => {
          if(!res){
              messageApi.error("Sin datos obtenidos")
          } else if(res.errorMessage){
              messageApi.error(res.errorMessage)
          } else if(res._id){
            setGeocerca(res)
            if(res.geocercas){
                setGeoJson({
                    type: "FeatureCollection",
                    features: res.geocercas.map(geo => ({
                        type: "Feature",
                        geometry: {
                            type: geo.region.type,
                            coordinates: geo.region.coordinates
                        }
                    }))
                })
            }
            if(res.rutas) if(Array.isArray(res.rutas) !== false) setGeometrias(res.rutas)
          }
          return setLoadingMaster(false)
      })
      .catch(error => {
          messageApi.error("No se pudo efectuar la operación")
          return setLoadingMaster(false)
      })

    }

    const guardarCambios = async (nuevas_geo_forzar) => {
      let faltantes = []
      requeridos.map(campo => {
          if(!geocerca[campo.value]) faltantes.push(campo.label)
          return true
      })
      if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  

      const instancia = JSON.parse( JSON.stringify( geocerca ))
      instancia.geocercas = newGeojson ? newGeojson.features : []
      instancia.geocercas_actualizar = geocercasParaActualizar.filter(ge => ge.titulo)

      if(nuevas_geo_forzar) if(Array.isArray(nuevas_geo_forzar)) if(nuevas_geo_forzar.length > 0) instancia.geocercas = nuevas_geo_forzar

      setLoading(true)
      const url = `${urlapi}/geodata/zonas/empresa`
      return fetch(url, {
          method: "PUT",
          body: JSON.stringify(instancia),
          headers: {
              'Content-type': "application/json",
              'Authorization': `Bearer: ${session.tokenSession}`,
              'country': pais,
              'lang': idioma
          }
      })
      .then(res => {
          if(res.status === 401) return dispatch(cerrarSesion())
          return res.json()
      })
      .then(async res => {
          if(!res){
              messageApi.error("Sin datos obtenidos")
          } else if(res.errorMessage){
              messageApi.error(res.errorMessage)
          } else if(res._id){
              messageApi.success("Actualizado exitosamente")
              setTimeout(() => {
                  return window.location.reload()
              }, 1000);
          }
          return setLoading(false)
      })
      .catch(error => {
          messageApi.error("No se pudo efectuar la operación")
          return setLoading(false)
      })

    }

    const agregarTipoVehiculos = (registros) => {
      if(!geocerca.tipos_vehiculo) geocerca.tipos_vehiculo = []

      for( const driver of registros ){
        const i = geocerca.tipos_vehiculo.findIndex(c => c._id === driver._id)
        if(i > -1) continue
        geocerca.tipos_vehiculo.unshift(driver)
      }
      return setGeocerca({...{}, ...geocerca })
    }

    const agregarConductor = (driver) => {
      if(!geocerca.conductores) geocerca.conductores = []

      if(Array.isArray(driver) !== false){

        for( const conductor_elegido of driver ){
          const i = geocerca.conductores.findIndex(c => c._id === conductor_elegido._id)
          if(i > -1) continue
          geocerca.conductores.unshift(conductor_elegido)
        }

      } else {
        const i = geocerca.conductores.findIndex(c => c._id === driver._id)
        if(i > -1) return messageApi.info("Ya agregaste este registro")
        geocerca.conductores.unshift(driver)
    }
    
      return setGeocerca({...{}, ...geocerca })
    }

    const mostrarTiposVehiculo = () => {
      if(!geocerca.tipos_vehiculo) return false
      if(Array.isArray(geocerca.tipos_vehiculo) !== true ) return false
      return <div style={{ marginTop: 10 }}>
        <List
          header={false}
          footer={false}
          bordered
          dataSource={geocerca.tipos_vehiculo}
          renderItem={(driver,i) => (
            <List.Item>
              <Paragraph className="mb-0 mt-0" style={{ fontSize: 12 }}>{driver.tipo}</Paragraph>
              <Button type="dashed" size="small" style={{ fontSize: 12 }} icon={<DeleteOutlined />} onClick={() => removerItem(i,'tipos_vehiculo')} >REMOVER</Button>
            </List.Item>
          )}
        />
      </div>
    }

    const removerItem = (pos, name) => {
      geocerca[name].splice(pos,1)
      return setGeocerca({...{}, ...geocerca})
    }

    const actualizarGeocercas = (data) => {
      setGeoCercasParaActualizar(data)
    }

    const mostrarVehiculos = () => {
      if(!geocerca.vehiculos) return false
      if(Array.isArray(geocerca.vehiculos) !== true ) return false
      return <div style={{ marginTop: 10 }}>
        <List
          header={false}
          footer={false}
          bordered
          dataSource={geocerca.vehiculos}
          renderItem={(driver,i) => (
            <List.Item>
              <Paragraph className="mb-0 mt-0" style={{ fontSize: 12 }}>{driver.nombres} {driver.marca} {driver.modelo} {driver.patente} <Link target="_blank" to={`${rutas.vehicles.slug}/${driver._id}`}><BsBoxArrowInUpRight /></Link></Paragraph>
              <Button type="dashed" size="small" style={{ fontSize: 12 }} icon={<DeleteOutlined />} onClick={() => removerItem(i,'vehiculos')} >REMOVER</Button>
            </List.Item>
          )}
        />
      </div>
    }


    const mostrarConductores = () => {
      if(!geocerca.conductores) return false
      if(Array.isArray(geocerca.conductores) !== true ) return false
      return <div style={{ marginTop: 10 }}>
        <List
          header={false}
          footer={false}
          bordered
          dataSource={geocerca.conductores}
          renderItem={(driver,i) => (
            <List.Item>
              <Paragraph className="mb-0 mt-0" style={{ fontSize: 12 }}>{driver.nombres} {driver.apellido_p} {driver.apellido_m} <Link target="_blank" to={`${rutas.drivers.slug}/${driver._id}`}><BsBoxArrowInUpRight /></Link></Paragraph>
              <Button type="dashed" size="small" style={{ fontSize: 12 }} icon={<DeleteOutlined />} onClick={() => removerItem(i,'conductores')} >REMOVER</Button>
            </List.Item>
          )}
        />
      </div>
    }

    const obtenerIDS = (valores) => {
      if(!valores) return []
      if(Array.isArray(valores) !== true) return []
      const ids = valores.map(e => e._id).filter(e => e)
      return ids
    }

    const handleChangeMovil = (e, pos, name) => {
      let value = e
      if(typeof e === "object") if(e.target) value = e.target.value
      geocerca.moviles_adicionales[pos][name] = value
      return setGeocerca(prev => ({...{}, ...geocerca}))
  }

  const removerMovil = (i) => {
      geocerca.moviles_adicionales.splice(i,1)
      return setGeocerca(prev => ({...{}, ...geocerca})) 
  }
  
  const anadirMovilAdicional = () => {
      if(!geocerca.moviles_adicionales) geocerca.moviles_adicionales = []
      geocerca.moviles_adicionales.unshift({
          pais_codigo: '',
          movil: ''
      })
      return setGeocerca(prev => ({...{}, ...geocerca}))
  }

  const anadirEmailAdicional = () => {
      if(!geocerca.emails_adicionales) geocerca.emails_adicionales = []
      geocerca.emails_adicionales.unshift({
          email: ''
      })
      return setGeocerca(prev => ({...{}, ...geocerca}))
  }

  const removerEmail = (i) => {
      geocerca.emails_adicionales.splice(i,1)
      return setGeocerca(prev => ({...{}, ...geocerca})) 
  }

  const handleChangeEmail = (e) => {
      const { name, value } = e.target
      const pos = parseInt(e.target.getAttribute('pos'))
      geocerca.emails_adicionales[pos][name] = value
      geocerca.emails_adicionales[pos].valid = validateEmail(value)
      return setGeocerca(prev => ({...{}, ...geocerca}))
  }

  const mostrarEmailsAdicionales = () => {
      if(!geocerca.emails_adicionales) return false
      return <div>
          {
              geocerca.emails_adicionales.map((field,i) => {
                  return <div key={`email-${i}`} className="mt-3">
                      <Card size="small" title="Email" className="mb-3" extra={<Button type="ghost" onClick={() => removerEmail(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} >REMOVER</Button>} >
                              <Form.Item label="" required={true} className="mb-0">
                              <Input placeholder="Escribe aquí el email" prefix={<AiOutlineMail />} className='mb-0' status={field.valid === false ? 'error' : ''} pos={i}  name="email" value={field.email} onChange={handleChangeEmail} />
                              </Form.Item>
                      </Card>
                  </div>
              })
          }
      </div>
  }

  const agregarVehiculo = (driver) => {

    if(Array.isArray(driver) !== false){
      
      return setGeocerca(prev => {
        let actual = {...prev}
        if(!actual.vehiculos) actual.vehiculos = []
        for( const conductor_elegido of driver ){
          const i = actual.vehiculos.findIndex(c => c._id === conductor_elegido._id)
          if(i > -1) continue
          actual.vehiculos.unshift(conductor_elegido)
        }
        return actual
      })

    } else {
      return setGeocerca(prev => {
        let actual = {...prev}
        if(!actual.vehiculos) actual.vehiculos = []
        const i = actual.vehiculos.findIndex(c => c._id === driver._id)
        if(i > -1) {
          messageApi.info("Ya agregaste este registro")
        } else {
          actual.vehiculos.unshift(driver)
        }
        return actual
      })
    }
    
  }

  const mostrarMovilesAdicionales = () => {

      const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

      if(!geocerca.moviles_adicionales) return false
      return <div>
          {
              geocerca.moviles_adicionales.map((field,i) => {
                  return <div key={`movil-${i}`} className="mt-3">
                  <Card size="small" title="Móvil" className="mb-3" extra={<Button type="ghost" onClick={() => removerMovil(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />}>REMOVER</Button>} >
                      <Row gutter={15}>
                          <Col md={24} className="mb-2">
                              <Form.Item label="Cod. área" required={true} className="mb-0">
                              <Select style={{ width: "100%" }} showSearch placeholder="COD. Área" name="pais_codigo" value={field.pais_codigo} onChange={(e) => handleChangeMovil(e,i,"pais_codigo")} 
                                  filterOption={filterOption}
                                  options={codigos_area}
                              />
                              </Form.Item>
                          </Col>
                          <Col md={24}>
                              <Form.Item label="Número" required={true} className="mb-0">
                              <Input placeholder="Escribe aquí el número telefónico" className='form-control mb-3' name="movil" value={field.movil} onChange={(e) => handleChangeMovil(e,i,"movil")} />
                              </Form.Item>
                          </Col>
                      </Row>
                  </Card>
                  </div>
              })
          }
      </div>
  }

    const formularioGeocerca = () => {

      const conductores_seleccionados = obtenerIDS(geocerca.conductores)
      const tipos_vehiculos_seleccionados = obtenerIDS(geocerca.tipos_vehiculo)
      let defaultHorario = false
      if(geocerca){
        if(geocerca.horario){
          if( typeof geocerca.horario === "object" ){
            if( geocerca.horario.desde || geocerca.horario.hasta ){
              defaultHorario = [ dayjs( `${geocerca.horario.desde.hora}:${geocerca.horario.desde.minuto}`, 'HH:mm') , dayjs( `${geocerca.horario.hasta.hora}:${geocerca.horario.hasta.minuto}`, 'HH:mm') ]
            }
          }
        }
      }

      return <div>
        <Form layout="vertical" style={{ padding: 20 }}>
        <Row gutter={10}>
                <Col md={24} xs={24}>
                  <Form.Item label="Título" required={siEsRequeridoEnEsquema('titulo',requeridos)} >
                    <Input mb={3} name="titulo" defaultValue={geocerca.titulo} onChange={handleChange} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                <Form.Item label="Tipo de rastreo" required={siEsRequeridoEnEsquema('tipo_rastreo',requeridos)} >
                  <Select style={{ width: "100%" }} defaultValue={geocerca.tipo_rastreo}  options={[{ value: "zona", label: "Zona" }, { value: "ruta", label: "Ruta"}]} onChange={(e) => handleChangeSelect(e,'tipo_rastreo')} />
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                <Form.Item label="Tipo de zona" required={siEsRequeridoEnEsquema('tipo',requeridos)} >
                  <Select defaultValue={geocerca.tipo} fullwidth className="mb-3" style={{ width: "100%" }} options={tipo_zonas} onChange={(e) => handleChangeSelect(e,'tipo')} />
                  </Form.Item>
                </Col>
                
                <Col md={24} xs={24}>
                <Form.Item label="Horario" required={siEsRequeridoEnEsquema('horario',requeridos)} >
                  <TimePicker.RangePicker placeholder={['Desde', 'Hasta']} changeOnBlur minuteStep={5} format="HH:mm" defaultValue={defaultHorario} onChange={handleChangeHorario} />
                  </Form.Item>
                </Col>
                
                <Col md={24} xs={24}>
                <Form.Item label="Seleccionar tipos de vehículo específicos" required={siEsRequeridoEnEsquema('tipo_destino',requeridos)} >
                    <ModalSeleccionTiposVehiculos exclude={tipos_vehiculos_seleccionados} showSelection={true} hideSeleccionados={true} onSelect={(data) => agregarTipoVehiculos(data)} />
                    {mostrarTiposVehiculo()}
                </Form.Item>
                <Form.Item label="Seleccionar conductores específicos" required={siEsRequeridoEnEsquema('tipo_destino',requeridos)} >
                    <ModalSeleccionVehiculos exclude={conductores_seleccionados} hideSeleccionados={true} onSelect={(driver) => agregarConductor(driver)} />
                    {mostrarConductores()}
                </Form.Item>
                <Form.Item label="Seleccionar vehículos específicos" >
                    <ModalSeleccionVehiculo isMulti={true} hideSeleccionados={true} onSelect={(driver) => agregarVehiculo(driver)} />
                    {mostrarVehiculos()}
                </Form.Item>
                </Col>
                <Col md={24} xs={24}><Title level={4} className="mt-0">¿Cuando notificar?</Title></Col>
                <Col md={8} xs={24}>
                <Form.Item label="Notificar al entrar" required={siEsRequeridoEnEsquema('notificar_entrar',requeridos)} >
                <Switch
                    checked={geocerca.notificar_entrar ? true : false}
                    checkedChildren="SI"
                    unCheckedChildren="NO"
                    onChange={() => {
                      geocerca.notificar_entrar = geocerca.notificar_entrar === true ? false : true
                      console.log(geocerca)
                      return setGeocerca({...{}, ...geocerca})
                    }}
                    className="mb-3"
                    />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                <Form.Item label="Notificar al salir" required={siEsRequeridoEnEsquema('notificar_salir',requeridos)} >
                <Switch
                    checked={geocerca.notificar_salir ? true : false}
                    checkedChildren="SI"
                    unCheckedChildren="NO"
                    onChange={() => {
                      geocerca.notificar_salir = geocerca.notificar_salir === true ? false : true
                      console.log(geocerca)
                      return setGeocerca({...{}, ...geocerca})
                    }}
                    className="mb-3"
                    />
                    </Form.Item>
                </Col>
                  <Col md={24} xs={24}><Title level={4} className="mt-0">¿Cómo notificar?</Title></Col>
                  <Col md={8} xs={24}>
                <Form.Item label="Por email" required={siEsRequeridoEnEsquema('notificar_email',requeridos)} >
                <Switch
                    checked={geocerca.notificar_email ? true : false}
                    checkedChildren="SI"
                    unCheckedChildren="NO"
                    onChange={() => {
                      geocerca.notificar_email = geocerca.notificar_email === true ? false : true
                      console.log(geocerca)
                      return setGeocerca({...{}, ...geocerca})
                    }}
                    className="mb-3"
                    />
                    </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                    <Title className="mt-0" level={4}>Datos de contacto</Title>
                    <Row gutter={15}>
                        <Col md={24}>
                        <Title level={4} className="mt-0" ><GoDeviceMobile /> Teléfonos</Title>
                        <Button icon={<GrAdd /> } className="mb-3" onClick={() => anadirMovilAdicional()} >AÑADIR NUEVO</Button>
                        {mostrarMovilesAdicionales()}
                        <Divider />
                        </Col>
                        <Col md={24}>
                        <Title level={4} className="mt-0" ><AiOutlineMail /> Emails</Title>
                        <Button icon={<GrAdd /> } className="mb-3" onClick={() => anadirEmailAdicional()} >AÑADIR NUEVO</Button>
                        {mostrarEmailsAdicionales()}
                        </Col>
                    </Row>
                </Col>
                <Col xs={24}>
                  <Button loading={loading} type="primary" onClick={() => guardarCambios()} >GUARDAR CAMBIOS</Button>
                </Col>
        </Row>
        </Form>
      </div>
    }
    const render = () => {
        if(loadingMaster) return <div style={{ padding: 20 }}><Skeleton active /></div>

        const tabs = [
            {
                key: '1',
                label: `EDITAR ZONA`,
                children: formularioGeocerca(),
            },
            {
                key: '2',
                label: `ALERTAS DE ESTA ZONA`,
                children: <ListadoAlertas typeView="simple" condicion_default={{ id_zona: id }} />,
            },
        ]

        const tabs_mapa = [
            {
                key: '1',
                label: `GEOCERCAS CARGADAS`,
                children: <MapaZonasDetalle height="90vh" 
                              geometries={geometrias} 
                              zona={geocerca} 
                              onChangeGeocerca={data => actualizarGeocercas(data)} 
                              geocercas_defecto={geocerca.geocercas.map(geo => parsearGeocercaMapa(geo) ) } 
                              default_geojson={geojson}
                              onNewPoints={features => {
                                // setNewGeoJson(prev => {
                                //   let newGeo = {
                                //     type: "FeatureCollection",
                                //     features
                                //   }
                                  
                                // })
                                guardarCambios(features)
                              }}
                          />,
            },
            {
                key: '2',
                label: `DIBUJA NUEVAS GEOCERCAS`,
                children: <MapaDibujoPoligono loading={loading} resize={resize} guardarCambios={() => guardarCambios()} geometries={geometrias} default_geojson={geojson} onChange={data => {
                  console.log(data)
                  setNewGeoJson(data)
                }} />,
            },
        ]

        const eliminarRecurso = async () => {
            setLoadingMaster(true)
            return fetch(`${urlapi}/geodata/zonas/empresa?id=${id}`,{
                method:'DELETE',
                headers: {
                    'Content-Type':'application/json',
                    'Authorization': `Bearer: ${session.tokenSession}`,
                    'country': pais,
                    'lang': idioma
                }
            })
            .then(res => {
                if(res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                console.log(res)
                if(!res){
                    messageApi.error('Sin datos')
                } else if(res.errorMessage){
                    messageApi.error(res.errorMessage)
                } else if(res._id){
                    return window.location = rutas.zones.slug
                }
                return setLoadingMaster(false)
            })
            .catch(error => {
                messageApi.error("Error al consultar la información, intente nuevamente")
                return setLoadingMaster(false)
            })
        }
    
      const handleChangeTab = () => {
        setResize(prev => prev + 1)
      }

      return <div>
        {contextHolder}
        <Row gutter={15}>
          <Col md={8} style={{ height: "100vh", overflowY: "scroll" }}>
          <Card className="mb-3">
         <Breadcrumb
                items={[
                {
                    href: rutas.operation_zones.slug,
                    title: <HomeOutlined />,
                },
                {
                    href: rutas.zones.slug,
                    title: <span>Zonas de operación</span>,
                },
                {
                    title: 'Editar zona',
                },
                ]}
            />
            <Title className="mb-2 mt-0">Editar zona</Title>
            <Row gutter={15}>
              <Col>
            <Popconfirm
                    title="Eliminar definitivamente"
                    description="¿Estás seguro que deseas eliminar este recurso? Esta opción no se puede deshacer"
                    onConfirm={() => eliminarRecurso()}
                    onCancel={() => false}
                    okText="SI"
                    cancelText="NO"
                    >
                  <Button size="small" type="dashed" danger>ELIMINAR</Button>
                  </Popconfirm>
              </Col>
              <Col>
                  <Button loading={loading} size="small" type="primary" onClick={() => guardarCambios()} >GUARDAR CAMBIOS</Button>
              </Col>
            </Row>
        </Card>
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Tabs defaultActiveKey="1" items={tabs} onChange={handleChangeTab} />
        </div>
          </Col>
          <Col md={16} style={{ height: "100vh", overflowY: "scroll" }}>
          <Tabs defaultActiveKey="1" items={tabs_mapa} />
          </Col>
        </Row>
      </div>
    }
    
    return <Structure component={render()} />
  };
  

export default DetallesZona